@import '../../variables.scss';

.ChapterList {
  .chapter-container {
    overflow: hidden;
    transition: height $animation-speed ease;
  }
  .chapter-separator {
    padding: 15px 0 15px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .mio-chapter-card {
    flex-wrap: wrap;
  }
  .mio-volume-card {
    margin-bottom: $spacing;

    .description {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box !important;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
  }
}
