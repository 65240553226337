@import '../../variables.scss';

#mio-subscription {
  width: 100%;

  .space-top {
    margin-top: $spacing * 3;
  }
  .spacer {
    margin-top: $spacing * 6;
  }
  .divider {
    margin-top: $spacing * 12;
  }
  .mio-disclaimer {
    margin-bottom: $spacing * 3;
  }

  .nudge {
    margin: $spacing * 5 0;
  }
  .subscription-btn {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .success-form {
    text-align: center;
    width: 100%;
    padding: 30px;

    .success-title {
      margin-bottom: 30px;
    }
    .success-button {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 50%;
    }
    .dl-app-text {
      margin-top: 30px;
    }
    .app-ctas {
      display: flex;
      margin-top: 30px;

      .app-cta {
        margin: 0 20px 20px 20px;
      }
    }
  }
  .coffee-success {
    width: 120px;
    height: 120px;
  }

  .mio-form-icon {
    width: 100px;
    height: 100px;
    color: white;
  }

  .stripe-loader {
    position: relative;
    display: inline-block;
  }
  .stripe-loader .spinner {
    position: absolute;
    top: 8px;
    left: 0;
  }
  /* @group LOADER */
  .spinner {
    animation: spin 1.4s linear infinite;
  }

  .spinner .path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    stroke: #fff;
    animation: dash 1.4s ease-in-out infinite;
  }

  .code-selection {
    width: 100%;
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    justify-content: space-between;
    .mio-button {
      margin-top: 30px;
    }
  }

  .stripe-loader {
    .mio-button {
      padding: 0;
      display: inline;
      .label {
        text-decoration: bold;
      }
    }
  }
  .header-text {
    margin-top: 70px;
    .title-container {
      text-align: center;
      margin-bottom: 20;
      padding-right: 30px;
      padding-left: 30px;
    }
    .secondaryText {
      color: $color-text-secondary-light;
    }
    .subtitle {
      padding: 20px 0;
      max-width: 800px;
      margin: 0 auto;
    }
    .spanText {
      text-decoration: underline;
      color: white;
    }
  }
}
