@import '../../variables.scss';

#mio-login {
  width: 100%;

  .mio-disclaimer {
    margin-top: 30px;
  }

  .mio-button {
    margin-top: 30px;
  }
  .disclaimerSuccess {
    width: 100%;
  }
  @media (min-width: $breakpoint-sm) {
    .mio-button {
      margin-top: 100px;
    }
  }
}
