// N'importer que les fichiers nécessaires
@import '../theme/config.scss';

.mio-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 30px;
  color: white;
  width: 100%;

  &.group-field {
    margin-top: 8px;
  }

  .mio-check-input {
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
    margin: 0;
    padding: 0;
  }

  .mio-check-label {
    font-size: 0.875rem;
    line-height: 1.5em;
    cursor: pointer;
    user-select: none;
    flex: 1;
    a {
      color: white;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .mio-check-input:disabled ~ .mio-check-label {
    opacity: 0.5;
    cursor: default;
    user-select: none;
  }

  .mio-check-checkbox {
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    height: 24px;
    width: 24px;
    min-width: 24px;
    transition: outline 0.1s;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.15);
    border: 2px solid rgba(255, 255, 255, 0.6);
    cursor: pointer;
  }

  .mio-check-input:disabled + .mio-check-checkbox {
    border: none;
    cursor: default;
  }

  .mio-check-input:checked + .mio-check-checkbox {
    background: url($cdn + '/images/checkbox.svg') no-repeat center;
    background-color: white;
  }
  .mio-check-input:hover + .mio-check-checkbox {
    border-color: white;
  }
  .mio-check-input:focus + .mio-check-checkbox {
    border-color: white;
    outline: 3px solid rgba(255, 255, 255, 0.15);
  }
}
