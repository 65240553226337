#responsive-columns {
  display: flex;

  &.oneColumn {
    flex-direction: column;
  }

  .main {
    flex: 1;
    margin-right: 35px;
    overflow: hidden;
  }
  .sidebar {
    width: 360px;
  }

  &.invert {
    flex-direction: row-reverse;
  }
}
