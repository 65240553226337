.carrousel-medias {
  .medias-bloc {
    width: 140px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    transition: all 0.2s ease;
    justify-content: center;

    &:hover {
      border-color: #ffffff;
    }
  }
  .medias-img {
    width: 110px;
  }
}
