@import '../../variables.scss';

#PricingPage {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  .PricingPageWrapper {
    padding: 0 25px;
    padding-top: 20px;
    padding-bottom: 100px;
  }

  .popularConnected-line {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 50px;
    margin-top: 50px;
    z-index: 3;
    .buttonLink {
      justify-content: center;
      display: flex;
    }
    .titleBloc {
      display: none;
    }
    #button {
      margin-top: 20px;
      margin-bottom: 100px;
    }
    .elements {
      padding-top: 20px;
    }
  }

  .pricing {
    position: relative;

    .--title {
      position: relative;
      text-transform: none;
      margin: 0;
      font-size: 2.25rem;
      line-height: 1.5em;
      font-weight: 500;
    }

    .--subtitle {
      position: relative;
      font-size: 1.25rem;
      line-height: 1.5em;
      font-weight: normal;
      color: #afafaf;
      margin-block-start: 0;
      margin-top: 12px;
    }

    .pricing-hero-image {
      width: 100%;
      position: relative;
      margin-bottom: -11%;

      img {
        width: 106%;
        max-width: none;
        height: auto;
        transform: translateX(-50%);
        margin-left: 50%;
      }
    }

    .pricing-hero {
      max-width: 853px;
      width: 100%;
      margin: 0 auto;
    }

    .pricing-cta {
      display: inline-block;
      margin-top: 45px;
      margin-bottom: 15px;
    }

    @media (max-width: 900px) {
      .--title {
        font-size: 1.5rem;
        line-height: 1.5em;
      }

      .--subtitle {
        font-size: 1rem;
        line-height: 1.5em;
      }
    }

    /* @group PANELS */
    .panels {
      position: relative;
      display: block;
      margin: 0 -20px 0 -20px;
    }

    .panel {
      max-width: 357px;
      width: 33%;
      display: inline-block;
      position: relative;
      padding: 15px;
    }

    /* @end PANELS */

    /* @group SUBSCRIPTION */
    .subscription {
      position: relative;
      margin-top: 25px;
      margin-bottom: 20px;
      z-index: 0;
      color: $color-text-primary-light;
    }

    .subscription .title {
      font-size: 0.875rem;
      line-height: 1.5em;
      text-transform: uppercase;
      font-weight: 600;
      color: $color-text-primary-light;
    }

    @media (max-width: 1200px) {
      .subscription {
        // margin-bottom: 50px;
      }
    }

    @media (max-width: 900px) {
      .subscription {
        .panels {
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .panel {
          width: 100%;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    /* @end SUBSCRIPTION */
  }

  .pricing-partners {
    margin-bottom: 80px;
    display: flex;
    justify-content: center;
    .partners-list {
      padding: 0;
    }
  }

  .pricing-voucher {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .pricingUsersFeedback {
    overflow: hidden;
    position: relative;
    width: 100%;
    text-align: center;
    color: black;
  }

  #LandingFeedback {
    .content-wrapper {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  @media (max-width: 900px) {
    .pricing-table-plans {
      width: 100%;
    }
    .PricingPageWrapper {
      padding: 20px 15px 50px;
      .passCulture {
        margin-left: 10px;
        margin-right: 10px;
      }

      .pricing-hero {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}
