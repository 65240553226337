@import '../../variables.scss';

#PublisherPage {
  .contenus-lies-bloc {
    background-color: transparent;
    .content {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .info-bloc {
    margin-bottom: 65px;
    border-radius: 0;

    @media (min-width: $breakpoint-sm) {
      border-radius: 8px;
    }
    .externalLink {
      display: flex;
      align-items: center;
      svg {
        width: 20px;
      }
    }
  }
  .MangaGrid {
    padding: 0;
  }

  .descriptionPublisher {
    white-space: pre-wrap;
  }

  .blocTitle {
    margin: 0 0 15px 30px;
    @media (min-width: $breakpoint-sm) {
      margin-left: 15px;
    }
  }
  #publisherTile {
    margin-right: 30px;
  }
  .publisherBloc {
    margin: 0 35px 35px 35px;
    @media (min-width: $breakpoint-sm) {
      margin: 0;
    }
  }

  #mangaListAnchor {
    transform: translateY(-80px);
  }
}
