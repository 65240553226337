@import '../../variables.scss';

#FavoritesCategories {
  padding: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  .pageTitle {
    margin-bottom: 10px;
  }
  .categories {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 30px;
    .categoryCard {
      overflow: hidden;
      align-items: stretch;
      padding: 0;
      min-height: 80px;
      height: 80px;
      width: 100%;
      p {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
      }
    }
    .selectedCard {
      background-color: #63556b;
      border: 1px solid white;
    }
  }

  @media (min-width: $breakpoint-md) {
    .categories {
      max-width: 900px;
      margin-top: 20px;
      .categoryCard {
        overflow: hidden;
        width: 20%;
        margin: 20px;
        min-height: 100px;
        height: 100px;
      }
    }
  }
}
