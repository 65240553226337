@import url('https://fonts.googleapis.com/css?family=Poppins:100,400,500,600,700&display=swap');
@import './variables.scss';

html {
  &.smooth {
    scroll-behavior: smooth;
  }
}
.App {
  background-color: #f4f4f4;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23f4e4ff' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
  min-height: 100vh;

  &.dark-mode {
    // dark mode css here
    background: $background-dark;
  }
}
body {
  background: $background-dark;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

a {
  text-decoration: none;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.hide-sm,
.hide-md,
.hide-lg {
  display: none;
}

.full-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
.uppercase {
  text-transform: uppercase;
}
.textEllipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.text-center {
  text-align: center;
}

.horizontal-spacer {
  width: $spacing * 3;
  margin: 0 !important;
}

.clickable {
  cursor: pointer;
  user-select: none;
}

.no-wrap {
  white-space: nowrap;
}

/* Skeleton Elements */
.skeleton-text {
  background-color: rgba(255, 255, 255, 0.2);
  height: 1em;
  display: inline-block;
  width: 100%;
  max-width: 100%;

  &.dark {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.skeleton-shape {
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
  height: 100%;

  &.dark {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.skeleton-shape-bg {
  background-color: rgba(255, 255, 255, 0.2);

  &.dark {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.mio-scrollbar {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 254, 254, 0.35);
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;

    &:hover {
      background-color: rgba(255, 254, 254, 0.6);
    }
  }
}

@media (min-width: $breakpoint-sm) {
  // big phone and up
  .hide-sm {
    display: unset;
  }
}
@media (min-width: $breakpoint-md) {
  // tablet and up
  .horizontal-spacer {
    width: $spacing * 10;
    margin: 0 !important;
  }
  .hide-md {
    display: unset;
  }
}
@media (min-width: $breakpoint-lg) {
  // desktop and up
  .hide-lg {
    display: unset;
  }
}
@media (min-width: $breakpoint-xl) {
  // wide desktop and up
}
