@import '../../variables.scss';

.pricing-line {
  margin-top: $spacing * 2;
  width: 100%;

  .pricing-line-header {
    width: 100%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mio-tag {
    font-weight: 300;
    font-size: 0.8125rem;
    line-height: 1.5em;
    color: rgba(255, 255, 255, 0.8);
    padding: 1px 10px;
    white-space: nowrap;
  }

  .price {
    font-size: 28px;
    color: white;
    display: flex;
    justify-content: flex-end;

    span {
      margin-left: 5px;
      font-size: 13px;
    }
  }

  .promo {
    margin-top: 5px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
  }

  .mobile {
    .price {
      font-size: 24px;
      span {
        font-size: 11px;
      }
    }
    .promo {
      font-size: 10px;
    }
  }
}
