@import '../../../variables.scss';

#LandingCards {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .CardsComponent {
    .tag {
      color: white;
      width: fit-content;
      border: 1px solid #fff;
      padding: 2px 5px;
      border-radius: 4px;
      opacity: 0.7;
      margin-right: 10px;
      text-align: center;
      text-transform: uppercase;
      font-size: 11px;
      margin-bottom: 5px;
    }
    .tagWhite {
      background: white;
      border-radius: 12px;
      text-transform: none;
      padding-left: 17px;
      padding-right: 17px;
      opacity: 1;
      font-weight: 600;
      line-height: unset;
    }
    .textDarkBlue {
      color: #102969;
    }
    display: flex;
    margin-top: 50px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .priceBloc {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 10px;
      height: 450px;
      width: 327px;
      padding: 15px;
      border-radius: 8px;
      text-align: center;
      .priceText {
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 55px;
        white-space: nowrap;
        font-weight: 700;
      }
      .subText {
        text-transform: uppercase;
        font-weight: 200;
      }
      .btnOffer {
        margin-bottom: 10px;
        white-space: nowrap;
      }
    }
    .threeM {
      background: linear-gradient(
          205.95deg,
          #2c2984 2.78%,
          #36167e 28.07%,
          #511245 98.77%
        ),
        linear-gradient(180deg, #ebb019 0%, #ea6419 100%);
    }
    .sixM {
      background: linear-gradient(74.66deg, #00b1bc 0%, #074dff 100%);
    }
    .oneY {
      background: linear-gradient(180deg, #ebb019 0%, #ea6419 100%),
        linear-gradient(
          205.95deg,
          #2c2984 2.78%,
          #36167e 28.07%,
          #511245 98.77%
        );
    }
    @media (min-width: $breakpoint-lg) {
      flex-direction: row;
    }
  }
  #infoBtn {
    margin-top: 30px;
  }
  @media (min-width: $breakpoint-lg) {
    margin-bottom: 100px;
  }
}
