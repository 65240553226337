@import '../../../variables.scss';

.referral-slide {
  color: white;

  .mio-text.title {
    text-transform: uppercase;
    opacity: 0.5;
  }

  .referral-row {
    padding: $spacing * 2 $spacing * 4;
    &.horizontal {
      display: flex;
      justify-content: space-between;
    }
  }

  .no-code {
    display: flex;
    flex-direction: column;
    align-items: center;

    .mio-text {
      text-align: center;
    }

    svg {
      color: rgb(177, 177, 177);
      width: 150px;
    }

    a {
      color: $color-primary;
      font-weight: 600;
    }
  }

  a {
    color: white;
  }
}
