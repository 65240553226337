@import '../../variables.scss';

#SensitiveContentWall {
  z-index: 4;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(116, 114, 114, 0.7);
  backdrop-filter: blur(64px);
  overflow: auto;

  /* @group SENSITIVE WALL */

  .content-wrapper {
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 25px;
    min-height: 100vh;
  }

  .box {
    background: $background-dark;
    border-radius: 8px;
    text-align: center;
  }

  .box-header {
    border-bottom: 1px solid rgba(140, 140, 140, 0.3);
    padding: 20px 30px;

    .box-title {
    }
  }

  .box-content {
    padding: 35px 30px;
    font-size: 0.9375rem;
    line-height: 1.5em;
    font-weight: normal;

    strong {
      font-weight: 600;
    }

    img {
      margin-bottom: 20px;
    }
  }

  .box-actions {
    margin-top: 50px;

    .Button {
      display: inline-flex;
      margin: 0 15px;
    }
  }

  @media (max-width: 600px) {
  }

  @media (max-width: 520px) {
    .content-wrapper {
      padding: 0;
    }

    .box {
      background: transparent;
      // padding: 25px 0;
      .box-content {
        padding: 35px 15px;
        font-size: 0.8rem;
      }
    }

    background: #1a151d;
  }

  @media (max-width: 1000px) {
  }

  /* @end SENSITIVE WALL */

  /* @group FORM */
  [type='date']::-webkit-inner-spin-button {
    display: none;
  }
  [type='date']::-webkit-calendar-picker-indicator {
    display: none;
  }

  .field {
    position: relative;
    margin-top: 40px;
    margin-bottom: 5px;
    font-size: 1em;
  }

  .field .input {
    font-family: inherit;
    font-size: 1em;
    line-height: 1.5em;
    border: 2px solid rgba(255, 255, 255, 0.7);
    border-radius: 4px;
    background: none;
    height: 56px;
    width: 100%;
    padding: 0 15px;
    color: white;
  }
  .field .input:focus {
    border-color: white;
    outline: none;
  }
  .field .input::-webkit-clear-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .field .input::placeholder,
  .field .input::-webkit-datetime-edit {
    font-family: inherit;
    /* color: rgba(255, 255, 255, 0.4); */
  }
  /* .field .input:focus::-webkit-datetime-edit,
    .field .input:valid::-webkit-datetime-edit {
      color: rgba(255, 255, 255, 1);
    } */
  .field.has-icons-right .input {
    padding-right: 60px;
  }
  .field .input.is-error {
    border-color: #ff4a3f;
  }

  .field .icon {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    width: 54px;
    opacity: 0.7;
  }
  .field .icon img {
  }

  .field .is-reveal {
    padding: 0;
    margin: 0;
    background: none;
    border: none;
  }
  .field .icon.is-reveal input {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
  }
  .field .icon.is-reveal input + .icn-reveal {
    display: inline-block;
    cursor: pointer;
    width: 100%;
    height: 100%;
    background: url($cdn + '/images/mdi_eye-off.svg') no-repeat center;
    background-size: 24px;
  }

  .field .icon.is-reveal input:checked + .icn-reveal {
    background-image: url($cdn + '/images/mdi_eye.svg');
  }

  .field input:focus ~ .icon {
    opacity: 1;
  }

  .field .input + label {
    position: absolute;
    top: 17px;
    left: 17px;
    user-select: none;
    pointer-events: none;
    transition: all 0.2s ease;
    font-size: 1em;
    line-height: 1.5rem;
    padding: 0;
    color: rgba(255, 255, 255, 0.7);
  }
  /* .field .input:valid + label,
    .field .input:focus + label,
    .field .input + label.shrink, */
  .field .input + label {
    top: -6px;
    left: 12px;
    font-size: 0.75rem;
    line-height: 1.2em;
    padding: 0 5px;
    background-color: #312637;
    border-radius: 4px;
  }

  .field .input:focus + label {
    color: white;
  }

  .field .checkbox {
    display: inline-flex;
    align-items: center;
    position: relative;
  }

  .field .checkbox input {
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
    margin: 0;
    padding: 0;
  }

  .field .checkbox .check-label {
  }

  .field .checkbox .check {
    flex: auto;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    height: 18px;
    width: 18px;
    min-width: 18px;
    transition: 0.1s;
    border: 2px solid #e11f7b;
    border-radius: 2px;
    background: none;
    cursor: pointer;
  }
  .field .checkbox input:checked + .check {
    background: url($cdn + '/images/checkbox.svg') no-repeat center;
    background-color: #e11f7b;
  }
  .field .checkbox input:focus + .check {
    box-shadow: 0 0 4px #e11f7b;
  }

  .help {
    font-size: 0.75rem;
    line-height: 1.5em;
  }
  .help.is-error {
    color: #ff4a3f;
  }

  .button {
    color: white;
    font-family: inherit;
    font-size: 1rem;
    line-height: 1.5em;
    font-weight: 600;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.05) 100%
    );
    border-radius: 8px;
    height: 44px;
    line-height: 44px;
    padding: 0 25px;
    white-space: nowrap;
    border: none;
    transition: box-shadow 50ms ease;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    text-decoration: none;
  }
  .disabled {
    cursor: default;
    opacity: 0.5;
  }
  .button:not(.disabled):hover,
  .button:not(.disabled):focus {
    outline: none;
    text-decoration: none;
    box-shadow: inset 0 0 0 1px white;
  }

  .button .icon {
    margin-right: 10px;
    margin-left: -5px;
  }

  .button .spinner {
    display: inline-block;
    vertical-align: text-bottom;
    margin-left: 10px;
  }

  .button.is-primary {
    background: linear-gradient(358.61deg, #92124a 8.67%, #d91d6f 91.33%);
  }
  .button.is-fullwidth {
    width: 100%;
    text-align: center;
  }

  .button.is-fullwidth .spinner {
    position: absolute;
    right: 22px;
    top: 11px;
  }

  .is-divider {
    display: block;
    position: relative;
    border-top: 1px solid #dbdbdb;
    height: 1px;
    margin: 2rem 0;
    text-align: center;
  }
  .is-divider::after {
    background: #312637;
    color: #afafaf;
    content: attr(data-content);
    display: inline-block;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0.5em 0.8em;
    transform: translateY(-1em);
    text-align: center;
  }

  @media (max-width: 600px) {
    .field {
      font-size: 0.875rem;
    }

    .button {
      font-size: 0.875rem;
    }
  }

  @media (max-width: 520px) {
    .is-divider::after {
      background: #1e1922;
    }
    .field .input + label {
      background-color: #1e1922;
    }
  }
  /* @end FORM */
}
