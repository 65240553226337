@import '../../../variables.scss';

.profile-slide {
  .row.error {
    background: rgb(138, 59, 59);
    margin: 0 $spacing * 3;
    border-radius: $radius;
    &:hover {
      background: rgb(138, 59, 59);
    }
  }
  .profile-row {
    display: flex;
    align-items: center;
    .profile-avatar {
      border-radius: 50%;
      height: 55px;
      width: 55px;
      overflow: hidden;
      position: relative;
      margin: $spacing * 4 $spacing * 3 $spacing * 4 $spacing * 4;

      img {
        height: 100%;
        width: 100%;
      }

      .loading {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;

        background: black;
        svg {
          color: grey;
        }
      }
    }
  }
  .action-row {
    position: relative;
    .destroy-avatar {
      position: absolute;
      right: $spacing * 3;
    }
  }

  input {
    display: none;
  }
}
