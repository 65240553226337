@import '../../variables.scss';

.SearchInput {
  width: 100%;
  display: flex;

  input {
    color: white;
    background-color: transparent;
    outline: none;
    border: none;
    border-radius: 10px;
    width: 100%;

    padding: 15px 10px;
  }

  svg {
    width: 30px;
    color: white;
    opacity: 0.7;
  }
}

.search-result-slide {
  .no-result {
    padding: $spacing * 4;
    .mio-text {
      text-align: center;
      margin-bottom: $spacing * 4;
    }
  }

  .mio-inlineCard {
    .textBlock .children {
      // overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      overflow-wrap: break-word;
    }
    .mio-tag {
      margin: $spacing $spacing 0 0;
    }
  }
}
