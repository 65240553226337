@import '../../variables.scss';

.manga-header {
  .box-buttons {
    justify-content: space-between;
    display: flex;
    .mio-button {
      svg {
        fill: white;
        filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.7));
      }
    }
    .box-buttons-right {
      display: flex;
      flex-direction: row;
      .mio-button {
        margin-left: 15px;
      }
    }
    .box-buttons-left {
      display: flex;
      flex-direction: row;
    }
    @media (min-width: $breakpoint-sm) {
      padding: $spacing * 3;
    }
  }
  .mio-progressbar {
    border-radius: 0;
  }
  .mio-progressbar-level {
    border-radius: 0;
  }
  .header-main {
    flex-direction: column;
    display: flex;
    background-size: cover;
    background-position: 50% 50%;
    border-radius: 8px 8px 0px 0px;
    @media (max-width: $breakpoint-lg) {
      border-radius: 0;
    }

    .title-content {
      display: flex;
      margin-left: 15px;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
    }
    .title-main {
      padding-bottom: 20px;
      .mio-text {
        display: inline-block;
        align-self: flex-end;
        margin-right: 20px;
        text-shadow: 1px 3px 10px black;
      }
    }

    .title-sub {
      padding-bottom: 20px;
      .mio-text {
        display: inline-block;
        align-self: flex-end;
        margin-right: 3px;
        text-shadow: 1px 3px 10px black;
      }
    }
  }

  .bottom-header {
    width: 100%;
    padding: 30px;
    margin-bottom: 15px;
    border-radius: 0px;
    background-color: rgba(44, 39, 47, 1);
    @media (min-width: $breakpoint-sm) {
      margin-bottom: 32px;
    }
    @media (min-width: $breakpoint-lg) {
      border-radius: 0px 0px 8px 8px;
    }
    .columns {
      display: flex;
      flex-direction: column;
      @media (min-width: $breakpoint-sm) {
        flex-direction: row;
        justify-content: space-between;
      }
    }
    .leftColumn {
      .title-main {
        margin-top: 5px;
        margin-bottom: 10px;
      }
      .subtitle {
        color: rgba(255, 255, 255, 0.8);
      }
      .authors {
        margin-bottom: 20px;
        a {
          color: rgb(32, 215, 255);
        }
        a:hover {
          text-decoration: underline;
        }
      }
    }

    .rightColumn {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media (min-width: $breakpoint-sm) {
        align-items: flex-end;
      }

      .categories {
        display: flex;
        flex-wrap: wrap;
        line-height: normal;
        display: flex;
        justify-content: flex-start;
        @media (min-width: $breakpoint-sm) {
          justify-content: flex-end;
        }
        & > * {
          margin: 0 $spacing * 3 0 0;
          @media (min-width: $breakpoint-sm) {
            margin: 0 0 0 $spacing * 3;
          }
        }

        .tags {
          display: inline-block;
          white-space: nowrap;
          margin-bottom: $spacing * 2;
        }
      }

      .publishers {
        margin-top: 10px;
        a {
          color: rgb(32, 215, 255);
        }
        a:hover {
          text-decoration: underline;
        }
      }
    }

    .actions {
      margin-top: $spacing * 2;
      display: flex;
      & > * {
        white-space: nowrap;
        @media (min-width: $breakpoint-sm) {
          margin-right: $spacing * 3;
        }
      }
    }
  }
}
