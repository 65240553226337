// N'importer que les fichiers nécessaires
@import '../theme/colors.scss';
@import '../theme/layout.scss';

// Chaque composant a une classe unique préfixée par mio-
.mio-toast {
  color: $primary-white;
  border-radius: $radius;
  padding: $spacing-3;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;

  &.success {
    background: $success;
  }
  &.error {
    background: $error;
  }
  &.warning {
    background: $warning;
  }
}
