@import '../../variables.scss';

.responsivePage {
  max-width: 1730px;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;

  @media (min-width: $breakpoint-lg) {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 15px;
  }
}
