$black: #000000;
$dark-background: #151018;
$menu-background: #231b27;
$black-background: #2c272f;
$card-background: #262128;
$secondary-button-background: rgba(56, 47, 61, 0.65);

$primary-white: #ffffff;
$secondary-white: rgba(255, 255, 255, 0.7);
$hover-highlight: rgba(255, 255, 255, 0.15);

$primary: #e11f7b;
$success: #21bd4d;
$warning: #f36f0f;
$error: #ff003d;
$info: #20d7ff;
$blue: #154ee2;
$purple: #5925a5;

$primary-gradient: linear-gradient(180deg, #d91d6f 0%, #92124a 100%);
$hover-gradient: linear-gradient(0deg, $hover-highlight, $hover-highlight);
$blue-gradient: linear-gradient(74.66deg, #00e8f7 0%, #074dff 100%);
$loading-gradient: linear-gradient(
  115deg,
  rgba(255, 255, 255, 0.1) 0%,
  rgba(255, 255, 255, 0.05) 100%
);

$gold-gradient: linear-gradient(180deg, #ebb019 0%, #ea6419 100%);
$silver-gradient: linear-gradient(180deg, #848385 0%, #423947 100%);
$bronze-gradient: linear-gradient(180deg, #864625 0%, #572115 100%);
$primary-gradient: linear-gradient(180deg, #d91d6f 0%, #92124a 100%);
