@import '../../../variables.scss';

#LandingGiftcard {
  text-align: center;
  padding: 120px 0;

  .giftcard-container {
    position: relative;
    overflow: hidden;
  }

  .giftcard-wrapper {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 0 25px;
  }

  .giftcard-collector-tag {
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1.5em;
    display: inline-flex;
    align-items: center;
    margin: 10px 0;

    .icon {
      margin-right: 8px;
      margin-top: -3px;
    }

    .text-gold {
      background: linear-gradient(
        90deg,
        #bf953f 0%,
        #e0b416 0.01%,
        #f3d97c 46.88%,
        #bf953f 100%
      );
      background-clip: text;
      -webkit-text-fill-color: transparent;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
    }
  }

  .text-gold {
    background: linear-gradient(
      90deg,
      #bf953f 0%,
      #f3d97c 46.88%,
      #bf953f 100%
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
  }

  .giftcard-title {
    font-weight: 700;
    font-size: 2.625rem;
    line-height: 1.5em;
    margin: 0;
    margin-bottom: 10px;
    color: white;
  }

  .giftcard-subtitle {
    font-size: 1rem;
    line-height: 1.8em;
    color: white;
    max-width: 580px;
    margin: 0 auto;
    font-weight: normal;
  }

  .giftcard-alert-wrapper {
    display: flex;
    justify-content: center;
    margin-top: $spacing * 6;
    .giftcard-alert {
      text-align: left;
      max-width: 500px;
      .mio-text {
        color: white;
        margin: 0;
        font-size: 0.875rem;
        line-height: 1.75em;
      }
      .side {
        padding: 15px;

        svg {
          width: 24px;
        }
      }
      .content {
        padding: 15px;
        padding-left: 0;
      }
    }
  }

  .giftcard-card-wrapper {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 25px;
  }

  .giftcard-cta {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 30px;
  }

  .giftcard-display {
    background: linear-gradient(
      103.31deg,
      #e07716 2.52%,
      #e68c2d 33.67%,
      #ffe791 47.74%,
      #c88b4a 67.33%,
      #bf7c3f 98.99%
    );
    border-radius: 40px;
    margin-top: 54px;
    margin-bottom: 54px;
    overflow: hidden;

    .giftcard-container {
      width: 100%;
      display: flex;
      overflow-y: hidden;
      overflow-x: auto;
      flex-direction: row;
      justify-content: space-between;
      padding: 4%;
    }

    .card {
      width: 100%;
      height: auto;

      &:not(:first-child) {
        margin-left: 29px;
      }

      img {
        display: block;
        width: 100%;
        min-width: 250px;
        border-radius: 20px;
        box-shadow: 7px 0px 10px rgba(0, 0, 0, 0.3);
        background-color: #060c14;

        user-select: none;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
      }
    }
  }

  @media (max-width: 700px) {
    padding: 50px 0;

    .giftcard-title {
      font-size: 1.5rem;
      line-height: 1.5em;
    }
    .giftcard-subtitle {
      font-size: 0.8rem;
    }

    .content-wrapper {
      padding: 0 15px;
    }

    .giftcard-display {
      // background: none;
      border-radius: 30px;
      margin-top: 50px;
      margin-bottom: 0;

      .giftcard-container {
        // padding: 30px calc((100% - 250px)/2);
        padding: 30px;
      }
    }
  }
  @media (max-width: 1200px) {
  }
}
