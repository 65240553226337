@import '../../../variables.scss';

.voucher-slide {
  .clickable.disabled {
    cursor: not-allowed;
    .mio-text,
    svg {
      color: grey;
      fill: grey;
    }
  }
  .info {
    margin: $spacing * 3;
    .mio-button {
      margin-top: $spacing * 5;
    }
  }
}
