@import '../../../variables.scss';

.change-password-slide {
  .form {
    padding: 0 $spacing * 3;
  }
  .mio-button {
    margin-top: $spacing * 7;
  }

  .mio-text.error {
    color: #c4143d;
  }

  .password-success {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      color: green;
      width: 150px;
    }
  }
}
