@import '../../../variables.scss';

.stripe-redirect {
  color: white;
  align-items: center;
  justify-content: center;
  padding: $spacing * 5;

  .mio-text {
    text-align: center;
  }
  .stripe-loader {
    position: relative;
    display: inline-block;
    margin: $spacing * 5 0;
  }
  .stripe-loader .spinner {
    position: absolute;
    top: 8px;
    left: 0;
  }

  /* @group LOADER */
  .spinner {
    animation: rotator 1.4s linear infinite;
  }

  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }

  .spinner .path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    stroke: #fff;
    animation: dash 1.4s ease-in-out infinite;
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 187;
    }
    50% {
      stroke-dashoffset: 46.75;
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: 187;
      transform: rotate(450deg);
    }
  }
  /* @end LOADER */
}
