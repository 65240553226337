@import "../theme/colors.scss";
@import "../theme/layout.scss";

.mio-progressbar {
  background-color: #463F42;
  border-radius: $radius;
  width: 100%;
  height: 6px;
  
  .rtl{
    float: right;
  }
  .mio-progressbar-level{
    background-color: $primary;
    border-radius: $radius;
    height: 100%;
  }
}
