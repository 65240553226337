@import '../../variables.scss';

.mio-information {
  padding: 0;
  @media (min-width: $breakpoint-sm) {
    padding: 0 $spacing * 2;
  }

  .row:not(:last-of-type) {
    margin-bottom: $spacing * 5;
  }

  .row.vertical {
    .mio-text {
      display: flex;
      flex-direction: column;
    }
  }

  .mio-text {
    &.title {
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: '10px';
    }

    a {
      color: rgba(32, 215, 255, 1);
      margin-right: $spacing;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .mio-tag {
    margin-top: $spacing;
    margin-right: $spacing;
    white-space: nowrap;
  }
  .socialIcon {
    margin-right: $spacing * 2;
  }
}
