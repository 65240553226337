@import '../../variables.scss';

#btn-affiliation {
  margin-top: 15px;
  width: 330px;
}
.affiliation-modal {
  position: fixed;
  z-index: 1000;
  max-height: 510px;
  max-width: 439px;
  top: $spacing * 2;
  left: $spacing * 2;
  right: $spacing * 2;
  border: 1px solid $background-light;
  background: $background-dark;
  animation: fade1 0.1s ease-in;

  @keyframes fade1 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .img-affiliation {
    height: 165px;
    margin-top: 30px;
    border-radius: 8px;
  }
  .icon-close {
    fill: white;
    position: absolute;
    width: 30px;
    cursor: pointer;
    margin: 10px;
    right: 0;
  }
  .link svg {
    fill: #e11f7b !important;
  }

  .bottom {
    .row {
      display: flex;
      margin: $spacing * 4 0;
      padding: 0 $spacing * 10;
      justify-content: space-between;
      .info:last-child {
        text-align: right;
      }
    }
    .row-subtitles {
      margin-top: 40px;
      margin-bottom: 0px;
    }
    .row-links {
      background-color: #262128;
      height: 50px;
      margin-top: 0px;
      align-items: center;
    }

    .--subtitle {
      margin: 0;
      font-size: 12px;
      color: $color-text-secondary-light;
    }
    .--text {
      font-size: 16px;
      color: $color-text-primary-light;
    }
    .link {
      color: inherit;
      display: flex;
      align-items: center;
      svg {
        margin-left: $spacing;
        fill: $color-primary;
        width: 20px;
      }
    }
  }
}
@media (min-width: $breakpoint-md) {
  // tablet and up
  .affiliation-modal {
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    max-height: 510px;
    max-width: 440px;
    transform: translate(-50%, -50%);
  }
}
