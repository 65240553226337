@import '../../variables.scss';

.isCompleted {
  height: 200px;
  position: relative;
  overflow: hidden;

  .isCompleted-bg {
    background-image: url('https://cdn.mangas.io/landing/feedback-bg-1-min.svg');
    background-size: contain;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 0;
  }

  .sparkle {
    animation: sparkling 1s linear infinite;
  }

  .second-sparkle {
    rotate: 90deg;
    animation: second-sparkling 2s linear infinite;
  }

  .third-sparkle {
    rotate: 180deg;
    animation: third-sparkling 6s linear infinite;
  }

  .content {
    position: relative;
    z-index: 1;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
  }
}

@keyframes sparkling {
  0% {
    transform: translateY(0);
    transform: scale(0);
  }
  50% {
    transform: translateY(50%);
    transform: scale(1.3);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes second-sparkling {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes third-sparkling {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(40%);
  }
  100% {
    transform: translateY(0);
  }
}
