@import '../../variables.scss';

.mangaline-tagContent {
  width: 40px;
  height: 50px;
  padding-right: 5px;
  background: #fcf8ff21;
  border-radius: 10px;
  margin-top: 15px;
  border: 1px solid $color-text-primary-light;
  display: flex;
  align-items: center;
  justify-content: center;
}
