@import '../../../variables.scss';

.subscription-slide {
  .line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $spacing * 2;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.02);
    min-height: 50px;
  }
  .subscription-cta-container {
    padding: $spacing * 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    .mio-button {
      margin-top: 20px;
    }
  }
  .subscription-logo {
    width: 100px;
    height: 20px;
    margin-top: 5px;
    object-fit: contain;
  }
  .currentStatusPill {
    padding: 5px 10px;
    border-radius: $radius;
    &.gradient-green {
      background: $gradient-green;
    }
    &.gradient-red {
      background: $gradient-red;
    }
    &.gradient-blue {
      background: $gradient-blue;
    }
    &.dark-red {
      background: rgb(139, 9, 9);
    }
    &.gradient-gold {
      background: rgb(234, 100, 25);
      background: linear-gradient(
        0deg,
        rgba(234, 100, 25, 1) 0%,
        rgba(235, 176, 25, 1) 50%
      );
    }
  }

  del {
    color: rgba(214, 79, 105, 0.58);
    font-size: 14px;
    font-weight: 600;
    padding: 0 $spacing;
  }

  .info {
    padding: $spacing * 2;
  }
}
