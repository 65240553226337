@import './variables.scss';

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.--spin {
  animation: spin 2s infinite linear;
}

// TYPOGRAPHY
.--title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  color: $color-text-primary-dark;
  .dark-mode & {
    color: $color-text-primary-light;
  }
}
.--subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: $color-text-primary-dark;
  .dark-mode & {
    color: $color-text-primary-light;
  }
}
.--title-s {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $color-text-primary-dark;
  &.secondary {
    opacity: 0.5;
  }
  .dark-mode & {
    color: $color-text-primary-light;
  }
}
.--text {
  font-size: 13px;
  line-height: 19px;
  margin: 0;
  color: $color-text-primary-dark;
  .dark-mode & {
    color: $color-text-primary-light;
  }
  b {
    color: $color-primary;
    font-weight: 600;
  }
}

.--text-secondary {
  line-height: 1.5em;
  margin: 0;
  color: $color-text-secondary-dark;
  .dark-mode & {
    color: $color-text-secondary-light;
  }
}
.--semibold {
  font-weight: 600;
}
.--secondary {
  opacity: 0.5;
}

.text-error {
  text-align: center;
  color: $color-text-primary-dark;
  .dark-mode & {
    color: $color-text-primary-light;
  }
}

// LAYOUT
.--content-block {
  background: #ffffff;
  padding: $spacing * 2;
  &.--no-padding {
    padding: 0;
  }
  .dark-mode & {
    background: #201a23;
  }
}

@media (min-width: $breakpoint-sm) {
  // big phone and up
}
@media (min-width: $breakpoint-md) {
  // tablet and up
  // TYPOGRAPHY
  .--title {
    font-size: 32px;
    line-height: 48px;
  }
  .--subtitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
  }
  .--title-s {
    font-size: 22px;
    line-height: 33px;
  }
  .--text {
    font-size: 15px;
    line-height: 24px;
  }
}
@media (min-width: $breakpoint-lg) {
  // desktop and up
}
@media (min-width: $breakpoint-xl) {
  // wide desktop and up
}
