@import '../../variables.scss';

#VolumePage {
  #responsive-columns.invert {
    padding: 35px;
  }
  .subtitle-text {
    color: #afafaf;
  }
  .first-bloc {
    padding: 35px;
  }
  .mio-bloc {
    @media (max-width: $breakpoint-sm) {
      border-radius: 0;
    }
    @media (max-width: $breakpoint-lg) {
      .content {
        padding: 0px;
      }
    }
  }
  .mio-placeholder-shape {
    display: none;
  }

  .volume-header {
    margin-bottom: 20px;
    width: 100%;
    background-position: center;
    display: flex;
    justify-content: space-between;
    background-size: cover;
    svg {
      fill: white;
    }
    @media (max-width: $breakpoint-lg) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .dropdown-container {
    margin-bottom: 20px;
    width: 120px;
  }
  .header-container {
    background: rgba(255, 255, 255, 0.03);
    padding: 15px;

    .carrousel-wrapper {
      padding: 0 0 20px 0;
    }
    .volume-header-title {
      #manga-title {
        font-weight: 300;
        font-size: 33px;
      }
      #tome-subtitle {
        font-weight: 300;
        #author-name {
          font-weight: 600;
          color: white;
        }
      }
      @media (max-width: $breakpoint-lg) {
        text-align: center;
      }
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    @media (min-width: $breakpoint-lg) {
      border-radius: 8px;
      margin-bottom: 15px;
    }
  }

  .volume-header-buttons {
    display: flex;
    flex-direction: column;
    .cover-img-container {
      #cover-img {
        width: 330px;
        height: 448px;
        border-radius: 8px;
      }
      @media (max-width: $breakpoint-lg) {
        background-color: #2c272f;
        padding: 30px 0px 30px 0px;
      }
    }
    svg {
      fill: white;
    }
    #button-header-volume {
      width: 330px;
    }
    #btn-affiliation-modal {
      width: 330px;
      margin-top: 15px;
    }

    .buttons {
      @media (min-width: $breakpoint-lg) {
        margin-top: 11px;
      }
      @media (max-width: $breakpoint-lg) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 25px 0px 25px 0px;
        background: rgba(255, 255, 255, 0.03);
      }
    }

    @media (max-width: $breakpoint-lg) {
      background-color: #2c272f;
      width: 100%;
      align-items: center;
    }
  }

  .mio-information {
    padding: $spacing * 3;
    border-radius: 8px;
    margin-bottom: $spacing * 3;
    @media (min-width: $breakpoint-lg) {
      background: rgba(255, 255, 255, 0.03);
    }
  }

  .affiliation-volume-modal {
    position: fixed;
    z-index: 1000;
    border-radius: 8px;
    max-width: 380px;
    top: $spacing * 2;
    left: $spacing * 2;
    right: $spacing * 2;
    background: #2c272f;
    animation: fade1 0.1s ease-in;
    .affiliation-bloc {
      #icon-affiliation-modal {
        color: white;
        width: 24px;
        background: rgba(255, 255, 255, 0.15);
        padding: 4px;
        border-radius: 90%;
        height: 24px;
        cursor: pointer;
      }
    }
    .content {
      padding: 35px;
    }
    svg {
      fill: black;
    }
    @keyframes fade1 {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @media (min-width: $breakpoint-sm) {
      // tablet and up

      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
    }
  }

  .volume-chapters-bloc {
    margin-top: 35px;
    margin-bottom: 35px;

    @media (max-width: $breakpoint-sm) {
      background-color: #2c272f;
      border-radius: 0px;
      margin-top: 15px;
      margin-bottom: 15px;
      .content {
        padding: 0px;
      }
    }
  }
  .category-tags {
    display: flex;
    flex-wrap: wrap;
    line-height: 40px;
  }
  .volume-tomes-bloc {
    .volume-mangas-list {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      ::after {
        flex: auto;
      }
      #manga-tile {
        margin: 8px;
      }
      @media (max-width: $breakpoint-lg) {
        justify-content: center;
      }
    }
    @media (max-width: $breakpoint-sm) {
      border-radius: 0px;
    }
    @media (max-width: $breakpoint-lg) {
      .content {
        padding: 15px 14px 15px 14px;
      }
    }
  }
}
