@import '../../variables.scss';

$smallWidth: 135px;
$mediumWidth: 180px;
$largeWidth: 232px;
$ratio: 1.5;

.RandomPages {
  .page {
    width: $smallWidth;
    height: $smallWidth * $ratio;
  }

  @media (min-width: $breakpoint-md) {
    .page {
      width: $mediumWidth;
      height: $mediumWidth * $ratio;
    }
  }

  @media (min-width: $breakpoint-lg) {
    .page {
      width: $largeWidth;
      height: $largeWidth * $ratio;
    }
  }

  img {
    width: 100%;
    height: 100%;
    background-color: $background-lighter;
    display: inline-block;
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
  }
}
