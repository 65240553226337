@import '../../../variables.scss';

#ReaderControl {
  // background-color: green;
  .controls {
    position: absolute;
    display: flex;
    align-items: center;
    // justify-content: center;
    top: 0;
    bottom: 0;
    width: 50%;
    &.hidden {
      display: none;
    }
    &.right {
      // background-color: rgba(255, 0, 0, 0.5);
      right: 0;
      justify-content: flex-end;
    }
    &.left {
      // background-color: rgba(0, 255, 0, 0.5);
      left: 0;
      justify-content: flex-start;
    }
    .icon-wrapper {
      // background-color: red;
      svg {
        // display: none;
        height: 80px;
        fill: rgba(255, 255, 255, 0.8);
        opacity: 0;
        transform: scale(0.8);
        background-color: rgba(255, 255, 255, 0.15);
        border-radius: 40px;
        margin: 20px;
        transition: opacity $animation-speed-fast ease-in,
          transform $animation-speed-fast ease-in;
      }
      &.visible svg {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}
