@import '../../variables.scss';

.mangaCards-container-selector {
  display: flex;
  flex-direction: row;
  margin-bottom: 17px;

  .button {
    margin-right: 10px;
    z-index: 5;
  }
}

.mangaCards-container {
  .pair {
    margin-right: 15px !important;
  }
  .mangaCard {
    margin-bottom: 15px;
    background: rgba(44, 39, 47, 1);

    width: 417px;
    .title {
      margin-left: 0;
    }
    .mio-tag {
      margin-right: 5px;
    }

    &:hover,
    &:focus {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.05),
          rgba(255, 255, 255, 0.05)
        ),
        linear-gradient(0deg, #262128, #262128);
    }
    &:active {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0.1)
        ),
        linear-gradient(0deg, #262128, #262128);
    }
  }
  .progressBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    .progressBar-number {
      margin-left: 10px;
    }
  }
}
