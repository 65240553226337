@import '../../../variables.scss';

#LandingPopularManga {
  text-align: center;
  .mio-text.secondary {
    color: $color-text-secondary-light;
  }
  #simultradPill {
    background: linear-gradient(90deg, #ef5e0c 0%, #d82323 100%);
  }
  .mio-pill {
    margin: $spacing * 2 $spacing;
  }

  .pop-card {
    position: relative;
    text-align: left;
    border: 1px solid #3f3f3f;
    border-radius: 16px;
    background: #252027;
    max-width: 974px;
    width: 100%;
    min-height: 516px;
    margin: 80px auto;
    padding: 32px 44px;
    overflow: hidden;
    padding-right: calc(36.52% + 56px);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &.purple-drop-shadow {
      box-shadow: -11px 5px 26px rgba(186, 6, 179, 0.15),
        11px -5px 26px rgba(255, 0, 92, 0.15);
    }

    .cover {
      position: absolute;
      z-index: 0;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-size: cover;
      background-position: center;
    }

    .cover-mobile {
      z-index: 0;
      display: none;
    }

    &.left-cover {
      padding-right: 44px;
      padding-left: calc(36.52% + 56px);

      .--title img {
        margin-left: 0;
      }
    }

    .--title {
      position: relative;
      text-transform: uppercase;
      font-size: 2rem;
      line-height: 1.5em;
      margin: 0;
      img {
        margin: 0;
        padding: 0;
        display: inline-block;
      }
    }

    .note {
      position: relative;
      font-size: 1.375rem;
      line-height: 1.5em;
      color: $color-text-primary-light;
      margin-bottom: 0.73em;
      .stars {
        vertical-align: middle;
        margin-bottom: 3px;
        margin-right: 0.73em;
        height: 1.73rem;
        width: auto;
      }

      .--text-secondary {
        font-size: 0.73em;
        white-space: nowrap;
      }
    }

    .--text {
      position: relative;
      font-size: 1rem;
      line-height: 1.5em;
      color: $color-text-primary-light;
      margin-bottom: 1.33em;
    }

    .tags {
      .Pill .background {
        background-color: #646066 !important;
      }
    }

    > a:hover {
      text-decoration: none;
    }

    .mio-button {
      position: relative;
      margin: 0;
    }

    @media (max-width: 900px) {
      padding: 0;
      padding-bottom: 25px;
      max-width: 500px;
      margin: 30px auto;
      text-align: center;

      &.first {
        margin-top: 50px;
      }
      .cover {
        display: none;
      }
      &.left-cover {
        padding-left: 0;
        padding-right: 0;
      }
      .cover-mobile {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        background-position: center;
      }

      .--title {
        font-size: 1.375rem;
        margin: 0 auto;
        margin-bottom: 5px;
        padding: 0 15px;
        max-width: 85%;
      }

      .note {
        margin-top: 0;
        font-size: 1.125rem;
        padding: 0 15px;
      }

      .--text {
        padding: 0 15px;
        font-size: 0.875rem;
      }

      .mio-button {
        display: inline-flex;
        max-width: calc(100% - 30px);
        margin: 0 15px;
      }
    }
  }

  @media (max-width: 900px) {
    padding: 10px;
    .faq-footer {
      font-size: 0.875rem;
      padding: 0;
    }
  }
}
