@import '../../variables.scss';

#legalPage {
  background-color: $background-dark;
  .header {
    margin: 20px 50px;
    padding: 100px 50px;
    border-radius: 20px;
    height: 400px;
    background-image: url('https://res.cloudinary.com/wayne75/image/upload/v1669714707/Nouveau_projet_3_duuttj.png');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: $color-text-primary-light;
    font-size: 40px;
    a {
      color: $color-text-primary-light;
    }
  }
  .content {
    margin: 20px 100px;
    color: $color-text-primary-light;
    a {
      color: $color-primary;
    }
  }
  @media (max-width: $breakpoint-md) {
    .header {
      margin: 0px;
      font-size: 25px;
    }
    .content {
      margin: 20px;
    }
  }
}
