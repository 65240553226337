@import '../theme/colors.scss';
@import '../theme/layout.scss';

.mio-bloc {
  background-color: $black-background;

  border-radius: $radius;
  &.mobile {
    border-radius: 0;
  }

  & > * {
    padding: $spacing * 3 $spacing * 3 $spacing * 3 $spacing * 6;
  }

  &.mobile > * {
    padding: $spacing * 3;
  }

  .header {
    border-bottom: 3px solid rgba(255, 255, 255, 0.15);
    display: flex;
    flex-direction: column;
    .title_bloc {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .subtitle {
      opacity: 0.7;
    }
    &.invertTitles {
      flex-direction: column-reverse;
    }
  }

  .pre-header-action {
    padding: 7px 15px 7px 15px;
    color: white;
    height: 34px;
    border-radius: $radius $radius 0 0;
  }
}
