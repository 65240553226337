@import '../../variables.scss';

.Banner {
  border-radius: 8px;
  display: flex;
  padding: $spacing * 3;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: #2c272f;
  justify-content: space-between;

  .left {
    // width: 100%;
    display: flex;
    align-items: center;
  }
  .content {
    line-height: 1.75em;
    color: white;
    // padding: $spacing * 4 $spacing * 4 $spacing * 4 0;

    .text {
      color: inherit;
    }

    b,
    strong {
      font-weight: 500;
    }
  }
  .side {
    // padding: $spacing * 4;
    margin-right: $spacing * 3;
  }
  .icon-outline {
    padding: 8px;
    border: 1px solid #ffffff;
    border-radius: 50%;
  }
  svg {
    display: block;
    width: 24px;
    height: auto;
    fill: white;
  }
  .banner-link {
    display: inline-block;
    flex-shrink: 0;
    margin-left: $spacing * 3;

    .mio-button {
      .label {
        font-weight: 600;
        line-height: 1.5em;
      }
    }
  }
  &.banner-kana {
    background: rgba(241, 233, 106, 0.15);
    svg {
    }

    .mio-button {
      background: linear-gradient(90deg, #f1e96a 0%, #ffff01 100%);
      .label {
        color: black;
      }
    }
  }
  &.HomePage-banner {
    margin: 40px 15px 0;

    @media (min-width: $breakpoint-md) {
      margin-left: 55px;
      margin-right: 55px;
    }
    @media (min-width: $breakpoint-lg) {
      margin-left: 80px;
      margin-right: 80px;
    }
  }
  @media (max-width: 500px) {
    display: block;

    .banner-link {
      width: 100%;
      margin: 0;
      margin-top: $spacing * 3;

      .mio-button {
        width: 100%;
      }
    }
  }
}
