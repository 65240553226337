@import '../theme/colors.scss';
@import '../theme/layout.scss';

.mio-drawer {
  width: 100%;
  background: rgba(102, 89, 89, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  padding: 15px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    border: 1px solid #ffffff;
  }

  .left {
    width: 100%;
  }

  .right {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
}
