@import '../../../variables.scss';

#LandingFaq {
  text-align: center;

  .section-container {
    position: relative;
    overflow: hidden;
  }

  .content-wrapper {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 0 25px;
  }

  .faq-title {
    font-size: 2.25rem;
    line-height: 1.5em;
    font-weight: 700;
    color: white;
    margin: 0;
  }

  .faq-container {
    max-width: 750px;
    display: inline-block;
  }

  .faq-footer {
    color: white;
    font-size: 1.125rem;
    padding: 0 20px;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  .faq {
    font-size: 1.25rem;
    text-align: left;
    margin-bottom: 50px;
    margin-top: 50px;
    padding: 0;

    .faq-row {
      list-style: none;
      background: #29252d;
      border-radius: 8px;
    }

    .faq-question,
    .faqAnswer {
      display: block;

      .content {
        padding: 2px 25px;
      }
    }

    .faq-question {
      position: relative;
      margin-top: 10px;
      cursor: pointer;

      .mio-placeholder-shape {
        display: inline-block;
      }

      .content {
        padding-right: 60px;

        .title {
          font-size: 1.25rem;
          line-height: 1.5em;
          font-weight: 400;
          padding: 20px 0;
          margin: 0;
          color: white;
        }

        p {
          color: white;
        }
      }

      .close {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 15px;
        width: 35px;
        height: 35px;
        transform: translateY(-50%);
        transition: transform 0.2s ease-in-out;
        color: white;
      }

      &.active {
        .close {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    .faqAnswer {
      // margin-top: 5px;
      transition: all 0.2s ease-in-out;
      // transform: scaleY(0);
      // transform-origin: 50% 0;
      overflow: hidden;
      max-height: 0;

      .mio-placeholder-shape {
        display: block;
        margin-bottom: 4px;
      }

      .content {
        margin-top: 0px;
        color: #afafaf;
        line-height: 1.5em;
        margin-bottom: 22px;

        p {
          font-size: 1em;
        }

        a {
          color: inherit;
          text-decoration: underline;
        }
      }
    }

    .faq-row:target .faqAnswer,
    .faqAnswer.active {
      max-height: 900px;
      // transform: scaleY(1);
    }

    @media (max-width: 900px) {
      .faq-question .content .title {
        font-size: 0.9375rem;
        line-height: 1.5em;
      }

      .faq-question .content,
      .faqAnswer .content {
        p {
          font-size: 0.875rem;
          line-height: 1.5em;
        }
      }

      .faq-question .close {
        width: 30px;
        height: 30px;
      }
    }

    @media (max-width: 500px) {
      .faq-row {
        border-radius: 0;
      }
      .faq-question .content,
      .faqAnswer .content {
        padding-left: 15px;
      }
    }
  }

  @media (max-width: 900px) {
    .faq-title {
      font-size: 1.5rem;
      line-height: 1.5em;
    }

    .faq-footer {
      font-size: 0.9375rem;
      line-height: 1.5em;
    }
  }

  @media (max-width: 500px) {
    .content-wrapper {
      padding: 0;
    }
  }
}
