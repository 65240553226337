@import '../../variables.scss';

.burgerCursor {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>🍔</text></svg>")
      16 0,
    auto;
}
.customHeader-images {
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }
}
.RedeemPage {
  [data-aos] {
    opacity: 1;
    transform: initial;
  }
  @media (max-width: $breakpoint-md) {
    margin-bottom: 100px;
  }
  .warningText {
    margin-top: 20px;
  }
  button:disabled {
    opacity: 0.5;
  }
  .code-validation-input {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    .mio-button {
      margin-left: $spacing * 3;
      margin-top: $spacing;
    }
    .mio-input {
      max-width: 250px;
      margin: 2px;
    }

    @media (min-width: $breakpoint-lg) {
      justify-content: flex-start;
    }
  }
  #copyrights {
    padding: 0;
    text-align: center;
    .mio-text {
      font-style: italic;
    }
    a {
      color: $color-text-primary-light;
    }
  }

  #moreMangas {
    margin-right: 24px;
    margin-left: 24px;
    margin-bottom: 50px;

    .title {
      margin-bottom: 50px;
    }
    .buttonMangas {
      display: flex;
      justify-content: center;
    }
    .titleBloc {
      justify-content: center;
    }
    .controls {
      svg {
        color: black;
      }
    }
  }
  @media (max-width: $breakpoint-lg) {
    #moreMangas {
      margin-right: 5px;
      margin-left: 5px;
      .title {
        text-align: center;
      }
    }
  }
  #faq {
    padding: 10px;
    .content-wrapper {
      padding: 0;
    }
  }
  .codeValidation {
    display: flex;
    align-items: center;
    padding: $spacing * 4 0;
    @media (max-width: 900px) {
      justify-content: center;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: $spacing;
    }
    &.valid {
      svg {
        fill: rgb(23, 117, 23);
      }
    }
    &.invalid {
      svg {
        fill: rgb(153, 28, 28);
      }
    }
    &.loading {
      svg {
        fill: rgb(197, 197, 197);
      }
    }
  }
}
