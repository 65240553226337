@import '../../variables.scss';

#LibraryPage {
  .LibraryPage-header {
    margin-top: 30px;
    padding-left: 30px;
    .categoryShortDescription {
      max-width: 763px;
      white-space: pre-wrap;
    }
  }

  .LibraryPage-nav {
    margin-top: 20px;
    margin-bottom: 35px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .link {
      display: inline-block;
      margin-bottom: $spacing * 2;
      margin-right: $spacing * 2;
    }
  }

  .LibraryMangaTile {
    .MangaCover {
      background-color: transparent;
    }
  }

  .text-error {
    margin-bottom: 20px;
  }
  .categoryTitle,
  .categorySubtitle {
    margin-bottom: 15px;
  }

  @media (min-width: $breakpoint-sm) {
    // tablet and up

    .LibraryPage-content {
      padding: $spacing * 8 0;
    }
    .LibraryPage-nav {
      margin-top: 20px;
      margin-bottom: 35px;
      .link {
        margin-right: $spacing * 2;
      }
    }

    .LibraryMangaTile {
      .info .--title,
      .info .--text {
        font-size: 0.875rem;
      }
    }

    .LibraryPage-top3 .title {
      font-size: 1.375rem;
    }

    .LibraryPage-infos {
      margin: 150px auto;

      .infos-title {
        font-size: 1.375rem;
      }
      .infos-text {
        font-size: 1rem;
      }
    }
  }
  @media (min-width: $breakpoint-lg) {
    // desktop and up
    .LibraryPage-header {
      padding-left: 0;
    }
    .LibraryPage-nav {
      padding: 0;
    }
  }
}
.LibraryPage-infos {
  margin: 30px auto;
  background: rgba(26, 23, 28, 1);
  .textDescription {
    max-width: 763px;
    margin: 0 auto;
    padding: 80px 30px;
    white-space: pre-wrap;
  }
  .subtitleSecondary {
    margin-bottom: 30px;
  }
  @media (min-width: $breakpoint-lg) {
    .textDescription {
      padding: 80px 0;
    }
  }
}
