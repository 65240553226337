@import '../../../variables.scss';

.cancel-subscription-code-slide {
  .floatingmenu-body {
    padding: $spacing * 2 $spacing * 3;

    .mio-text {
      margin-bottom: $spacing * 2;
    }
    .error {
      color: #c4143d;
    }
    .mio-button {
      margin-bottom: $spacing * 2;
      margin-top: $spacing * 2;
    }
    .description {
      margin-top: $spacing * 4;
      text-align: center;
    }
  }
}
.validation-message {
  margin-top: $spacing * 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .mio-button {
    margin-top: $spacing * 5;
  }
}
