@import '../theme/colors.scss';
@import '../theme/layout.scss';

.mio-pill {
  color: $primary-white;
  border: 1px solid $hover-highlight;
  border-radius: $radius;
  display: inline-block;

  &.small {
    padding: 2px 4px;
    font-size: 12px;
    line-height: 16px;
  }

  &.medium {
    font-size: 14px;
    padding: 2px 6px;
    line-height: 18px;
  }

  &.large {
    font-size: 16px;
    padding: 2px 8px;
    line-height: 21px;
  }
}
