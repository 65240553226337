@import '../../variables.scss';

.subscription {
  margin-top: 25px;
  margin-bottom: 20px;
  z-index: 1;
  color: $color-text-primary-light;

  .panels {
    position: relative;
    display: flex;
    flex-direction: row;
  }

  .panel {
    max-width: 357px;
    padding: 15px;
    .mainCard {
      text-align: center;
    }
    .bottomCard {
      text-align: center;
    }
  }

  .subscription .title {
    font-size: 0.875rem;
    line-height: 1.5em;
    text-transform: uppercase;
    font-weight: 600;
    color: $color-text-primary-light;
  }
  a {
    text-align: center;
  }
}
.box-notice {
  color: $color-text-primary-light;
  background: rgba(255, 183, 42, 0.15);
  backdrop-filter: blur(26px);
  border-radius: 8px;
  padding: 15px;
  margin: 65px auto 20px;
  font-size: 0.875rem;
  line-height: 1.75em;
  display: inline-flex;
  text-align: left;
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;

  .icon {
    margin-right: 15px;
    margin-top: 0px;
  }
}

@media (max-width: 900px) {
  .box-notice {
    margin-left: 10px;
    margin-right: 10px;
  }
  .subscription {
    .panels {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .panel {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
