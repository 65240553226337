// RESPONSIVE
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

// ANIMATIONS
$animation-speed: 0.3s;
$animation-speed-fast: 0.15s;

// LAYOUT
$navbar-height: 60px;
$reader-menu-height: 50px;
$readerProgressBarHeight: 2;

// THEMING
$color-primary: #e11f7b;
$color-text-primary-dark: #151018;
$color-text-primary-light: #ffffff;
$color-text-secondary-dark: #646066;
$color-text-secondary-light: #afafaf;

$color-border: rgba(255, 255, 255, 0.15);
$background-dark: #151018;
$background-lighter: #646066;
$background-light: #fcf8ff;
$background-nav: #231b27;
$background-placeholder: linear-gradient(
  115deg,
  rgba(255, 255, 255, 0.1) 0%,
  rgba(255, 255, 255, 0.05) 100%
);
$background-banner: linear-gradient(75deg, #00e8f7 0%, #074dff 100%);

$spacing: 5px;
$radius: 8px;

$cdn: 'https://cdn.mangas.io';

$gradient-green: linear-gradient(180deg, #49da84 0%, #0d9845 100%);
$gradient-red: linear-gradient(180deg, #ff4d4d 0%, #ff0000 100%);
$gradient-pink: linear-gradient(180deg, #d91d6f 0%, #92124a 100%);
$gradient-blue: linear-gradient(180deg, #00e8f7 0%, #074dff 100%);
$gradient-blue-purple: linear-gradient(90deg, #273483 0%, #800c57 100%);
$gradient-dark-blue: linear-gradient(
  180deg,
  #420294 0%,
  #566cbe 49%,
  #566cbe 100%
);
$gradient-purple-red: linear-gradient(90deg, #c52002 0%, #98039b 100%);
$gradient-gold-pink: linear-gradient(135deg, #a34a18 0%, #75135f 100%);
$gradient-passculture: linear-gradient(90deg, #711b79 0%, #a11c71 100%);
