@import '../../variables.scss';

#ReaderPage {
  position: absolute;
  z-index: 900;
  overflow-x: hidden;
  top: 0;
  width: 100%;
  min-height: 100vh;
  background: #f8f8f8;
  color: white;
  display: flex;
  flex-direction: column;

  .dark-mode & {
    // dark mode css here
    background: $background-dark;
  }

  @media (min-width: $breakpoint-sm) {
    // big phone and up
  }
  @media (min-width: $breakpoint-md) {
    // tablet and up
  }
  @media (min-width: $breakpoint-lg) {
    // desktop and up
    overflow: hidden;
    height: 100vh;
  }
  @media (min-width: $breakpoint-xl) {
    // wide desktop and up
  }
}
