@import '../../variables.scss';

#PostPage {
  .PostPage-container {
    .featured_image {
      position: relative;
      width: 100%;
      height: 316px;
      background-size: cover;
      background-position: center center;
      overflow: hidden;
      margin-bottom: -144px;
    }

    .content-wrapper {
      position: relative;
      max-width: 860px;
      width: 100%;
      margin: 0 auto;
      overflow: hidden;
    }

    .breadcrumb {
      display: inline-block;
      background-color: rgba(0, 0, 0, 0.65);
      border-radius: 21px;
      color: $color-text-secondary-light;
      font-size: 1rem;
      line-height: 1.5em;
      padding: 5px 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 10px 0;
      max-width: 97%;

      a {
        color: $color-text-primary-light;
      }
    }

    .PostPage-article {
      background-color: white;
      border-radius: 8px;
      padding: 45px 5.3%;
      margin-bottom: 80px;

      .title {
        font-size: 1.875rem;
        line-height: 1.8em;
        font-weight: 400;
        margin: 0 0 20px 0;
      }

      .author {
        margin: 2em 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-size: 0.875rem;
        line-height: 1.5em;

        p {
          margin: 0;
        }

        .author_avatar {
          width: 70px;
          height: 70px;
          border: 4px solid white;
          overflow: hidden;
          border-radius: 50%;
          float: left;
          margin-right: 10px;
          // box-shadow: 0px 0px 3px 0px rgba(0,0,0,.25);
        }

        .datetime {
          opacity: 0.55;
        }
      }

      .subtitle {
        font-size: 1.3rem;
        line-height: 1.78em;
        font-weight: 400;
        margin-bottom: 2em;
        margin-top: 2em;
      }

      .body {
        font-size: 1rem;

        h1 {
          font-size: 1.5em;
          line-height: 1.7em;
          font-weight: 600;
          margin-top: 2.5em;
        }
        h2 {
          font-size: 1.25em;
          line-height: 1.7em;
          font-weight: 600;
          margin-top: 2.5em;
        }
        p,
        ul,
        ol {
          font-size: 1.125em;
          line-height: 1.78em;
        }
        a {
          color: $color-primary;
          &:hover {
            text-decoration: underline;
          }
        }
        ul {
          li {
          }
        }
        .block-img {
          text-align: center;
          margin-top: 2em;
          img {
            max-width: 80%;
          }
        }
        .embed {
          margin-top: 2em;
          text-align: center;
          > div {
            display: inline-block;
            width: 80%;
            position: relative;
            padding-top: 55%;
          }
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }

      .home_link {
        font-size: 1.125em;
        line-height: 1.78em;
        color: $color-text-secondary-dark;
        margin-bottom: 20px;
        display: inline-block;

        &:hover {
          text-decoration: underline;
        }

        svg {
          width: 26px;
          height: 26px;
          fill: $color-text-secondary-dark;
          margin-right: 10px;
          vertical-align: text-bottom;
        }
      }
    }

    @media (max-width: 860px) {
      .featured_image {
        margin-bottom: 0;
      }
      .PostPage-article {
        border-radius: 0;
      }
      .breadcrumb {
        margin: 10px;
        margin-top: 15px;
      }
    }

    @media (max-width: $breakpoint-md) {
      .breadcrumb {
        font-size: 0.875rem;
      }

      .PostPage-article {
        .title {
          font-size: 1.625rem;
        }
        .subtitle {
          font-size: 1.1rem;
        }
        .body {
          font-size: 0.875rem;
        }
      }
    }
  }

  .blogPage-content-wrapper {
    max-width: 1281px + (80px * 2);
    width: 100%;
    margin: 0 auto;
    padding: 0 5.3%;
    overflow: hidden;
  }

  .blogPostRelated {
    color: $color-text-primary-light;
    font-size: 1.375rem;
    line-height: 1.68em;
    font-weight: 600;
    margin: 0;
  }

  .BlogGrid {
    //padding: $spacing * 5 $spacing * 3;
    //display: flex;
    margin-top: 30px;
    margin-bottom: 170px;

    .BlogGrid_item {
      margin-bottom: 10px;
    }
  }
}
