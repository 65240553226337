@import '../theme/colors.scss';
@import '../theme/layout.scss';

.mio-cornertag {
  background: $dark-background;
  padding: 2px 10px;
  border-radius: 0px $radius;
  color: $primary-white;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  &.gold {
    background: $gold-gradient;
  }
  &.silver {
    background: $silver-gradient;
  }
  &.bronze {
    background: $bronze-gradient;
  }
  &.primary {
    background: $primary;
  }
}
