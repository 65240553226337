// N'importer que les fichiers nécessaires
@import '../theme/colors.scss';
@import '../theme/layout.scss';

// Chaque composant a une classe unique préfixée par mio-
.mio-separator {
  width: 100%;
  color: $secondary-white;
  padding: $spacing-3;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 14px;
  line-height: 21px;
}

.mio-separator::before,
.mio-separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid $secondary-white;
}

.mio-separator:not(:empty)::before {
  margin-right: $spacing-3;
}

.mio-separator:not(:empty)::after {
  margin-left: $spacing-3;
}
