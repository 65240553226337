@import '../../variables.scss';

.AppStore-buttons {
  //margin-bottom: $spacing * 4;
  // padding: 0 $spacing * 4;

  flex-direction: column;

  .title {
    margin-top: 10px;
  }

  .buttons {
    display: inline-flex;

    flex-wrap: wrap;
    @media (max-width: $breakpoint-md) {
      justify-content: center;
    }
  }

  .storeButtonLink {
    margin-top: $spacing * 2;
  }

  .storeButtonLink:not(:last-child) {
    margin-right: $spacing * 2;
  }
}

.storeButton {
  padding: 0;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;

  img {
    width: auto;
    height: 46px;
  }
}
