@import '../../variables.scss';

.Preview {
  width: 100%;
  flex-direction: column;
  margin-top: $spacing * 6;
  margin: 0 auto;
  margin-bottom: 65px;
  @media (max-width: $breakpoint-lg) {
    border-radius: 0px;
  }
  .header {
    padding: 15px 30px 15px 30px;
    .titleRight {
      display: flex;
      align-items: center;
      svg {
        color: white;
        width: 24px;
      }
      .title {
        padding-right: 7px;
        padding-left: 7px;
      }
    }
  }
  .content {
    padding: 40px;
  }
  .preview-wrapper {
    background-color: #ffffff;
    align-items: center;
    border-radius: 8px;
    background-position: center center;
    background-size: cover;

    .dark-mode & {
      background-color: #262128;
    }
  }
  .header-mobile {
    margin-left: $spacing * 5;
    h2 {
      color: $color-primary;
      font-weight: 500;
    }
  }

  .reader {
    display: flex;
    overflow: hidden;
    align-items: center;
    position: relative;
    .pages {
      display: flex;
      transition: transform $animation-speed ease;
      width: 100%;
    }

    .read-more {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #262128;
      &.mentions {
        align-self: center;
        padding: 5%;
        background-color: rgba(255, 255, 255, 0.15);
        border-radius: 16px;
        text-align: center;
        overflow: scroll;

        @media (max-width: $breakpoint-lg) {
          #mentions-text {
            font-size: 12px;
            line-height: 15px;
          }
        }

        @media (max-width: $breakpoint-sm) {
          #mentions-text {
            font-size: 8px;
            overflow: hidden;
            line-height: 15px;
          }
        }
      }
      .chapterTitle {
        margin-bottom: 15px;
        text-align: center;
        line-height: 24px;
      }
      img {
        max-width: 30%;
        max-height: 248px;
        border-radius: 8px;
        margin-bottom: 20px;
      }
      .tagList {
        margin-top: 15px;
        margin-bottom: 34px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        #tag {
          margin-left: 3.5px;
          margin-bottom: 3.5px;
          margin-right: 3.5px;
        }
      }
    }
    .page {
      position: relative;
      background-size: cover;
      background-position: center center;
      flex-shrink: 0;
    }
    .PageTurn {
      .icon-wrapper {
        width: $spacing * 10;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 100%;
          border-radius: 0;
          background-color: transparent;
          margin: 0;
        }
      }
    }
  }
  .actions {
    display: flex;
    justify-content: space-evenly;
    height: 84px;
    align-items: center;
    padding-bottom: $spacing * 3;
  }

  @media (min-width: $breakpoint-sm) {
    // big phone and up
    .header-mobile {
      margin-left: $spacing * 7;
    }
  }
  @media (min-width: $breakpoint-md) {
    // tablet and up
    margin-top: $spacing * 10;
    display: flex;
    .preview-wrapper {
      display: flex;
      padding: $spacing * 10 $spacing * 10;
    }
    .reader,
    .info {
      flex: 1;
    }
    .reader {
      .PageTurn {
        .icon-wrapper {
          &.visible {
            svg {
              box-shadow: 0px 0px 2px black;
            }
          }
        }
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      h2 {
        color: $color-primary;
      }
      .description {
        margin: $spacing * 9 0 $spacing * 5 0;
      }
      .details {
        padding: 0 $spacing * 10;
      }
      .actions {
        justify-content: flex-start;
        padding: 0 $spacing * 10;
        .mio-button {
          margin-right: $spacing * 4;
        }
      }
    }
  }
  @media (min-width: $breakpoint-lg) {
    max-width: 942px;
    // desktop and up
    .preview-wrapper {
      padding: $spacing * 5 $spacing * 15;
    }
  }
}
