@import '../../../variables.scss';

.voucher-code-slide {
  padding: 0 $spacing * 3;
  & > * {
    margin-top: $spacing * 5;
  }
  .warningText {
    text-align: center;
  }
}
