@import '../../variables.scss';

.nudge-banner {
  margin-top: $spacing * 5;
  padding: 0 15px;

  @media (min-width: $breakpoint-lg) {
    padding: 0;
    margin-right: 0;
    margin-left: 0;
  }
}
