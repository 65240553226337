@import '../../variables.scss';

.disclaimer {
  background: rgba(32, 215, 255, 0.15);
  border: 1px solid #20d7ff;
  border-radius: 8px;
  display: flex;

  .content {
    padding: 10px;
  }
  .side {
    display: flex;
    align-items: flex-start;
    padding: 10px 0;
    padding-left: 15px;
  }
  svg {
    fill: #20d7ff;
    width: 20px;
    margin-top: 2px;
  }

  &.disclaimer-success {
    background: rgba(33, 189, 77, 0.15);
    border-color: #21bd4d;
    svg {
      fill: #21bd4d;
    }
  }
}
