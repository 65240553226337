@import '../../variables.scss';

$small-margin: 15px;
$small-gutter: 15px;
$medium-margin: 55px;
$medium-gutter: 25px;
$large-margin: 80px;
$large-gutter: 30px;

.carrousel-wrapper {
  padding: 20px 0;

  .mio-carrousel .elements > * {
    margin-right: $small-gutter;
  }
  .pre-title {
    color: $color-primary;
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 600;
  }
  .titleBloc {
    display: flex;
  }
  .title {
    margin-top: 0;
    margin-bottom: 17px;
  }
  .subtitleLink {
    display: flex;
    align-items: center;
    margin-bottom: 17px;
    margin-left: 20px;
  }
  .subtitle {
    color: $color-text-secondary-dark;
  }
  .subtitleChevron {
    color: $color-text-secondary-dark;
    margin-left: 10px;
  }
  @media (min-width: $breakpoint-md) {
    .mio-carrousel .elements > * {
      margin-right: $medium-gutter;
    }

    .controls.left {
      left: 10px;
    }
    .controls.right {
      right: 10px;
    }
  }

  @media (min-width: $breakpoint-lg) {
    .mio-carrousel .elements > * {
      margin-right: $large-gutter;
    }

    .controls.left {
      left: 20px;
    }
    .controls.right {
      right: 20px;
    }
  }
}
