@import '../../variables.scss';

.pricing-card-highlight {
  margin-top: $spacing * 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-position: 50% 50%;
  padding: $spacing * 2;
  border-radius: $radius;

  & > * {
    margin-top: $spacing * 5;
  }

  .pricing-card-highlight-header {
    background-size: cover;
    background-position: 50% 50%;
    padding: $spacing * 3 $spacing * 5;
    position: relative;
    img {
      width: 60px;
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(50%, -30%);
      display: none;
      @media (min-width: $breakpoint-sm) {
        display: inline-block;
      }
    }
  }

  .mio-pill {
    color: #ef5d0d;
    font-weight: 600;
  }

  .pricing-card-highlight-price {
    display: flex;
    align-items: center;

    .mio-text:first-of-type {
      font-size: 60px;
    }

    & > * {
      margin: 0 $spacing;
    }
  }
}
