// N'importer que les fichiers nécessaires
@import '../theme/colors.scss';
@import '../theme/layout.scss';

.mio-text-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  width: 100%;
  opacity: 0.7;

  textarea {
    display: block;
    width: 100%;
    border: 2px solid white;
    border-radius: 4px;
    background-color: transparent;
    padding: 0.8125em 1em;
    font-size: 1rem;
    line-height: 1.75em;
    color: rgba(255, 255, 255, 0.7);
    border-color: inherit;
    &::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  label {
    opacity: 0.7;
    line-height: 1.75em;
  }

  &.disabled {
    opacity: 0.3;
    input {
      color: rgba(255, 255, 255, 0.3);
    }
  }

  &:hover:not(.disabled),
  &.active {
    opacity: 1;
  }
}
