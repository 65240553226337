.mio-activation-password {
  .mio-text.header {
    text-align: center;
  }
  .mio-button {
    margin-top: 100px;
  }
  .successIcon {
    color: #21bd4d;
    width: 131px;
  }
}
