@import '../../variables.scss';

.MuiTooltip-popperInteractive {
  .MuiTooltip-tooltip {
    font-size: 0.75rem;
    line-height: 1.5em;
    font-weight: normal;
    max-width: 230px;
    background-color: #38343a;
    p {
      margin: 5px 0;
    }
    a {
      color: white;
      font-weight: 600;

      svg {
        width: 20px;
        height: 20px;
        fill: white;
        vertical-align: middle;
        margin-top: -1px;
      }
    }
  }
  .MuiTooltip-arrow {
    color: #38343a;
  }
}

#LandingAdvantage {
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px;
  .partners-advantage-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.centerContent {
      justify-content: center;

      .partners {
        width: 100%;
      }
    }

    .partners {
      text-align: left;
    }

    .advantage {
      text-align: left;
      width: 32%;
    }

    .section-title {
      font-size: 1rem;
      line-height: 1.5em;
      color: $color-text-secondary-light;
      font-weight: normal;
      padding-top: 1px;
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;

      &:after {
        content: '';
        display: table;
        clear: both;
      }

      span {
        padding-right: 15px;
        flex-shrink: 0;
      }

      .hr {
        border: 1px solid white;
        opacity: 0.2;
        border-width: 1px 0 0 0;
        margin-top: 11px;
        width: 100%;
      }
    }

    .pass-culture {
      border-radius: 22px;
      overflow: hidden;
      display: inline-block;
      margin-bottom: 20px;
      img {
        display: block;
      }
    }
    .pass-culture-text {
      color: rgba(255, 255, 255, 0.7);
      font-size: 1rem;
      line-height: 1.75em;
      strong {
        font-weight: 400;
        color: white;
      }
    }

    .partners-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;

      li {
        flex: 1 0 12%;
        margin: 12px;
        max-width: 100px;
        min-height: 100px;
        align-items: center;
        display: flex;
        a {
          opacity: 0.7;
          &:hover {
            opacity: 1;
          }
        }
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }

    @media (max-width: 900px) {
      flex-direction: column;
      justify-content: center;

      .partners {
        width: 100%;
      }
      .advantage {
        width: 100%;
        margin-top: 50px;
      }

      .pass-culture-text {
        font-size: 0.875rem;
      }

      .partners-list {
        justify-content: center;
        padding: 0;
        li {
          flex: 1 0 15%;
          min-height: 80px;
        }
      }
    }
  }
}
