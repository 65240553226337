@import '../theme/colors.scss';
@import '../theme/layout.scss';

.mio-chapter-card {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;

  &.initial {
    background-color: rgba(255, 255, 255, 0);
  }
  &:hover:not(.faded) {
    background-color: rgba(255, 255, 255, 0.05);
  }
  &:active:not(.faded) {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &.faded > * {
    opacity: 0.5;
  }

  & > * {
    padding: $spacing * 3 0 $spacing * 3 $spacing;
  }

  .number-container {
    position: relative;
    min-width: 100px;
    font-size: 33px;
    text-align: center;
    line-height: 49px;
    color: white;
    font-weight: 100;

    .bullet {
      position: absolute;
      left: 10px;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: $primary;
    }
  }

  .title-container {
    flex: 1;
    overflow: hidden;
  }

  .badge-container {
    padding-right: $spacing * 3;
  }

  .access-icon {
    height: 24px;
    min-width: 24px;
    border-radius: 12px;
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: white;
    padding: 0 $spacing;
    &.login {
      background: linear-gradient(74.66deg, #00b1bc 0%, #074dff 100%);
    }
    &.read {
      background: linear-gradient(348.15deg, #92124a 8.67%, #d91d6f 91.33%);
    }
    &.signup {
      background: linear-gradient(180deg, #ebb019 0%, #ea6419 100%);
    }
    &.locked {
      border: none;
      svg {
        width: 20px;
        opacity: 0.7;
      }
    }
  }
}
