@import '../../variables.scss';

.Pill {
  display: flex;
  border-radius: $radius * 2;
  overflow: hidden;

  .background {
    display: flex;
    align-items: center;
    padding: 2px $radius;
  }
  svg {
    width: 15px;
    height: 15px;
    margin-right: $spacing;
  }
  .label {
    font-size: 12px;
    line-height: 18px;
    font-weight: bold;
  }

  @media (min-width: $breakpoint-sm) {
    // big phone and up
  }
  @media (min-width: $breakpoint-md) {
    // tablet and up
  }
  @media (min-width: $breakpoint-lg) {
    // desktop and up
  }
  @media (min-width: $breakpoint-xl) {
    // wide desktop and up
  }
}
