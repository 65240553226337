@import '../../variables.scss';

.image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
  }
  img {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: block;
    animation: 100ms ease-out 0s 1 fadeIn;
  }
  .placeholder-svg {
    display: none;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  // .image {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  // }
  // .placeholder-svg {
  //   background-color: rgba(255, 255, 255, .02);
  //   width: 100%;
  //   height: auto;
  //   max-width: 100%;
  // }
  // &.fix-width {
  //   width: 100%;
  //   height: auto;
  //   .placeholder-svg {
  //     width: 100%;
  //     height: auto;
  //     max-width: 100%;
  //   }
  // }
  // &.fix-height {
  //   width: auto;
  //   height: 100%;
  //   .placeholder-svg {
  //     width: auto;
  //     height: 100%;
  //     max-height: 100vh;
  //   }
  // }
}
