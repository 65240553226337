@import '../theme/colors.scss';
@import '../theme/layout.scss';

.mio-ctaImage {
  color: $primary-white;
  border-radius: $radius;

  * {
    width: 100%;
  }
}
