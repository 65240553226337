@import '../../variables.scss';

.mio-toggle {
  position: relative;
  width: 40px;
  height: 20px;
}

.switch {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $background-lighter;
  border-radius: 34px;
  cursor: pointer;
  transition: 0.3s;
}

.switch:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  transition: 0.3s;
}

input[type='checkbox'] {
  display: none;
}

input[type='checkbox']:checked + .switch {
  background-color: $color-primary;
}

input[type='checkbox']:checked + .switch:before {
  transform: translateX(20px);
}
