@import '../../../variables.scss';

.family-slide {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .family-member {
      cursor: pointer;
      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }
    }
    .empty-family {
      margin-top: 20px;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .text-line {
      width: 100%;
      text-align: start;
    }
    .add-member {
      padding: 15px;
      width: 100%;
      .mio-button {
        margin-top: 30px;
      }
      .mio-input {
        margin-top: 15px;
      }
    }
    .disclaimer-invitation {
      width: 100%;
      margin-bottom: 10px;
    }
    .line {
      display: flex;
      padding: 15px;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid $color-border;
    }
    .clear-line {
      padding: 10px 15px 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .control-wrapper {
        width: 100%;
        display: flex;
        padding-top: 15px;
        flex-direction: column;
        .control-top {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;
        }
      }
      #dropdown-select {
        width: 100%;
      }
    }
    .delete-text {
      margin-top: 15px;
    }
    .delete-email {
      color: $color-text-secondary-light;
    }
    .delete-container {
      width: 100%;
      height: 270px;
      padding: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    }
    .delete-button {
      margin-top: 20px;
      width: 100%;
      padding: 15px;
    }
    .bottom-container {
      margin-top: 20px;
      .mio-button {
        margin-top: 20px;
      }
    }
  }
}
