@import '../../variables.scss';

.affiliation-bloc {
  .content {
    padding: 20px !important;
  }
  .icon-selector {
    cursor: pointer;
  }
  .link svg {
    color: #e11f7b !important;
  }
  .img-thumb {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;

    .img-affiliation {
      height: 165px;
      width: 116px;
      border-radius: 8px;
      margin-left: 40px;
      margin-right: 40px;
    }
    svg {
      background: white;
      color: black;
      border-radius: 90px;
    }
  }
  .bottom {
    user-select: none;
    .row {
      padding: 0px 35px 0px 35px;
      display: flex;
      margin: $spacing * 4 0;
      justify-content: space-between;
      .info:last-child {
        text-align: right;
      }
    }
    .row-subtitles {
      margin-top: 40px;
      margin-bottom: 0px;
    }
    .row-links {
      height: 50px;
      margin-top: 0px;
      margin-bottom: 0px;
      align-items: center;
      border-bottom: solid 1px #ffffff26;
    }

    .row-center {
      height: 50px;
      margin-top: 0px;
      margin-bottom: 0px;
      align-items: center;
      border-top: solid 1px #ffffff26;
      justify-content: center;
    }

    .--subtitle {
      margin: 0;
      font-size: 12px;
      color: $color-text-secondary-light;
    }
    .--text {
      font-size: 16px;
      color: $color-text-primary-light;
    }
    .link {
      color: inherit;
      display: flex;
      text-transform: capitalize;
      align-items: center;
      svg {
        margin-left: $spacing;
        fill: $color-primary;
        width: 20px;
      }
    }
  }
}
