@import '../../../variables.scss';

.chapter-insert {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  scroll-snap-align: center;
  margin: $spacing * 10 0;
  width: 100%;
  .button-close {
    margin-top: 30px;
    .text-close {
      color: $color-text-primary-dark;
      text-decoration: underline;
      &:hover {
        color: $color-text-secondary-dark;
      }
    }
  }
  .button-login {
    margin-top: 30px;
    color: $color-text-primary-dark;
    &:hover {
      text-decoration: underline;
    }
  }

  .disclaimer-info {
    margin-top: 20px;
    svg {
      border: none;
    }
    p {
      color: $color-text-secondary-dark;
      font-weight: 400 !important;
    }
  }
  .similarMangasContainer {
    width: 90%;
    margin-top: 40px;
    .right,
    .left {
      svg {
        color: black;
      }
    }
  }
  .chapter-copyright {
    white-space: pre-wrap;
    text-align: center;
    margin-top: 30px;
    .mio-text.copyright {
      color: #505050;
      font-size: 10px;
      line-height: 14px;
    }
  }
  .chapter-insert-content {
    width: 90%;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ededed;
    padding: $spacing * 3;
    border-radius: $radius;
    h1.--title-s,
    p.--subtitle {
      color: $color-text-primary-dark;
      margin: 0 0 $spacing * 3 0;
    }
    p.--subtitle {
      margin-bottom: $spacing * 5;
      text-align: center;
    }
    .--subtitle {
      opacity: 0.5;
    }

    .direction-ltr .icon {
      transform: rotate(180deg);
    }
    .mode-vertical .icon {
      transform: rotate(0deg);
    }
  }
  @media (min-width: $breakpoint-sm) {
    .chapter-insert-content {
      min-width: 500px;
      width: 30%;
      padding: 25px $spacing * 10;
      margin: 0 $spacing * 10;
    }
    .chapter-copyright {
      .mio-text.copyright {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
  @media (min-width: $breakpoint-lg) {
    .similarMangasContainer {
      margin-top: 100px;
    }
  }
}
