@import '../../variables.scss';

#VoucherPageNew {
  .titleVoucher {
    margin-bottom: 20px;
    text-align: center;
    .highlight {
      background: linear-gradient(88.5deg, #fbff34 0%, #34fbdd 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
    }
  }
  .tag {
    color: white;
    width: fit-content;
    border: 1px solid #fff;
    padding: 2px 5px;
    border-radius: 4px;
    opacity: 0.7;
    margin-right: 10px;
    text-align: center;
    text-transform: uppercase;
    font-size: 11px;
    margin-bottom: 5px;
  }
  .tagWhite {
    background: white;
    border-radius: 12px;
    text-transform: none;
    padding-left: 17px;
    padding-right: 17px;
    opacity: 1;
    font-weight: 600;
    line-height: unset;
  }
  .textLightBlue {
    color: #074dff;
  }
  .textDarkBlue {
    color: #102969;
  }
  .textOrange {
    color: #ea6419;
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    .subtitle {
      text-align: center;
      color: $color-text-secondary-light;
    }
    .logo {
      height: 183px;
      @media (min-width: $breakpoint-sm) {
        height: 339px;
      }
    }
  }

  .activation {
    margin-top: 100px;
    padding: 20px;
    .coffeeImg {
      margin-top: 40px;
    }
    .activationBtn {
      margin-top: 60px;
      width: 200px;
    }
  }

  .price {
    display: flex;
    margin-top: 80px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .priceBloc {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 10px;
      height: 450px;
      width: 327px;
      padding: 30px;
      border-radius: 8px;
      text-align: center;
      .priceText {
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 55px;
        white-space: nowrap;
        font-weight: 700;
      }
      .subText {
        text-transform: uppercase;
        font-weight: 200;
      }
      .btnOffer {
        margin-bottom: 10px;
        white-space: nowrap;
      }
    }
    .threeM {
      background: linear-gradient(
          205.95deg,
          #2c2984 2.78%,
          #36167e 28.07%,
          #511245 98.77%
        ),
        linear-gradient(180deg, #ebb019 0%, #ea6419 100%);
    }
    .sixM {
      background: linear-gradient(74.66deg, #00b1bc 0%, #074dff 100%);
    }
    .oneY {
      background: linear-gradient(180deg, #ebb019 0%, #ea6419 100%),
        linear-gradient(
          205.95deg,
          #2c2984 2.78%,
          #36167e 28.07%,
          #511245 98.77%
        );
    }
    @media (min-width: $breakpoint-lg) {
      flex-direction: row;
    }
  }
  .title-feedback {
    background: linear-gradient(88.5deg, #00e9c6 0%, #8f73df 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
  }
  .process-collector {
    text-align: center;
    margin: 25px;
    @media (min-width: $breakpoint-lg) {
      margin: 80px 0;
    }
    .process-title {
      margin-bottom: 30px;
    }
    .mio-carrousel {
      background: #29252d;
      border-radius: 8px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title {
      margin-bottom: 10px;
      opacity: 0.8;
      font-size: 20px;
    }
    .card {
      margin: 15px;
      width: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.arrow {
        align-self: center;
        align-items: flex-end;
        width: auto;
      }

      .mio-text {
        margin-top: 20px;
      }
    }
  }
}
