// N'importer que les fichiers nécessaires
@import '../theme/colors.scss';
@import '../theme/layout.scss';
@import '../theme/animations.scss';

// Chaque composant a une classe unique préfixée par mio-
.mio-volume-card {
  background-size: cover;
  display: flex;
  overflow: hidden;

  .mask {
    display: flex;
    flex: 1;
    background: #3e3742;
    padding: $spacing * 3 0;
  }

  .toggle {
    display: flex;
    align-items: center;
    padding: 0 $spacing * 4;
    svg,
    svg > * {
      height: 25px;
      width: 25px;
    }
    svg {
      background: rgba(0, 0, 0, 0.4);
      border-radius: 13px;
      color: $primary-white;
      cursor: pointer;
      transition: transform $fast;
      &.rotate90 {
        transform: rotate(90deg);
      }
    }
  }

  .action {
    display: flex;
    align-items: center;
    padding: 0 $spacing * 4;
    svg,
    svg > * {
      height: 25px;
      width: 25px;
    }
    svg {
      color: $primary-white;
      opacity: 0.7;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
  }

  .cover {
    width: 100px;
    height: 144px;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: $radius;
    margin-right: $spacing * 4;
  }
  .smallerCover {
    width: 45px;
    height: 70px;
    border-radius: $radius / 2;
  }

  .subtitle,
  .description {
    color: $secondary-white;
    opacity: 0.7;
  }

  &.small {
    height: 82px;
    .mask {
      padding: 6px 0;
    }
    .content {
      justify-content: center;
    }
  }

  &.large {
    height: 180px;
  }
}
