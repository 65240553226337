@import '../../../variables.scss';

.cancel-subscription-slide {
  .floatingmenu-body {
    padding: $spacing * 2 $spacing * 3;

    .mio-text {
      margin-bottom: $spacing * 2;
    }
    .error {
      color: #c4143d;
    }
    .mio-button {
      margin-bottom: $spacing * 2;
      margin-top: $spacing * 2;
    }
  }
}
