#PricingCardNew {
  border-radius: 8px;
  overflow: hidden;
  // border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1) inset;

  .button {
    display: inline-block;
    cursor: pointer;
    user-select: none;
    font-size: 1rem;
    line-height: 1.5em;
    color: white;
    font-weight: 600;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    height: 3.3125em;
    width: 100%;
    padding: 0 2em;
    align-items: center;
    display: flex;
    justify-content: center;
    transition: all 100ms ease;
    background: rgba(255, 255, 255, 0.05);
    mix-blend-mode: normal;
    backdrop-filter: blur(16px);
  }

  .button:hover {
    text-decoration: none;
    border: 1px solid white;
  }

  .button:not(.disabled):active {
    border: 1px solid white;
    background: rgba(255, 255, 255, 0.15);
  }

  .button.disabled {
    cursor: default;
    opacity: 0.5;
  }

  .card-tag {
    position: absolute;
    top: -0.75em;
    left: 0;
    z-index: 1;
    font-size: 1rem;
    line-height: 1.5em;
    font-weight: 500;
    height: 1.5em;
  }

  .card-header {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 32px;
    top: 0;
    right: 0;
    left: 0;
    span {
      font-weight: 400;
      font-size: 1.375em;
      line-height: 28px;
    }
  }

  .card-content {
    padding: 1.875rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .background {
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .chip {
    font-size: 0.8rem;
    font-weight: 600;
    background-color: white;
    border-radius: 13px;
    padding: 2px 11px;

    &.hidden {
      background-color: transparent;
    }
  }

  .price {
    font-size: 3.4375rem;
    font-weight: 700;
    line-height: 1.5em;
    color: white;
    margin: 0;
    margin-top: 3px;
    white-space: nowrap;
  }

  .price.is-2 {
    font-size: 1.2rem;
    line-height: 1.5em;
  }

  .price small {
    display: block;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5em;
    text-transform: uppercase;
  }

  .card-content .subtitle {
    font-size: 1rem;
    line-height: 1.5em;
    margin-block-start: 0;
    margin-block-end: 0;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 400;
    margin-bottom: 7px;
  }

  .mainCard {
    flex: 1;
    padding-top: 31px;
  }

  .bottomCard {
    width: 100%;
  }

  &.voucher {
    background: linear-gradient(270deg, #9420c6 1.84%, #2088c9 100%);
  }

  &.passCulture {
    background: linear-gradient(91.01deg, #861d72 0.53%, #61167e 99.14%);
    .chip {
      color: #5925a5;
    }
    .background {
      background-image: url(https://cdn.mangas.io/images/pricing-card-pass-min.svg);
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: cover;
    }
    .button {
    }
    .logo {
      margin-top: 16px;
    }
    .subtitle {
      font-size: 0.875rem;
      line-height: 1.7em;
      color: white;
    }
  }

  &.free {
    background: linear-gradient(180deg, #2d2930 0%, #1a151d 100%);
    .chip {
    }
    .background {
    }
    .button {
    }
  }

  &.monthly {
    background: linear-gradient(180deg, #dd7007 0%, #c13d00 100%);
    .chip {
    }
    .background {
      background-image: url(https://cdn.mangas.io/images/pricing-card-btth-hero-2-min.png);
    }
    .button {
    }
  }
  &.promotion {
    background: linear-gradient(
      175.8deg,
      #aa1a88 0%,
      #e75a03 55.78%,
      #e0a306 100%
    );
    .chip {
      background: #5925a5;
    }
  }

  &.yearly {
    background: linear-gradient(180deg, #00adf7 0%, #074dff 100%);
    .chip {
      color: #154ee2;
    }
    .background {
      background-image: url(https://cdn.mangas.io/images/pricing-card-btth-hero-1-min.png);
    }
    .button {
    }
  }

  @media (max-width: 1200px) {
  }
}
