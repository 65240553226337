@import '../../../variables.scss';

$menuHeight: 50px;

#ReaderMenu {
  color: $color-text-secondary-dark;
  z-index: 1;
  width: 100%;
  top: 0;
  height: 0px;
  margin-bottom: 2px;
  position: fixed;
  background: #fff;
  .--title-s,
  .--text {
    color: $color-text-secondary-dark;
  }
  .--text {
    font-size: 14px;
  }
  .icon {
    //fill: $color-text-secondary-dark;
    color: white;
  }
  .Pill.hd {
    position: absolute;
    top: 0;
    right: -10px;
    height: 14px;
    border-radius: 8px;

    .background {
      padding: 0px 5px;
    }

    .label {
      color: white;
      font-size: 10px;
    }
  }
  .select {
    height: 35px;
    background: #2b252f;
    border-radius: 8px;
    color: white;

    .custom__control {
      cursor: pointer;
    }
    .custom__control,
    .custom__option {
      font-size: 14px;
      line-height: 1.5em;
    }
    .custom__input {
      color: $color-text-primary-dark;
    }
    .custom__control,
    .custom__control--is-focused {
      border: none;
      box-shadow: none;
    }

    .custom__menu,
    .custom__control {
      background: #ececec;
      svg {
        fill: $color-text-secondary-dark;
      }
    }

    .custom__single-value {
      color: $color-text-primary-dark;
    }

    .custom__option {
      text-align: left;
      color: $color-text-primary-dark;
      line-height: 20px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;
      &:hover,
      &.custom__option--is-focused,
      &.custom__option--is-selected {
        background: rgba(0, 0, 0, 0.1);
      }
      & a {
        color: $color-text-primary-dark;
        display: block;
        padding: 8px 12px;
        width: 100%;
      }
    }
  }

  .dark-mode & {
    background: $background-dark;
    color: white;
    .--title-s,
    .--text {
      color: white;
    }
    .icon {
      fill: white;
    }
    .select {
      .custom__input {
        color: white;
      }
      .custom__menu,
      .custom__control {
        background: #2b252f;
        svg {
          fill: #fff;
        }
      }
      .custom__single-value {
        color: rgba(255, 255, 255, 0.9);
      }
      .custom__option {
        color: rgba(255, 255, 255, 0.9);
        &:hover,
        &.custom__option--is-focused,
        &.custom__option--is-selected {
          background: rgba(255, 255, 255, 0.1);
        }
        & a {
          color: rgba(255, 255, 255, 0.9);
        }
      }
    }
  }

  @media (min-width: $breakpoint-sm) {
    // big phone and up
  }
  @media (min-width: $breakpoint-md) {
    // tablet and up
  }
  @media (min-width: $breakpoint-lg) {
    position: initial;
    height: 50px;
    // desktop and up
  }
  @media (min-width: $breakpoint-xl) {
    // wide desktop and up
  }
}
