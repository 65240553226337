@import '../../variables.scss';

.TagList {
  padding: 0;
  list-style: none;
  font-size: 13px;
  color: $color-text-primary-dark;
  flex-wrap: wrap;
  display: flex;
  &.small {
    font-size: 8px;
    li {
      line-height: unset;
    }
  }
  li:not(:last-child)::after {
    content: '•';
    color: $color-primary;
    margin: 0 5px;
  }
  li {
    display: inline;
    line-height: 24px;
    text-transform: capitalize;
  }
  transform-origin: top left;

  .dark-mode & {
    // dark mode css here
    color: $color-text-primary-light;
  }

  @media (min-width: $breakpoint-sm) {
    // big phone and up
  }
  @media (min-width: $breakpoint-md) {
    // tablet and up
    font-size: 16px;
  }
  @media (min-width: $breakpoint-lg) {
    // desktop and up
  }
  @media (min-width: $breakpoint-xl) {
    // wide desktop and up
  }
}
