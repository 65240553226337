@import '../../../variables.scss';

#UniversalReader {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: row;

  .placeholder-svg {
    display: none;
  }

  .zoom-wrapper {
    position: relative;
    display: flex;
    flex-shrink: 0;
  }
  .pair-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
    @media (orientation: landscape) {
      align-items: flex-start;
    }
  }
  .page-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (orientation: landscape) {
      align-items: flex-start;
    }
    height: calc(100vh - 55px);
  }
  .snap-point {
    scroll-snap-align: center;
  }
  .spacer-right {
    flex-shrink: 0;
    height: 100%;
  }

  .reload-button {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 200px;
  }

  &.reader-page,
  &.reader-page_double,
  &.reader-horizontal {
    scroll-snap-type: x mandatory;
    display: flex;
    flex-direction: row;
    // background-color: pink;
    flex: 1;
    .zoom-wrapper {
      margin-left: 50px;
    }
  }
  &.reader-vertical,
  &.reader-webtoon {
    flex-direction: column;
    align-items: center;
    .zoom-wrapper {
      margin-bottom: 50px;

      &.contains-cover {
        display: block;
        @media (min-width: $breakpoint-lg) {
          display: flex;
        }
      }
      .pair-wrapper {
        .page-wrapper {
          height: 100%;
          width: 100%;
          min-height: calc(100vh - 55px);
        }
      }
    }
  }

  &.reader-webtoon {
    .zoom-wrapper {
      margin-bottom: 0;
    }
  }

  &.reader-page {
    .page-wrapper {
      width: 100vw;
      @media (min-width: $breakpoint-lg) {
        width: auto;
      }
      @media (min-width: $breakpoint-md) {
        align-items: flex-start;
      }
    }
    .pair-wrapper {
      min-width: 100vw;
    }
  }
  &.reader-page_double {
    .pair-wrapper {
      min-width: 100vw;
    }
    .page-wrapper {
      // background-color: fuchsia;
      &.pageLeft {
        justify-content: flex-end;
        // width: 50vw;
        flex: 1;
      }
      &.pageRight {
        justify-content: flex-start;
        // width: 50vw;
        flex: 1;
      }
    }
  }

  &.reader-horizontal {
  }
  &.reader-vertical {
  }
  &.reader-webtoon {
  }
}

// #UniversalReader {
//   flex: 1;
//   overflow: scroll;
//   .spacer-right {
//     flex-shrink: 0;
//     height: 100%;
//   }
//   .pair-wrapper {
//     position: relative;
//     display: flex;
//     align-items: center;
//     transform-origin: top left;
//     scroll-snap-align: center;
//     @media (orientation: landscape) {
//       align-items: flex-start;
//     }
//   }
//   .zoom-wrapper {
//     position: relative;
//     display: flex;
//     align-items: center;
//     // transform-origin: top;
//     scroll-snap-align: center;
//     @media (orientation: landscape) {
//       align-items: flex-start;
//     }
//     // width: 100%;
//   }
//   .pair {
//     // scroll-snap-align: center;
//     display: flex;
//   }
//   .chapter-cover {
//     scroll-snap-align: center;
//   }
//   .page-wrapper,
//   .chapter-cover {
//     position: relative;
//     flex-shrink: 0;
//     display: flex;
//     justify-content: center;
//     // align-items: center;
//     height: 100%;
//     @media (min-width: $breakpoint-lg) {
//       height: calc(100vh - 55px);
//     }
//   }
//   &.reader-page {
//     scroll-snap-type: x mandatory;
//     display: flex;
//     flex-direction: row;
//     height: 100vh;
//     .chapter-cover {
//       width: 100vw;
//     }
//     .page-wrapper {
//       // scroll-snap-align: center;
//       width: 100vw;
//       @media (orientation: landscape) {
//         align-items: flex-start;
//       }
//     }
//   }
//   &.reader-vertical,
//   &.reader-webtoon {
//     .pair-wrapper {
//       display: block;
//     }
//     // flex: initial;
//     #chapter-next-divider {
//       svg {
//         transform: rotate(-90deg);
//       }
//     }
//     #chapter-previous-divider {
//       svg {
//         transform: rotate(-90deg);
//       }
//     }
//   }
//   &.reader-vertical {
//     .page-wrapper {
//       margin: $spacing * 2 0;
//     }
//   }
//   &.reader-page_double {
//     scroll-snap-type: x mandatory;
//     display: flex;
//     flex-direction: row;
//     .pair {
//       width: 100vw;
//     }
//     .chapter-cover {
//       width: 100vw;
//     }
//     .page-wrapper {
//       // scroll-snap-align: center;
//       width: 100vw;
//       &.pageLeft {
//         scroll-snap-align: none;
//         display: flex;
//         justify-content: flex-end;
//         width: 50vw;
//         // height: auto;
//       }
//       &.pageRight {
//         scroll-snap-align: none;
//         display: flex;
//         justify-content: flex-start;
//         width: 50vw;
//         // height: auto;
//       }
//     }
//   }
//   &.reader-horizontal {
//     scroll-snap-type: x mandatory;
//     display: flex;
//     flex-direction: row;
//     .pair {
//       // scroll-snap-align: none;
//     }
//     .page-wrapper {
//       // scroll-snap-align: center;
//       &.marginLeft {
//         margin-left: 50px;
//       }
//     }
//   }
//   &.reader-webtoon {
//   }
// }
