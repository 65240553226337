@import '../../variables.scss';

#MangaSearch {
  .dropdown {
    margin-top: 20px;
    margin-right: 20px;
    position: relative;
    display: flex;
    z-index: 3;
    justify-content: flex-end;
    align-items: center;
    .dropdown-label {
      margin-right: 10px;
    }
    #dropdown-select {
      width: 230px;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    list-style: none;
    padding-inline-start: 0px;

    li {
      margin: 15px 0;
    }
  }
  @media (min-width: $breakpoint-sm) {
    ul {
      justify-content: center;
      li {
        margin: 10px;
      }
    }
  }
}
