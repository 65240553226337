@import "../theme/colors.scss";
@import "../theme/layout.scss";

.mio-avatar {
  height: 42px;
  width: 42px;
  position: relative;
  border: 4px solid transparent;
  border-radius: 16px;
  img{
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: $black-background;
  }

  &.notification::before{
    
    position: absolute;
    width: 10px;
    height: 10px;
    top: -3px;
    right: 2px;
    background: $primary; 
    padding: 2px;
    border: 2px solid $dark-background;
    content: "";
    z-index: 100;
    border-radius: 50%;
    
  }

  &.premium::after{
    position: absolute;
    top: -2px; bottom: -2px;
    left: -2px; right: -2px;
    background: $blue-gradient;
    content: '';
    z-index: -1;
    border-radius: 50%;
  }
  
}
