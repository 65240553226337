@import '../../variables.scss';

.BlogHighlights {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  @media (min-width: $breakpoint-lg) {
    flex-direction: row;
  }
  width: 100%;

  .main-container {
    flex: 1;
  }

  .main-highlight {
    display: flex;
    flex-direction: column;
    @media (min-width: $breakpoint-xl) {
      flex-direction: row;
    }
    padding: $spacing * 3 0;

    .featured_image {
      flex: 1;
      position: relative;
      padding-top: 69%;
      background-size: cover;
      background-position: center center;
      overflow: hidden;
      margin-bottom: $spacing * 5;

      @media (min-width: $breakpoint-xl) {
        padding-left: $spacing * 4;
        padding-top: 39%;
      }

      .featured_image_bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        transition: all 0.7s cubic-bezier(0.2, 1, 0.22, 1);
      }
    }
    &:hover .featured_image_bg {
      transform: scale(1.07);
    }

    .texts {
      flex: 1;
      .mio-text {
        margin-top: $spacing * 4;
        padding: 0 $spacing;
      }
      .faded {
        opacity: 0.7;
      }
      @media (min-width: $breakpoint-xl) {
        // flex: 1;
        padding-left: $spacing * 4;
      }
    }
  }

  .aside-container {
    width: 400px;
    padding-left: $spacing * 4;

    .mio-articlecard-imgBlock div {
      background-position: center center;
    }
  }

  .withHorizontalBar {
    overflow: hidden;
    &:after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      margin-right: -100%;
      margin-left: 24px;
      vertical-align: middle;
      opacity: 0.3;
      border-bottom: 1px solid #fff;
    }
  }
}
