@import '../../variables.scss';

.titleHeader {
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  .subtitleText {
    color: $color-text-secondary-light;
  }
}
