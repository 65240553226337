@import '../../variables.scss';

.MangaInfo {
  .content {
    white-space: pre-wrap;
  }
  #tag-secondary {
    outline: 1px solid rgba(255, 255, 255, 0.1);
    outline-offset: -1px;
    background-color: transparent;
    cursor: pointer;
  }
}
