@import '../../variables.scss';
#errorPage {
  background-color: $background-dark;
  .errorFlex {
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    .cookie404 {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .sm {
      margin-bottom: 30px;
    }
  }
  svg {
    fill: white;
  }
  #ErrorContainer {
    min-height: calc(100vh - #{$navbar-height});
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 800px;
    width: 100%;
    padding: 30px 15px;
    #contactLink {
      text-decoration: underline;
    }

    .marginBottom {
      margin-bottom: 15px;
    }

    .actions {
      display: flex;
      flex-direction: row;
      width: 250px;
      justify-content: space-between;
      margin: 30px 0;
    }
  }
}
