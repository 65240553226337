@import '../../variables.scss';

.volume-toggle {
  position: relative;
  .admin-area {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}
