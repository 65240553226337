@import '../theme/colors.scss';
@import '../theme/layout.scss';

.mio-indicatorPill {
  color: $primary-white;
  border: 1px solid $hover-highlight;
  border-radius: 90px;
  height: 26px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  min-width: 26px;
  line-height: 24px;
  align-items: center;
}
