/*
** RESET 
*/
@import url('https://fonts.googleapis.com/css?family=Poppins:100,400,500,600,700&display=swap');

*,
*:before,
*:after {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

a {
  text-decoration: none;
}

/*
** HELPERS 
*/

.uppercase {
  text-transform: uppercase;
}

.textEllipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-center {
  text-align: center;
}

.clickable {
  cursor: pointer;
  user-select: none;
}

.no-wrap {
  white-space: nowrap;
}

.spin {
  animation: spin 2s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
