@import '../../../variables.scss';

#LandingSupportCoffee {
  max-width: 1440px;
  width: 100%;
  margin-top: 100px;
  .buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  #cta_access {
    .label {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .big-card {
    background: linear-gradient(90deg, #273483 0%, #800c57 100%);
    border-radius: 40px;
    overflow: hidden;
    margin: 48px 24px;
    padding: 0 80px;

    .big-card-content {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
      max-width: 1140px;
      padding: 85px 0 70px;
      min-height: 532px;
      margin: 0 auto;
      font-size: 1.125rem;
      line-height: 1.5em;
      color: white;

      p {
        color: white;
      }

      .title {
        font-size: 2.25rem;
        line-height: 1.2em;
        font-weight: 700;
        margin-bottom: 35px;
      }
    }

    .content {
      width: 48.5%;
      text-align: left;
    }

    .big-card-cta {
      .cta {
        display: inline-block;
      }
    }

    .illu {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 30%;

      img {
        object-fit: contain;
      }
    }

    .illu-mobile {
      display: none;
      img {
        object-fit: contain;
      }
    }

    .AppStore-buttons {
      display: flex;
      margin-top: $spacing * 3;
      margin-bottom: $spacing * 2;

      .storeButtonLink:not(:last-child) {
        margin-right: $spacing * 2;
      }
    }

    @media (max-width: $breakpoint-lg) {
      margin-top: 50px;
      padding: 0 20px;
      background: linear-gradient(180deg, #273483 0%, #800c57 100%);

      .buttons-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .illu {
        display: none;
      }
      .illu-mobile {
        display: block;
        margin-bottom: 30px;
      }
      &.reverse .big-card-content {
        flex-direction: column;
      }
      .big-card-content {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 45px 0 30px;

        p {
          font-size: 1rem;
        }

        .title {
          font-size: 1.625rem;
        }
      }
      .content {
        width: 100%;
      }

      .big-card-cta {
        text-align: center;
      }

      .AppStore-buttons {
        margin-top: 0;
        justify-content: center;
      }
    }
  }
}
