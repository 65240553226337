@import '../../variables.scss';

.mio-form-footer {
  margin-top: 13px;

  .mio-text {
    margin-top: 10px;
    opacity: 0.7;
  }

  .text-link {
    color: white;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: $breakpoint-sm) {
    text-align: center;

    a {
      display: block;
    }

    .mio-text {
      margin-top: 30px;
    }
  }
}
