@import '../../variables.scss';

#AuthorPage {
  margin-top: 15px;
  padding: 0;
  overflow: hidden;
  #back-icon {
    fill: white;
  }

  .author-header {
    width: 100%;
    margin-bottom: 35px;
    padding-left: 80px;
    @media (max-width: $breakpoint-sm) {
      padding: 0;
    }
  }
  .titleContainer {
    margin-bottom: 60px;
    .author-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 25px;
      .img-author {
        object-fit: cover;
        border-radius: 50%;
        margin-right: 12px;
      }
    }
    .authorDescription {
      white-space: pre-wrap;
      max-width: 800px;
    }
  }

  .title-availability {
    display: flex;
    flex-direction: row;
    @media (max-width: $breakpoint-sm) {
      margin-left: 30px;
    }
  }

  #AuthorPage-container {
    @media (min-width: $breakpoint-sm) {
      padding: 0 80px;
    }

    @media (max-width: $breakpoint-sm) {
      padding: 15px;
    }
    .mio-button.large {
      padding: 0;
    }
  }
}
