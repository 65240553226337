@import '../../variables.scss';

.alert {
  backdrop-filter: blur(26px);
  border-radius: 8px;
  display: flex;
  .content {
    padding: $spacing * 4 $spacing * 4 $spacing * 4 0;
  }
  .side {
    padding: $spacing * 4;
  }
  svg {
    width: 20px;
  }
  &.alert-secondary {
    background-color: rgba(255, 183, 42, 0.15);
    svg {
      fill: #ffb72b;
    }
  }
}
