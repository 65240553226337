@import '../../../variables.scss';

.reader-settings {
  display: flex;
  flex-direction: column;
  background: #151018;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    svg {
      width: 25px;
      height: 25px;
      color: rgba(255, 255, 255, 0.6);
    }
  }
  .mask {
    flex: 1;
  }
  .reader-settings-container {
    padding: $spacing * 3;
  }
  #crown-icon {
    color: #ebb019;
    z-index: 3;
  }
  .animate-container {
    padding: $spacing * 2 0;
    margin-top: 10px;
    .checkbox-reduced-motion {
      margin-top: 15px;
      margin-bottom: 30px;
      .mio-check-input {
        width: 25px;
        height: 25px;
      }
    }
  }
  .reader-modes {
    padding: $spacing * 2;
    display: flex;
    flex-wrap: wrap;
    .mode-card {
      width: 131px;
      height: 131px;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      margin: 0 $spacing * 4 $spacing * 4 0;
      background: rgba(255, 255, 255, 0.05);
      border-radius: $radius;
      padding-bottom: 10px;
      &.active {
        border: 1px solid #ffffff;
      }
      &.faded {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }
  .quality-modes {
    .mode-row {
      display: flex;
      align-items: center;
      padding: $spacing * 2 0;
      border-bottom: 1px solid rgba(243, 243, 243, 0.1);
      .content {
        flex: 1;
        .faded {
          opacity: 0.7;
        }
      }
      .icon {
        width: 30px;
        svg {
          width: 30px;
          height: 30px;
          color: #21bd4d;
        }
      }
    }
  }
}
