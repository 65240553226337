.contenuslies-container {
  .mio-articlecard {
    display: flex;
    align-items: center;
    .mio-articlecard-title {
      padding-top: 0;
    }
    .mio-articlecard-imgBlock div {
      width: 80px;
      height: 80px;
      border-radius: 8px;
      background-position: center;
    }
  }
}
