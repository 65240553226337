@import '../../../variables.scss';

#LandingBanner {
  z-index: 3;
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 30px;
  justify-content: space-between;
  background: $gradient-gold-pink;

  @media (max-width: $breakpoint-md) {
    flex-direction: column;
    button {
      margin-top: 20px;
    }
  }
}
