@import '../theme/colors.scss';
@import '../theme/layout.scss';

.mio-inlineCard {
  position: relative;
  display: block;
  height: 149px;
  width: 100%;
  padding: 15px;
  border-radius: $radius;

  &:hover,
  &:focus {
    background-color: rgba(255, 255, 255, 0.05);
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .imgBlock {
    float: left;
    width: 82px;
    height: 118px;
    background-color: #5c595e;
    border-radius: $radius;

    & div {
      border-radius: $radius;
      width: 100%;
      height: 100%;
      background-size: cover;
    }
  }

  .textBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-left: 20px;
    line-height: 22px;
    .title {
      color: $primary-white;
      font-size: 15px;
      margin-bottom: 5px;
    }
    .subtitle {
      margin-bottom: 5px;
      color: $secondary-white;
      font-size: 13px;
    }
  }
  .cornerTag {
    position: absolute;
    left: 0;
    bottom: 0;
    .mio-cornertag {
      display: inline-block;
    }
  }
}
