@import '../theme/colors.scss';
@import '../theme/layout.scss';

.mio-radio-button {
  cursor: pointer;
  width: calc(50% - 13px);
  margin-top: 25px;
  min-height: 165px;

  background: rgba(56, 47, 61, 0.65);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 6%;

  .image {
    color: white;
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
    flex-shrink: 0;
  }

  &:hover {
    border: 1px solid #ffffff;
  }

  @media (max-width: 560px) {
    width: calc(50% - 7px);
    margin-top: 14px;
  }
}
