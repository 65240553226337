@import '../../../variables.scss';

.CompactMenu {
  display: flex;

  &.open {
    .toggle-button {
      transform: rotate(180deg);
    }
    .reader-menu-content {
      transform: translateY(0);
    }
  }

  .close-icon {
    position: absolute;
    color: white;
    left: 15px;
    top: 25px;
    width: 30px;
    height: 30px;
  }

  .toggle-button {
    position: absolute;
    right: 15px;
    top: 25px;
    padding: 7px;
    background-color: rgba(0, 0, 0, 0.6);
    width: 35px;
    height: 35px;
    border-radius: 50%;
    transition: transform 0.5s ease;
  }
  .reader-menu-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    transform: translateY(-100%);
    transition: all 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .chapterInfo {
      padding: $spacing * 3;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #151018;
    }
    .select {
      align-self: stretch;
    }
    .controls {
      padding-top: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      .page {
        margin: 0 $spacing * 2;
      }
    }
    .reader-settings {
      flex: 1;
      background: none;
      .floatingmenu-header {
        background: #151018;
      }
      .floatingmenu-body {
        background: #151018;
      }
    }
    .back {
      align-self: center;
      margin: $spacing * 2 0;
    }
  }
}
