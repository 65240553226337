// N'importer que les fichiers nécessaires
@import '../theme/colors.scss';
@import '../theme/layout.scss';
@import '../theme/animations.scss';

.mio-manga-tile {
  position: relative;

  .image-container {
    background: $loading-gradient;
    border-radius: $radius;
    margin-bottom: $spacing;
    overflow: hidden;
    display: flex;
    position: relative;
    z-index: 2;
  }

  // Le background est positionné dans une div en absolute pour permettre
  // de gérer l'effet de zoom au hover tout en gardant une size:cover
  .image {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform $medium ease;
    background-size: cover;
    background-position: center;
    z-index: 1;
  }

  .hover-effects {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
    border: 1px solid white;
    border-radius: inherit;
    padding: $spacing-2;
    opacity: 0;
    transition: opacity $fast;
    z-index: 2;
  }
  &:hover .hover-effects {
    opacity: 1;
  }

  // &:hover .image {
  //   transform: scale($zoom-scale-on-hover);
  // }

  &:active .hover-effects {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .subtitle {
    color: $secondary-white;
  }

  .mio-pill.has-new-chapter {
    margin-top: $spacing;
    position: absolute; // prevent word wrap. There may be a better solution
  }

  &.small {
    width: 140px;
    .image-container {
      height: 202px;
    }
  }

  &.medium {
    width: 180px;
    .image-container {
      height: 260px;
    }
  }

  &.large {
    width: 232px;
    .image-container {
      height: 336px;
    }
  }

  //holo

  .holo {
    --color1: #ec9bb6;
    --color2: #ccac6f;
    --color3: #69e4a5;
    --color4: #8ec5d6;
    --color5: #b98cce;
  }

  .holo:before,
  .holo:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-repeat: repeat;
    opacity: 0.5;
    mix-blend-mode: color-dodge;
    transition: all 0.33s infinite;
  }

  .holo:before {
    background-position: 50% 50%;
    background-size: 300% 300%;
    background-image: linear-gradient(
      115deg,
      transparent 0%,
      var(--color1) 25%,
      transparent 47%,
      transparent 53%,
      var(--color2) 75%,
      transparent 100%
    );
    opacity: 0.5;
    filter: brightness(0.5) contrast(1);
    z-index: 1;
  }

  .holo {
    transition: none;
    &:before {
      transition: none;
      animation: holoGradient 8s infinite;
    }
    &:after {
      transition: none;
    }
  }

  @keyframes holoGradient {
    0%,
    100% {
      opacity: 0.5;
      background-position: 50% 50%;
      filter: brightness(0.5) contrast(1);
    }
    5%,
    9% {
      background-position: 0% 50%;
      opacity: 0.5;
      filter: brightness(0.75) contrast(1);
    }
    13%,
    17% {
      background-position: 0% 0%;
      opacity: 0.88;
    }
    35%,
    39% {
      background-position: 100% 100%;
      opacity: 1;
      filter: brightness(0.5) contrast(1);
    }
    55% {
      background-position: 0% 0%;
      opacity: 1;
      filter: brightness(0.75) contrast(1);
    }
  }

  //video

  .video-container {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
