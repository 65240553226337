@import '../../variables.scss';

.ContinueReadingBlock {
  .mio-inlineCard {
    border-radius: 0;
    .corner-buttons {
      position: absolute;
      top: 0;
      visibility: hidden;
      margin: 5px;
      display: flex;
      right: 0;
      .corner-button {
        color: $background-light;
        opacity: 0.7;
        // background-color: rgba(255, 255, 255, 0.1);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        margin-left: 5px;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
      }
    }
    &:hover {
      .corner-buttons {
        visibility: visible;
      }
    }
    @media (max-width: $breakpoint-lg) {
      .corner-buttons {
        visibility: visible;
      }
    }
  }

  .inline-progress {
    display: flex;
    align-items: center;
    .subtitle {
      color: rgba(255, 255, 255, 0.7);
      font-size: 13px;
      margin-left: 10px;
    }
  }
  .newChapterSubtitle {
    display: flex;
    align-items: center;
    .newChapterPill {
      background-color: $color-primary;
      margin-right: 10px;
    }
  }
}

.notClickable {
  opacity: 0.4;
}
