.Mask {
  display: block;
  background: rgba(0, 0, 0, 0.01);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 900;
  & > * {
    // z-index: 1000;
  }
  &.withOverlay {
    background: rgba(0, 0, 0, 0.5);
  }
  &.absolute {
    position: absolute;
  }
}
