@import '../theme/colors.scss';
@import '../theme/layout.scss';

.mio-button {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border: none;
  white-space: nowrap;
  box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 0.15);
  border-radius: $radius;
  box-sizing: border-box;
  transition: box-shadow 0.2s ease;

  svg,
  svg > * {
    height: 20px;
    width: 20px;
  }
  svg {
    margin-right: $spacing-3;
    color: $primary-white;
  }

  .label {
    font-size: 14px;
    line-height: 1.5em;
    font-weight: 500;
    padding: 0;
    color: $primary-white;
    p {
      box-shadow: black 0px 0px 0px 0.5px;
    }
  }

  &.small.withIcon {
    position: relative;
    svg {
      margin: $spacing 0;
    }
    span {
      position: absolute;
      top: 100%;
      padding-top: $spacing;
      font-size: 10px;
      text-transform: uppercase;
    }
  }
  &:not(.disabled) {
    cursor: pointer;
  }

  &:hover:not(.disabled) {
    box-shadow: inset 0px 0px 0px 1px $primary-white;
  }

  &.primary {
    background: $primary-gradient;

    &:active:not(.disabled) {
      background: $hover-gradient, $primary-gradient;
    }
  }

  &.light {
    background: $primary-white;
    .label {
      color: black;
    }
  }

  &.dark {
    background: black;
  }

  &.gold {
    background: rgb(234, 100, 25);
    background: linear-gradient(
      90deg,
      #f06e0b 0%,
      #f4af01 9%,
      #f47f0e 33%,
      #f47f0e 74%,
      #fee639 100%
    );
    .label {
      text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
    }
  }

  &.secondary {
    background: $secondary-button-background;
    backdrop-filter: blur(16px);

    &:active:not(.disabled) {
      background: $hover-gradient, $secondary-button-background;
    }
  }

  &.text {
    background: transparent;
    box-shadow: none;

    &:hover:not(.disabled) {
      box-shadow: none;
    }
  }

  &.small {
    padding: $spacing $spacing-3;
    span {
      font-size: 13px;
    }
  }

  &.medium {
    padding: $spacing-2 $spacing-3;
    span {
      font-size: 14px;
    }
  }

  &.large {
    padding: $spacing-3 $spacing-5;
    span {
      font-size: 16px;
    }
  }

  &.disabled {
    opacity: 0.5;
  }

  &.fullWidth {
    width: 100%;
  }
}
.spaceBetween {
  justify-content: space-between;
}
