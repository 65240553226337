.carrousel-publisher {
  .publisher-bloc {
    // background: #262128;
    // border: 1px solid rgba(255, 255, 255, 0.15);
    width: 188px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    transition: all 0.2s ease;
    justify-content: center;

    &:hover {
      border-color: #ffffff;
    }
  }
  .publisher-img {
    width: 110px;
  }
}
