@import '../../variables.scss';

#mio-signup {
  width: 100%;

  .mio-text.center {
    text-align: center;
  }

  .text-link {
    margin-top: 10px;
    color: white;
    text-decoration: underline;
    cursor: pointer;
  }
  .mio-disclaimer {
    margin-top: 30px;
  }

  .mio-button {
    margin-top: 30px;
  }

  .phoneContainer {
    opacity: 0.7;
    width: 100%;
    margin-top: 30px;
    .titlePhoneNum {
      color: rgba(255, 255, 255, 0.7);
      text-align: start;
    }
    &:hover {
      opacity: 1;
    }
    &.active {
      opacity: 1;
    }
    .phoneInput {
      .selected-flag {
        background-color: transparent;
        .arrow {
          border-top-color: rgba(255, 255, 255, 0.7) !important;
          border-bottom-color: rgba(255, 255, 255, 0.7) !important;
        }
      }
      input {
        width: 100%;
        background-color: transparent;
        color: rgba(255, 255, 255, 0.7);
      }
      .search {
        display: flex;
        align-items: center;
        background-color: $background-dark;
        padding: 10px 0 6px 0;
      }

      .country {
        background-color: transparent;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  @media (min-width: $breakpoint-sm) {
    .mio-button {
      margin-top: 50px;
    }
  }
}
