@import '../../../variables.scss';

.manga-affiliation {
  width: 100%;
  max-width: 570px;
  margin: 15px auto;
  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -$spacing * 2;
  }

  .volume-wrapper {
    width: 33.33%;
    padding-right: $spacing * 2;
    margin: 0;
    position: relative;
    .image-wrapper {
      background-size: cover;
      background-position: center center;
      width: 100%;
      padding-top: 143%;
      height: auto;
    }
  }
  .volume-title {
    font-size: 0.75rem;
    line-height: 1.5em;
    margin: $spacing 0 $spacing * 3 0;
  }
  .--title-s {
    text-transform: uppercase;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: $spacing * 6;
    svg {
      fill: $color-text-primary-dark;
      width: 26px;
      height: 26px;
      margin-right: $spacing * 3;
    }
    .dark-mode & {
      svg {
        fill: $color-text-primary-light;
      }
    }
  }
  .modal {
    position: fixed;
    height: calc(100vh - #{$spacing} * 4);
    top: $spacing * 2;
    left: $spacing * 2;
    right: $spacing * 2;
    border: 1px solid $background-dark;
    background: $background-light;
    img {
      height: 200px;
    }
    .dark-mode & {
      border: 1px solid $background-light;
      background: $background-dark;
    }
    .close {
      fill: $color-primary;
      .dark-mode & {
        fill: $color-text-primary-light;
      }
      width: 30px;
      position: absolute;
      top: $spacing;
      right: $spacing;
    }

    .top {
      margin-top: $spacing * 6;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      svg {
        fill: $color-primary;
        width: 50px;
        &.disabled {
          opacity: 0.4;
        }
      }
      .dark-mode & {
        svg {
          fill: $color-text-primary-light;
        }
      }
    }

    .bottom {
      .row {
        display: flex;
        margin: $spacing * 4 0;
        padding: 0 $spacing * 10;
        justify-content: space-between;
        .info:last-child {
          text-align: right;
        }
      }
      .info {
      }
      .--subtitle {
        margin: 0;
        font-size: 12px;
        color: $color-text-secondary-dark;
      }
      .--text {
        font-size: 16px;
        color: $color-text-primary-dark;
      }
      .link {
        color: inherit;
        display: flex;
        align-items: center;
        svg {
          margin-left: $spacing;
          fill: $color-primary;
          width: 20px;
        }
      }

      .dark-mode & {
        .--subtitle {
          color: $color-text-secondary-light;
        }
        .--text {
          font-size: 16px;
          color: $color-text-primary-light;
        }
      }
    }
  }
  @media (min-width: $breakpoint-sm) {
    // big phone and up
    .volume-wrapper {
      width: 25%;
    }
  }
  @media (min-width: $breakpoint-md) {
    // tablet and up
    .modal {
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      max-height: 560px;
      max-width: 440px;
      transform: translate(-50%, -50%);
    }
  }
  @media (min-width: $breakpoint-lg) {
    .--title-s {
      font-size: 18px;
    }
    .volume-wrapper {
      width: 20%;
    }
    // desktop and up
  }
  @media (min-width: $breakpoint-xl) {
    // wide desktop and up
  }
}
