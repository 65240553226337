@import '../../../variables.scss';

#LandingHero {
  margin-top: -60px;
  min-height: 664px;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding-top: 60px;
  position: relative;
  z-index: 0;
  overflow: hidden;

  .ribbon {
    z-index: 40;
    width: 200px;
    height: 250px;
    position: absolute;
    top: 40px;
    right: -10px;
  }

  .ribbon-content {
    right: -70px;
    top: 50px;
    transform: rotate(45deg);
    position: absolute;
    display: block;
    width: 400px;
    padding: 10px 0;
    background: linear-gradient(
      90deg,
      #f06e0b 0%,
      #f4af01 9%,
      #f47f0e 33%,
      #f47f0e 74%,
      #fee639 100%
    );
    color: $color-text-primary-light;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    border: 2px solid rgb(234, 171, 25);
  }

  .hero-bg {
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    user-select: none;
    background-image: url('https://cdn.mangas.io/landing/BG-min.png');
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .hero-bg-full {
    display: block;
    position: absolute;
    width: 100%;
    // top: 0;
    // left: 0;
    height: 100%;
    user-select: none;
    background-image: url('https://res-2.cloudinary.com/mangasio/image/fetch/c_limit,w_1800/f_auto/https://cdn.mangas.io/landing/bg_landing_qvema_enhanced.png');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .hero-bg-partner {
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    user-select: none;
    background-image: url('https://cdn.mangas.io/images/BG-partner.png');
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .hero-image-mobile,
  .hero-image-mobile-partner {
    display: none;
  }

  .hero-wrapper::before {
    opacity: 0;
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: -5px;
    user-select: none;
    z-index: 0;
    background: linear-gradient(
        180deg,
        rgba(21, 16, 24, 0) 0%,
        rgba(21, 16, 24, 0) 14.06%,
        rgba(21, 16, 24, 0) 41.67%,
        rgba(21, 16, 24, 0.3) 80.73%,
        rgba(21, 16, 24, 0.58) 90.63%,
        #151018 100%
      ),
      rgba(0, 0, 0, 0.8);
  }

  .hero-container {
    display: flex;
    align-items: self-start;
    justify-content: center;
    flex-grow: 1;
    position: relative;
    overflow: hidden;
  }

  .hero {
    position: relative;
    text-align: center;
    max-width: 730px;
    width: 100%;
    height: 700px;
    padding: 50px 25px 110px;
    display: flex;
    align-items: self-start;
    flex-direction: row;

    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 25px;
  }

  .hero-content {
    position: relative;
    width: 50%;
    margin-top: 97px;
    text-align: left;
    margin-bottom: 50px;
  }

  .hero-content-partner {
    position: relative;
    width: 50%;
    margin-top: 97px;
    text-align: left;
    margin-bottom: 50px;
  }

  .hero-image {
    max-width: 72%;
    margin-right: -16%;
    margin-left: -7%;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .hero-container::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 10%;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      #151018 100%
    );
  }

  .hero-tags {
    margin-bottom: 10px;
    color: #fff;

    .hero-tag {
      display: inline-block;
      font-size: 0.6875rem;
      line-height: 1.4em;
      opacity: 0.7;
      border: 1px solid white;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 2px 5px;
      text-align: center;
      text-transform: uppercase;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  .hero-title {
    color: #fff;
    font-size: 2.625rem;
    line-height: 1.5em;
    margin-bottom: 15px;
    font-weight: 600;
  }

  .hero-subtitle {
    font-size: 1.125rem;
    line-height: 1.5em;
    font-weight: normal;
    margin-bottom: 30px;
    color: $color-text-primary-light;
    a {
      color: $color-text-primary-light;
      text-decoration: underline;
    }
  }

  .hero-cta {
    display: inline-block;
  }

  .AppStore-buttons {
    display: flex;
    margin-top: $spacing * 3;
    margin-bottom: $spacing * 2;

    .storeButtonLink:not(:last-child) {
      margin-right: $spacing * 2;
    }
  }

  .scroll-down {
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.5em;
    &:hover {
      text-decoration: underline;
    }
    position: absolute;
    bottom: 0px;
    max-width: 300px;
    margin: auto;
    display: block;
    padding-bottom: 40px;
    left: 0;
    right: 0;
    cursor: pointer;
  }

  .scroll-down .arrow-down {
    animation: pulse 2s infinite;
    fill: white;
  }

  @keyframes pulse {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(0, 10px);
    }
    100% {
      transform: translate(0, 0);
    }
  }

  .countdown-title {
    font-size: 1rem;
    line-height: 1.5em;
    margin-top: 54px;
    margin-bottom: 8px;
  }

  .countdown {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.05) 0%,
      rgba(141, 141, 141, 0.05) 100%
    );
    border-radius: 15px;
    padding: 12px 20px;
    display: inline-block;
    max-width: 100%;
  }

  .countdown ul {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
  }

  .countdown li {
    display: inline-block;
    font-size: 0.75em;
    line-height: 1.5em;
    font-weight: 500;
    list-style-type: none;
    padding: 0 15px;
    vertical-align: text-top;
  }

  .countdown li span {
    display: block;
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1em;
    margin-bottom: 5px;
  }

  //mobile

  @media (max-width: $breakpoint-xl) {
    .hero-bg-full {
      background-position: bottom right;
    }
  }

  @media (max-width: $breakpoint-lg) {
    padding-top: 0px;

    .ribbon {
      top: 40px;
      right: -75px;
      .ribbon-content {
        font-size: 10px;
      }
    }

    .hero-content,
    .hero-content-partner {
      width: 100%;
      padding: 0px 15px;
      margin-top: 0;
      text-align: center;
      margin-bottom: 0;
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    .hero-image,
    .hero-bg-full {
      display: none;
    }

    .hero-image-mobile {
      position: relative;
      display: block;
      width: 100%;
      margin-top: $navbar-height;
      overflow: hidden;

      img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        object-position: center;
      }
    }

    .hero-image-mobile-partner {
      position: relative;
      display: block;
      margin-top: 100px;
      img {
        width: 354px;
      }
    }

    .hero::before {
      height: 162px;
      top: 55px;
    }

    .hero {
      flex-direction: column;
      align-items: center;
      padding: 0px 0 50px;
      height: auto;
    }

    .hero-title {
      font-size: 2.125rem;
      line-height: 1.5em;
    }

    .hero-subtitle {
      font-size: 1rem;
      line-height: 1.5em;
    }

    .hero-tags .hero-tag {
      font-size: 0.5625rem;
      margin-bottom: 0;
      margin-top: 25px;
    }

    .AppStore-buttons {
      margin-top: 0;
      justify-content: center;
    }

    .countdown-title {
      font-size: 0.875rem;
      line-height: 1.5em;
    }

    .countdown {
      padding: 12px 10px;
    }

    .countdown li {
      font-size: 0.65em;
      line-height: 1.5em;
      padding: 0 8px;
    }

    .countdown li span {
      font-size: 1.9rem;
      line-height: 1em;
    }

    .scroll-down {
      font-size: 0.8rem;
      line-height: 1.5em;
      padding-bottom: 60px;
    }
  }
}
