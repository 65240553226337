@import '../../variables.scss';

.BlogCard {
  background: #272429;
  cursor: pointer;
  border-radius: 8px;
  height: 100%;
  min-height: 630px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .featured_image {
    position: relative;
    padding-top: 69%;
    background-size: cover;
    background-position: center center;
    overflow: hidden;

    .featured_image_bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      transition: all 0.7s cubic-bezier(0.2, 1, 0.22, 1);
    }
  }

  &:hover .featured_image_bg {
    transform: scale(1.07);
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: $spacing * 6 $spacing * 4;

    p {
      margin: 0;
    }

    .title {
      font-size: 1.25rem;
      color: white;
      line-height: 1.75em;
      font-weight: 500;
      margin: 10px 0 5px;
    }

    .description {
      flex: 1;
      font-size: 1rem;
      line-height: 1.75em;
      font-weight: 400;
      color: $color-text-secondary-light;
    }

    .type {
      color: $color-primary;
      text-transform: uppercase;
      font-size: 1rem;
      margin: 0;
    }

    .card_footer {
      display: flex;
      align-items: center;
      margin-top: 30px;
    }

    .author {
      font-size: 0.875rem;
      line-height: 1.5em;
      color: rgba(255, 255, 255, 0.9);
      margin: 0;
    }

    .author-info {
      flex: 1;
    }

    .datetime {
      font-size: 0.875rem;
      line-height: 1.5em;
      color: rgba(255, 255, 255, 0.55);
      margin: 0;
    }

    .author_avatar {
      width: 40px;
      height: 40px;
      overflow: hidden;
      border: 3px solid white;
      border-radius: 50%;
      margin-right: 10px;

      .author_avatar_img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  @media (max-width: $breakpoint-sm) {
    background: none;
    flex-direction: row;
    align-items: start;
    border-radius: 0;
    min-height: auto;

    .featured_image {
      width: 30%;
      padding-top: 0;
      border-radius: 8px;
      margin-right: 16px;

      &::after {
        content: '';
        display: block;
        padding-top: 100%;
      }
    }

    .content {
      padding: 0;
      .title {
        font-size: 0.9375rem;
      }
      .type {
        font-size: 0.75rem;
      }
      .card_footer {
        margin-top: 0;
      }
      .description,
      .author_avatar {
        display: none;
      }
      .author {
        display: inline-block;
      }
      .datetime {
        display: inline-block;
        &::before {
          content: ' | ';
        }
      }
    }
  }
}
