@import '../../variables.scss';

#ResponsiveDebugger {
  position: fixed;
  top: calc(100vh - 60px);
  z-index: 1;
  & > div {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    color: white;
    background: black;
    align-items: center;
    justify-content: center;
    display: none;
    opacity: 0.6;
    margin: 10px;
  }

  .xs {
    display: flex;
  }

  @media (min-width: $breakpoint-sm) {
    // big phone and up
    .xs {
      display: none;
    }
    .sm {
      display: flex;
    }
  }
  @media (min-width: $breakpoint-md) {
    // tablet and up
    .xs,
    .sm {
      display: none;
    }
    .md {
      display: flex;
    }
  }
  @media (min-width: $breakpoint-lg) {
    // desktop and up
    .xs,
    .sm,
    .md {
      display: none;
    }
    .lg {
      display: flex;
    }
  }
  @media (min-width: $breakpoint-xl) {
    // wide desktop and up
    .xs,
    .sm,
    .md,
    .lg {
      display: none;
    }
    .xl {
      display: flex;
    }
  }
}
