@import '../../variables.scss';

.FloatingMenu {
  // display: flex;
  position: absolute;
  top: $navbar-height;
  height: calc(100% - $navbar-height);
  overflow: hidden;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: $background-nav;

  @media (min-width: $breakpoint-lg) {
    margin-top: $spacing * 2;
    right: $spacing * 10;
    width: 400px;
    max-height: 60%;
    border-radius: $radius;
    overflow: auto;

    &.menu-settings {
      max-height: unset;
      overflow: hidden;
      background: none;
    }
  }
}

.floatingmenu-header {
  padding: $spacing * 3;
  height: 55px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  font-weight: 600;
  font-size: 14px;
  color: #afafaf;
}

.floatingmenu-body {
  flex: 1;

  overflow: auto;
  &::-webkit-scrollbar {
    width: 20px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 254, 254, 0.35);
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
    &:hover {
      background-color: rgba(255, 254, 254, 0.6);
    }
  }
}
