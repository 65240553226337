@import '../theme/animations.scss';

$controls-radius: 20px;

.mio-carrousel {
  width: 100%;
  position: relative;

  .elements {
    overflow-x: hidden;
    padding: 0;
    display: flex;
    & > * {
      flex-shrink: 0;
    }
  }

  &.scrollable .elements {
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .carrousel-shadow {
    &.hidden {
      opacity: 0;
    }
    width: 80px;
    z-index: 3;
    height: 100%;
    position: absolute;
    &.right {
      float: right;
      margin-right: 0;
      right: 0;
      background: linear-gradient(
        to left,
        #151018 0%,
        rgba(21, 16, 24, 0.84) 44.99%,
        rgba(21, 16, 24, 0) 100%
      );
    }
    &.left {
      float: left;
      margin-left: 0;
      left: 0;
      background: linear-gradient(
        to right,
        #151018 0%,
        rgba(21, 16, 24, 0.84) 44.99%,
        rgba(21, 16, 24, 0) 100%
      );
    }
  }

  &:hover .controls {
    opacity: 1;
  }

  .controls {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: $controls-radius * 2;
    height: $controls-radius * 2;
    border-radius: $controls-radius;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    opacity: 0;
    transition: opacity $fast;
    z-index: 10;

    &.left {
      left: 5px;
    }
    &.right {
      right: 5px;
      z-index: 3;
      svg {
        transform: rotate(180deg);
      }
    }

    &.hidden {
      opacity: 0;
    }

    &:hover {
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    }

    svg,
    svg > * {
      width: $controls-radius * 1.5;
    }
  }
}
