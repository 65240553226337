@import '../theme/colors.scss';
@import '../theme/layout.scss';

.mio-articlecard{
  display: block;
  height: 120px;
  width: 100%;
  min-width: 200px;
  padding: 20px;
  border-bottom: 1px solid $hover-highlight;
  
  &:hover, &:focus{
    background-color: rgba(255, 255, 255, 0.05);
  }
  &:active{
    background-color: rgba(255, 255, 255, 0.1);
  }
  .mio-articlecard-imgBlock{
    display: flex;
    float:left;
    width: 80px;
    height: 80px;
    background-color: #5C595E;
    border-radius: $radius;
    & div{
      width: 100%;
      height: 100%;
      background-size:cover;
    }
  }
  .mio-articlecard-textBlock{
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    line-height: 22px;
    .mio-articlecard-title{
      padding-top: 15px;
      color: $primary-white;
      font-size: 15px;
    } 
    .mio-articlecard-date{
      color: $secondary-white;
      font-size: 13px;
    }
  }
}
