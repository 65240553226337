@import '../../variables.scss';

.wrapper-highlight {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: $background-placeholder;

  @media (min-width: $breakpoint-lg) {
    border-radius: 8px;
  }

  .checkButton-container {
    position: absolute;
    right: 10px;
    bottom: 15px;
    .checkButton {
      background-color: #605765;
      border: 1px solid rgba(255, 255, 255, 0.15);
      padding: 5px;
      display: inline-block;
      margin: 4px 5px;
      cursor: pointer;
      border-radius: 16px;
    }
    .active {
      background-color: white;
    }
  }
}

.Highlight {
  // main css here
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-size: cover !important;
  display: flex;

  flex-direction: column;
  justify-content: flex-end;
  color: white;
  flex-grow: 0;
  transition: 0.5s transform;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px $spacing * 5 $spacing * 10 $spacing * 5;
    .pills {
      white-space: nowrap;
      display: flex;
      & > * {
        margin-right: $spacing * 3;
      }
    }

    .highlight-logo {
      max-height: 100px;
      display: flex;
      object-fit: contain;
      width: 100%;
      justify-content: center;
      margin-top: 15px;
      margin-bottom: 10px;
      img {
        height: 100%;
        max-width: 100%;
      }
      @media (min-width: $breakpoint-sm) {
        max-height: 80px;
        justify-content: flex-start;
      }
      @media (min-width: $breakpoint-md) {
        max-height: 100px;
      }
      @media (min-width: $breakpoint-xl) {
        max-height: 134px;
      }
    }

    .title-background {
      margin-top: $spacing;
      .mio-text {
        display: inline;
      }
    }

    .button-list {
      display: flex;
      width: 300px;
      margin-top: 10px;
      justify-content: space-evenly;

      .mio-button span {
        white-space: nowrap;
      }
    }
  }

  @media (min-width: $breakpoint-sm) {
    // tablet and up
    padding: $spacing * 3 $spacing * 11;
    justify-content: center;
    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 40%;
      min-width: 400px;
      padding: 0;
      .description {
        //display: inline-block;
        @media (min-width: $breakpoint-lg) {
          display: inline-block;
        }
      }
      .button-list {
        width: auto;
        & > * {
          margin-right: $spacing * 4;
        }
        justify-content: initial;
      }
    }
  }
  @media (min-width: $breakpoint-lg) {
    padding: $spacing * 3 $spacing * 15;
  }
  @media (max-width: $breakpoint-sm) {
    .content {
      flex: 1;
      justify-content: flex-end;
      background: linear-gradient(
        0deg,
        #000000e6,
        #000000b5,
        transparent,
        transparent
      );
    }
  }
}
