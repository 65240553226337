@import '../../variables.scss';

.MangaGrid {
  padding: $spacing * 5;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  & > * {
    margin: $spacing * 2 2px;
  }
  @media (min-width: $breakpoint-sm) {
    // desktop and up
    justify-content: flex-start;
    & > * {
      margin: $spacing * 2;
    }
  }
}
