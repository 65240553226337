@import '../../variables.scss';

#MangaPage {
  .chapter-action {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    fill: white;
    opacity: 0.8;

    .icon {
      width: 20px;
      margin-left: 10px;
    }
  }
  .information-bloc {
    .content {
      padding: 15px;
      @media (min-width: $breakpoint-sm) {
        padding: 12px;
      }
    }
    .title {
      padding-left: 15px;
      @media (min-width: $breakpoint-sm) {
        padding-left: 12px;
      }
    }
  }
  .similar-bloc {
    .title_bloc {
      padding: 6px 0 6px 0;
    }
    .carrousel-shadow {
      display: none;
    }
    .controls {
      svg {
        color: black;
      }
    }
  }
  .trailer-bloc {
    .content {
      display: flex;
      justify-content: center;
      height: 365px;
      padding: 15px !important;
      @media (min-width: $breakpoint-lg) {
        height: 228px;
      }
    }
  }
  .continue-reading-bloc {
    .content {
      padding: 0;
    }
    .mio-inlineCard {
      border-radius: 0 0 8px 8px;
    }
  }
  .continue-lies-bloc {
    background: transparent;
    .header {
      padding-left: 30px;
      padding-right: 0;
      border: none;
      @media (min-width: $breakpoint-lg) {
        padding-left: 0;
      }
    }
    .content {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .lectures-similaires-bloc {
    background: transparent;
    .header {
      border: none;
    }
  }

  .chapters-bloc {
    #btn-order {
      flex-direction: row-reverse;
      svg {
        margin-right: 0;
        margin-left: 15px;
      }
    }
  }

  .pre-header-action {
    display: flex;
    padding: 7px 13px 7px 13px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(90deg, #e11f7b 0%, rgba(198, 31, 225, 0) 55.73%),
      rgba(255, 255, 255, 0.05);
    .pre-header-component {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .pre-header-title,
    .pre-header-timing {
      font-size: 13px;
      color: white;
      opacity: 0.8;
    }
  }
  .ctaContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    .ctaImageSidebar {
      max-width: 360px;
      margin-bottom: 35px;
    }
  }

  .mio-bloc {
    margin-bottom: 35px;
    border-radius: 0;
    color: white;
    &.no-padding > .content {
      padding: 0px;
    }
    @media (min-width: $breakpoint-lg) {
      border-radius: $radius;
    }
  }
  .affiliation-bloc {
    align-self: center;
    border-radius: 8px;
    max-width: 360px;
    width: 100%;
    @media (max-width: $breakpoint-sm) {
      border-radius: 0px;
    }
    .content {
      border-radius: 0 0 8px 8px;
    }
  }
  .discord-img {
    align-self: center;
    margin: 0 auto 35px auto;
    width: 360px;
  }

  .activation-demo-img {
    width: 360px;
    margin: 0 auto 35px auto;
  }

  .disclaimerComp {
    display: flex;
    justify-content: center;
    align-items: center;
    .mio-disclaimer.small {
      width: 90%;
      .container {
        align-items: center;
      }
    }
  }
  .disclaimerProfile {
    margin: 0 15px 15px 15px;
    @media (min-width: $breakpoint-sm) {
      margin: 0 0 35px 0;
    }
  }
  .unlock-lecture-img {
    align-self: center;
    margin-bottom: 35px;
    img {
      max-width: 100%;
    }
  }

  .Mask {
    display: flex;
    align-items: center;
  }
  .trailer-modal {
    .bloc-trailer {
      width: 100%;
      height: 100%;
    }
    z-index: 1000;
    width: 80%;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: modalFade 0.8s;
  }
  .mask {
    animation: modalFade 0.8s;
  }

  @keyframes modalFade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .icon-trailer-modal {
    color: white;
    width: 30px;
    background: rgba(255, 255, 255, 0.15);
    padding: 4px;
    float: right;
    border-radius: 90%;
    height: 30px;
    cursor: pointer;
  }
  @media (max-width: $breakpoint-lg) {
    .trailer-bloc {
      .content {
        width: 80%;
        margin: 0 auto;
      }
    }
  }
}
