@import '../../variables.scss';

.onboardingBackground {
  overflow: hidden;
  background-color: $background-dark;
  background-size: cover;
  min-height: 100vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;

  .ellipse {
    animation: moving-ellipse1 10s infinite ease-in-out;
  }
  .ellipse2 {
    animation: moving-ellipse2 10s infinite ease-in-out;
  }

  @keyframes moving-ellipse1 {
    0% {
      transform: rotate(0deg) translateY(-150px) rotate(0deg);
    }
    50% {
      transform: rotate(-20deg) translateY(-150px) rotate(20deg);
    }
    100% {
      transform: rotate(0deg) translateY(-150px) rotate(0deg);
    }
  }
  @keyframes moving-ellipse2 {
    0% {
      transform: rotate(0deg) translateY(-300px) rotate(0deg);
    }
    50% {
      transform: rotate(20deg) translateY(-300px) rotate(-20deg);
    }
    100% {
      transform: rotate(0deg) translateY(-300px) rotate(0deg);
    }
  }

  .backgroundImage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
  }
  .successBg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://res-2.cloudinary.com/mangasio/image/fetch/c_limit,w_1200/f_auto/https://cdn.mangas.io/images/successBg.png'),
      linear-gradient(
        225deg,
        #174b9e 0%,
        #3d2d65 18.81%,
        #391843 46.06%,
        #411440 59.52%,
        #621435 80.12%,
        #7c1216 100%
      );
    background-position: center;
    background-size: cover;
    animation: shaking 0.5s infinite;
  }

  .blur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(21, 16, 24, 0.2);
  }
  .back-button {
    position: fixed;
    top: 0;
    padding: 15px;
    z-index: 2;
    width: 100%;
  }
  .wrapper {
    position: relative;
    max-width: 800px;
    width: 100%;
    padding: 30px 0;
  }
  .largeWrapper {
    max-width: 100%;
  }
}
@keyframes shaking {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
