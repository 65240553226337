@import '../variables.scss';

#HomePage {
  .carrousel-wrapper {
    .mio-indicatorPill {
      margin-left: 5px;
    }
    .title {
      display: flex;
      align-items: center;
    }
  }
  .carrousel-wrapper,
  .preview-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (min-width: $breakpoint-lg) {
    padding-top: 30px;
    .carrousel-wrapper,
    .preview-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
