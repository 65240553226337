@import '../../../variables.scss';

#LandingFeatures {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 100px;
  z-index: 1;

  .big-card {
    background: linear-gradient(93.56deg, #c52002 -11.02%, #98039b 118.24%);
    border-radius: 40px;
    overflow: hidden;
    margin: 48px 24px;
    padding: 0 80px;

    &.type-1 {
      background: linear-gradient(93.56deg, #c52002 -11.02%, #98039b 118.24%);
    }

    &.type-2 {
      background: linear-gradient(270deg, #9420c6 1.84%, #2088c9 100%);
    }
    &.type-3 {
      background: linear-gradient(261deg, #ffc138 1.68%, #ff2525 97.67%);
      .big-card-content {
        padding: 0;
      }
      .illu,
      .illu-mobile {
        margin-top: 0;
      }
    }

    .big-card-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 1140px;
      min-height: 532px;
      margin: 0 auto;
      font-size: 1.125rem;
      line-height: 1.5em;
      color: white;

      p {
        color: white;
      }

      .title {
        font-size: 2.25rem;
        line-height: 1.2em;
        font-weight: 700;
        margin-bottom: 15px;
      }

      .big-card-pill {
        display: inline-block;
        margin-bottom: 10px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 8px;
        font-size: 0.875rem;
        line-height: 1.5em;

        .background {
          background: rgba(255, 255, 255, 0.15);
        }
      }
    }

    &.reverse .big-card-content {
      flex-direction: row-reverse;
    }
    .feature-img {
      width: 100%;
    }
    .content {
      width: 44%;
      text-align: left;
      // padding-top: 30px;
    }
    .illu {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 46%;
      img {
        object-fit: contain;
        max-width: 100%;
        height: auto;
      }
    }

    .illu-mobile {
      display: none;
      img {
        object-fit: contain;
      }
    }

    .custom-illu-preview {
      width: 57%;
      // margin-top: -80px;
      margin-right: -80px;
      margin-left: -20px;
    }

    @media (max-width: 900px) {
      margin: 20px 5px;
      margin-bottom: 50px;

      padding: 0 30px;
      background: linear-gradient(158.66deg, #c52002 0%, #98039b 100%);

      &.type-1 {
        background: linear-gradient(158.66deg, #c52002 0%, #98039b 100%);
      }

      &.type-2 {
        background: linear-gradient(344.13deg, #9420c6 0.89%, #2088c9 99.32%);
      }
      &.type-3 {
        background: linear-gradient(164.98deg, #c10074 0%, #f77600 100%);
        .big-card-content {
          padding: 45px 0;
        }
      }

      .illu {
        display: none;
      }
      .illu-mobile {
        display: block;
      }
      &.reverse .big-card-content {
        flex-direction: column;
      }
      .big-card-content {
        flex-direction: column;
        justify-content: center;
        padding: 45px 0;

        p {
          font-size: 1rem;
        }

        .title {
          font-size: 1.625rem;
        }
      }
      .content {
        width: 100%;

        .store-buttons {
          justify-content: flex-start;
        }

        .Button {
          display: inline-block;
        }
      }

      .custom-illu-preview-mobile {
        margin-right: -20px;
      }
    }
  }

  /* OLD */
  .feature {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px 0 230px;

    &.last {
      margin-bottom: 50px;
    }
  }

  .feature .content {
    font-size: 1.125rem;
    line-height: 1.5em;
    max-width: 563px;
    padding: 30px;
    margin-top: 50px;

    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.07) 0%,
      rgba(255, 255, 255, 0.091) 100%
    );
    border-radius: 40px;
  }

  .ipad-border {
    // border: 4px solid #ffffff;
    // border-radius: 16px;
    background-image: url('../../../assets/pellicule-ipad-bg-min.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    max-width: 786px;
    width: 100%;
    padding: 5.6% 8.3%;

    .image {
      border-radius: 9px;
      overflow: hidden;
    }
  }

  .feature-adaptative {
    position: relative;
    max-width: 786px;
    width: 100%;

    .image-copyright {
      position: absolute;
      top: 95%;
      left: 21%;
      font-size: 10px;
      line-height: 15px;
      color: rgba(255, 255, 255, 0.3);
    }
  }

  .mobile-homepage {
    background-image: url('../../../assets/iphone-frame-min.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding: 18px 19px 29px 20px;
    padding: 2.4% 2.4% 3.7% 2.6%;

    position: absolute;
    left: -2px;
    bottom: -19px;
    width: 21.912%;
    height: 66%;
    // border: 2px solid #ffffff;
    // box-shadow: 0px 1px 22px rgba(0, 0, 0, 0.64);
    // border-radius: 11px;
    // overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    .image {
      overflow: hidden;
      border-radius: 8px;
      height: 100%;
    }
  }

  .mini-logo {
    position: absolute;
    bottom: -7%;
    right: 2%;
    transform: rotate(30.79deg);
    width: 20.3%;
  }

  .doom {
    position: absolute;
    z-index: 1;
    top: 0%;
    left: 0%;
    max-width: none;
    transform: translateY(-20%) translateX(155%);
  }

  .feature-modes {
    position: relative;
    max-width: 786px;
    width: 100%;
  }

  .star-1,
  .star-2,
  .star-3 {
    position: absolute;
    top: 24%;
    left: -18%;
  }
  .star-2 {
    top: -23%;
    left: -9%;

    img {
      transform: rotate(40deg);
    }
  }
  .star-3 {
    top: -1%;
    left: -34%;

    img {
      transform: rotate(40deg) scale(0.67);
    }
  }

  .modes-preview {
    position: relative;

    .image {
      position: absolute;
      top: 0;
      left: 0;
      // transition: opacity 100ms ease-out;
      opacity: 0;

      &.active {
        position: relative;
        z-index: 1;
        opacity: 1;
      }
    }

    .image-copyright {
      position: absolute;
      top: 95%;
      right: 9%;
      font-size: 10px;
      line-height: 15px;
      color: rgba(255, 255, 255, 0.3);
    }
  }

  .feature-hd {
    position: relative;
    z-index: 0;
    margin-bottom: 20px;

    .image-copyright {
      position: absolute;
      top: 101%;
      left: 50%;
      width: 100%;
      transform: translateX(-50%);
      font-size: 10px;
      line-height: 15px;
      color: rgba(255, 255, 255, 0.3);
    }

    .hd-rounded {
      border-radius: 50%;
      overflow: hidden;

      .hd-rounded-content {
        border-radius: 50%;
        overflow: hidden;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
      }

      .image {
        display: block;
        transform: scale(1.3);
      }
    }

    .hd-circle-1 {
      position: absolute;
      top: 10%;
      left: -19%;
    }
    .hd-circle-2 {
      position: absolute;
      top: 0%;
      left: 0%;
      z-index: -1;
    }
    .hd-circle-3 {
      position: absolute;
      bottom: 0%;
      right: -11%;
    }
  }

  .feature-library {
    position: relative;
    z-index: 0;

    .library-circle-1 {
      position: absolute;
      top: -35%;
      left: -80%;
      width: 85.6%;
    }
    .library-circle-2 {
      position: absolute;
      bottom: -22%;
      right: -39%;
      width: 57%;

      .image {
        transform: rotate(146deg);
      }
    }

    .library-history {
      position: relative;
      transform: translateX(-25%) translateY(-3%);
      z-index: 1;
      width: 100%;
    }
    .library-add {
      position: absolute;
      bottom: -4%;
      left: -12%;
      z-index: 2;
      width: 125%;
    }
    .library-knight {
      position: absolute;
      bottom: 11%;
      left: 55%;
      z-index: 0;
      width: 69.4%;
    }
  }

  @media (max-width: 1200px) {
    .doom {
      display: none;
    }
  }

  @media (max-width: 700px) {
    padding-right: 10px;
    padding-left: 10px;
    .feature .content {
      padding: 15px;
      margin-top: 40px;
      padding-top: 25px;
    }

    .feature {
      margin-bottom: 110px;
    }

    .star-1,
    .star-2,
    .star-3 {
      display: none;
    }

    .feature-hd {
      transform: scale(0.8);
      margin-bottom: -40px;

      .hd-circle-1 {
        width: 35.3%;
      }
      .hd-circle-2 {
        width: 18%;
      }
      .hd-circle-3 {
        width: 35.3%;
      }
    }

    .feature-library {
      width: 70%;

      .library-knight-illu,
      .library-circle-2 {
        display: none;
      }

      .library-circle-1 {
        top: 35%;
      }

      .library-add {
        //width: 80%;
      }
    }

    .ipad-border .image {
      border-radius: 4px;
      overflow: hidden;
    }
    .mobile-homepage .image {
      border-radius: 4px;
    }
  }
}
