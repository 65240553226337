@import '../../../variables.scss';

#LandingPartners {
  max-width: 1200px;
  .title {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 30px;
  }
  .partners-list {
    list-style-type: none;

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .link-item {
    flex: 1 0 12%;
    margin: 20px;
    flex-wrap: wrap;
    flex-direction: row;
    max-width: 100px;
    min-height: 100px;
    align-items: center;
    display: flex;
    a {
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
#LandingPartnersLine {
  .carrousel-wrapper {
    .elements {
      .publisher-bloc {
        background-color: transparent;
        border: none;
      }
    }
  }
}
@media (max-width: $breakpoint-lg) {
  #LandingPartners {
    margin-left: 20px;
    margin-right: 20px;
    .title {
      margin-top: 50px;
      margin-bottom: 30px;
    }
  }
}
