@import '../../variables.scss';

#teamPage {
  background-color: $background-dark;
  margin-bottom: 150px;
  .header {
    margin: 20px 50px;
    padding: 100px 50px;
    border-radius: 20px;
    height: 400px;
    background-image: url('https://cdn.mangas.io/images/textures/mio_bg_pattern.png');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .link-discord {
    margin: 0 5px;
    color: white;
    text-decoration: underline;
  }

  .team-pictures {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .card-wrapper {
      margin: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      perspective: 1000px;
      filter: grayscale(1);
      transition: filter 0.2s;

      &:hover {
        filter: grayscale(0);
      }

      .flip-card {
        position: relative;
        width: 250px;
        height: 250px;
        cursor: pointer;
        transition: transform 0.8s;
        transform-style: preserve-3d;

        .card-front:hover {
          animation: shake 0.7s;
        }

        .card {
          position: absolute;
          width: 100%;
          overflow: hidden;
          height: 100%;
          border-radius: 8px;
          backface-visibility: hidden;
          top: 0;
          left: 0;
        }

        .card-back {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px;
          text-align: center;
          justify-content: center;
          background-image: url('https://cdn.mangas.io/images/textures/mio_bg_pattern.png');
          color: white;
          transform: rotate3d(0, 1, 0, 180deg);
        }
      }

      @keyframes shake {
        10%,
        90% {
          transform: translate3d(-1px, 0, 0);
        }

        20%,
        80% {
          transform: translate3d(2px, 0, 0);
        }

        30%,
        50%,
        70% {
          transform: translate3d(-4px, 0, 0);
        }

        40%,
        60% {
          transform: translate3d(4px, 0, 0);
        }
      }

      .bottom-card {
        margin-top: 10px;
        text-align: center;
        color: white;
        display: flex;
        flex-direction: column;
        .card-name {
          font-weight: bold;
          font-size: 22px;
          line-height: 28px;
          letter-spacing: 0.25px;
          margin-bottom: 5px;
        }
        .card-position {
          font-weight: 200;
        }
      }
    }
    .card-wrapper:active .flip-card {
      transform: rotate3d(0, 1, 0, 180deg);
    }
  }
}
