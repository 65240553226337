@import '../../variables.scss';
.headerButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
}
#LandingHero {
  .headerPC {
    display: flex;
    align-items: center;
  }
  .hero-content {
    margin-top: 0;
  }
  .hero {
    align-items: center;
  }
  .stores {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-top: 30px;
    z-index: 10;

    .storeButtons {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      img {
        margin-top: 10px;
        margin-right: 10px;
      }
    }
  }
  .btm-header-text {
    z-index: 10;
    margin-top: 30px;
  }
}
.pc-steps {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  z-index: 100;
  margin-top: 50px;
  margin-bottom: 100px;
  .step-card {
    width: 40%;
    height: 250px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background: rgba(56, 47, 61, 0.65);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    padding: 30px 6%;
    margin: 20px;
    .mio-text {
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

#flash-message {
  width: 100%;
}
#LandingPagePC {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 300px;
  height: 100%;
  .popularConnected-line {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 50px;
    .buttonLink {
      justify-content: center;
      display: flex;
    }
    .titleBloc {
      display: none;
    }
    #button {
      margin-top: 20px;
      margin-bottom: 100px;
    }
    .elements {
      padding-top: 20px;
    }
  }
  .content-wrapper {
    text-align: center;
  }
  #LandingFaq {
    padding-left: 15px;
    padding-right: 15px;
  }
}

//mobile
@media (max-width: $breakpoint-md) {
  #flash-message {
    width: 100%;
    padding: 0;
  }
  #LandingHero {
    .stores {
      display: flex;
      align-items: center;
      justify-content: center;
      .storeButtons {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .headerButtons {
    flex-direction: column;
    margin-bottom: 20px;
    margin-top: 20px;
    .mio-button {
      margin-bottom: 20px;
    }
  }
  #LandingPage {
    .partners-list {
      padding: 0;
    }
  }
}
@media (max-width: $breakpoint-lg) {
  .pc-steps {
    flex-direction: column;
    .step-card {
      width: 80%;
    }
  }
}
