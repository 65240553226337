@import '../theme/colors.scss';
@import '../theme/layout.scss';
@import '../theme/animations.scss';

.mio-discovery-card {
  border-radius: $radius;
  background: $loading-gradient;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  transition: all $medium ease;
  position: relative;
  overflow: hidden;
  z-index: 1;

  .image-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    .image {
      flex: 1;
      background-size: cover;
      background-position: center;
      transition: transform $medium ease;
    }
  }

  &:hover {
    border-color: $primary-white;
    .image {
      transform: scale($zoom-scale-on-hover);
    }
  }

  .tags {
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    & > * {
      margin: $spacing $spacing 0 $spacing;
    }
  }

  &.large {
    .tags {
      padding: 8 * $spacing 0 7 * $spacing 0;
    }
    width: 295px;
    height: 500px;
  }

  &.small {
    .tags {
      padding: 6 * $spacing 0 5 * $spacing 0;
    }
    width: 250px;
    height: 420px;
  }
}
