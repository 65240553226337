@import '../../../variables.scss';

#TouchReader {
  overflow: auto;

  .image-wrapper {
    position: relative;
    width: 100%;
    height: auto;

    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .placeholder-svg {
      display: block;
      background-color: rgba(255, 255, 255, 0.02);
      width: 100%;
      height: auto;
      max-width: 100%;
    }
  }

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    svg {
      width: 60px;
      fill: rgba(0, 0, 0, 0.5);
    }
  }

  .reload-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 200px;
  }

  &.reader-vertical,
  &.reader-webtoon {
    .page {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: $spacing;
    }

    #chapter-previous-divider,
    #chapter-next-divider {
      .Button .icon svg {
        transform: rotate(-90deg);
      }
    }
  }

  &.reader-webtoon {
    .page {
      margin-bottom: 0;
    }
  }

  &.reader-page {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    scroll-snap-type: x mandatory;
    align-items: center;
    overflow: auto;

    .page {
      flex-shrink: 0;
      width: 100%;
      height: auto;
      max-height: 100%;
      margin: 0 25px;
      scroll-snap-align: center;
      display: flex;
      justify-content: center;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }

    .separator {
      width: 100%;
      flex-shrink: 0;
    }

    .image-wrapper.min-ratio {
      width: auto;
      height: 100vh;
    }
  }
}
