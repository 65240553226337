.admin-icon {
  background-color: red;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  position: absolute;
  top: -13px;
  right: 0px;
  padding: 4px;
  svg {
    fill: white;
  }
}
