// N'importer que les fichiers nécessaires
@import '../theme/colors.scss';
@import '../theme/layout.scss';

.mio-disclaimer {
  background: hsla(0, 0%, 100%, 0.15);
  border: 1px solid #2c272f;
  border-radius: 8px;
  display: flex;
  .container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .mio-button {
      margin: 10px;
      white-space: nowrap;
      background-color: transparent;
    }
    .firstLine {
      display: flex;
      align-items: center;
    }
  }
  .childrenContent {
    padding: 10px;
    display: flex;
    align-items: center;
    color: white;
  }
  svg {
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 90%;
    padding: 2px;
    margin-right: 10px;
    background-color: transparent;
    color: white;
    width: 25px;
    margin-top: 2px;
  }

  &.mio-disclaimer-info {
    background: rgba(32, 215, 255, 0.15);
    border-color: rgba(255, 255, 255, 0.15);
    svg {
      color: #20d7ff;
    }
  }

  &.mio-disclaimer-success {
    background: rgba(33, 189, 77, 0.15);
    border-color: rgba(255, 255, 255, 0.15);
    svg {
      color: #21bd4d;
    }
  }
  &.mio-disclaimer-danger {
    background: rgba(207, 7, 7, 0.15);
    border-color: rgba(255, 255, 255, 0.15);
    svg {
      color: #bd2121;
    }
  }
  &.mio-disclaimer-warning {
    background: rgba(189, 127, 33, 0.15);
    border-color: rgba(255, 255, 255, 0.15);
    svg {
      color: #bd7921;
    }
  }
  &.mio-disclaimer-promotion {
    background: linear-gradient(
      91.8deg,
      #44abf6 0%,
      #8e73f2 60.5%,
      #c459be 100%
    );
    .mio-button {
      background: rgba(0, 0, 0, 0.4);
    }
    svg {
      color: white;
      background-color: rgba(255, 255, 255, 0.15);
    }
  }

  &.mio-disclaimer-advertising {
    background: linear-gradient(90deg, #dd501b 0%, #e0a407 100%);
    .mio-button {
      background: rgba(255, 255, 255, 0.9);
      .label {
        color: #dd6f1b;
      }
    }
    svg {
      color: white;
      background-color: rgba(255, 255, 255, 0.15);
    }
  }

  &.mio-disclaimer-hint {
    background: rgba(255, 183, 43, 0.15);

    border-color: rgba(255, 255, 255, 0.15);
    svg {
      color: #ffb72b;
    }
  }

  &.mio-disclaimer-signup {
    background: rgba(255, 183, 43, 0.15);

    border-color: rgba(255, 255, 255, 0.15);
    svg {
      color: #ffb72b;
    }
    .mio-button {
      background: rgb(234, 100, 25);
      background: linear-gradient(
        90deg,
        #f06e0b 0%,
        #f4af01 9%,
        #f47f0e 33%,
        #f47f0e 74%,
        #fee639 100%
      );
      .label {
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
      }
    }
  }

  &.mio-disclaimer-locked {
    background: rgba(48, 55, 79, 1);
    border-color: rgba(255, 255, 255, 0.15);
    svg {
      color: white;
      min-width: 30px;
      padding: 5px;
      background: linear-gradient(84.76deg, #0bc8d4 -19.69%, #074dff 92.45%);
      border: 1px solid white;
    }
    .mio-button {
      background: linear-gradient(84.76deg, #0bc8d4 -19.69%, #074dff 92.45%);
    }
  }

  &.small {
    .container {
      flex-direction: column;
      align-items: flex-start;
      padding-left: 10px;
      padding-right: 10px;
    }
    .childrenContent {
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 0 0 0px;
    }
    .firstLine {
      align-items: center;
      margin-bottom: 10px;
      .iconContent {
        align-self: baseline;
      }
    }
    .secondLine {
      margin-left: 40px;
      margin-bottom: 10px;
    }
    .mio-button {
      margin: 0 0 10px 0;
      width: 100%;
    }
  }
  &.large {
    width: 100%;
    .side {
      align-items: center;
    }
    .childrenContent {
      flex-direction: row;
      p {
        margin-right: 10px;
      }
    }
  }
}
