@import '../../../variables.scss';

.voucherSuccessSlide {
  padding: $spacing * 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  .coffee-success {
    width: 180px;
  }
  .mio-text {
    margin-top: $spacing * 6;
  }
}
