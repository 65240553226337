@import '../../variables.scss';

// Adapt these values if the component <MangaTile /> changes
$small-tile: 198px;
$medium-tile: 255px;
$large-tile: 329px;

.align-controls-with-pictures {
  &.tiles-small {
    .controls {
      top: $small-tile * 0.5;
    }
  }
  &.tiles-medium {
    .controls {
      top: $medium-tile * 0.5;
    }
  }
  &.tiles-large {
    .controls {
      top: $large-tile * 0.5;
    }
  }
}
