@import '../../variables.scss';

.Quote {
  // main css here
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: $spacing * 14;
  .--subtitle {
    max-width: 700px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      width: 115px;
      fill: #272229;
    }
  }
  .quote-link {
    text-decoration: none;
    color: $color-primary;
  }

  .separator {
    width: 240px;
    border-top: 1px solid black;
    display: inline-block;
    margin: $spacing * 10 0;
  }

  .dark-mode & {
    .separator {
      border-top: 1px solid white;
    }
  }

  @media (min-width: $breakpoint-sm) {
    // big phone and up
  }
  @media (min-width: $breakpoint-md) {
    // tablet and up
  }
  @media (min-width: $breakpoint-lg) {
    // desktop and up
  }
  @media (min-width: $breakpoint-xl) {
    // wide desktop and up
  }
}
