@import '../theme/colors.scss';
@import '../theme/layout.scss';

.mio-radio-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  min-height: 80px;
  overflow: hidden;
  cursor: pointer;
  width: 100%;

  &.default {
    background: linear-gradient(270deg, #68586f 0%, #5a4b60 100%);
    border: 1px solid #524259;
  }
  &.orange {
    background: linear-gradient(270deg, #dd7007 0%, #c13d00 100%);
    border: 1px solid #f18725;
  }
  &.blue {
    background: linear-gradient(88.5deg, #0459a8 0%, #2384f7 100%);
    border: 1px solid rgba(255, 255, 255, 0.15);
  }
  &.promo {
    border: 1px solid #f18725;

    background: linear-gradient(
      91.8deg,
      #c10a96 0%,
      #dd501b 60.5%,
      #e0a407 100%
    );
  }

  &:hover {
    border-color: white;
  }

  .mio-checkbox {
    margin-top: 0;
    padding: 17px 25px;
  }

  .mio-radio-box-content {
    display: flex;
    flex: 1;
    flex-direction: row;
  }

  .mio-radio-box-center {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
  }

  .mio-radio-box-right {
    white-space: nowrap;
    color: rgba(255, 255, 255, 0.8);
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.mobile {
    .check {
      margin-right: 15px;
    }
  }

  .subscription-card-input:checked + label {
    .check {
      border-color: white;
      padding: 2px;
      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 50%;
      }
    }
  }
}
