@import '../../variables.scss';

.LtrContentWall {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  padding: 15px;
  display: flex;
  overflow: auto;
  align-items: center;
  justify-content: center;

  .LtrContentWallPopIn {
    margin: auto;
    max-width: 265px;
    min-height: 265px;
    width: 100%;
    background: rgba(0, 0, 0, 0.95);
    text-align: center;
    padding: 15px;
    border-radius: 8px;

    .PopInImage {
      margin: 30px 0 10px;
      max-width: 100%;
      height: auto;
    }

    .message {
    }
  }
}
