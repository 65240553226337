@import '../../variables.scss';

#Favorites {
  #page-title,
  .--title,
  .--subtitle {
    padding-left: $spacing * 2;
    margin-top: $spacing * 9;
  }
  @media (max-width: $breakpoint-md) {
    #page-title {
      padding-left: $spacing * 5;
    }
  }
  .MangaGrid {
    padding: 25px 0 25px 0;
  }
  .bloc-empty-list {
    width: 100%;
    margin-top: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    #empty-list-container {
      padding: $spacing * 2;
      width: 30%;
      border: 1px solid rgba(255, 255, 255, 0.15);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      svg {
        color: white;
      }
      #empty-list-text {
        margin: 16px 0 8px 0;
      }
      @media (max-width: $breakpoint-lg) {
        width: 60%;
      }
      @media (max-width: $breakpoint-sm) {
        width: 90%;
      }
    }
  }
}
