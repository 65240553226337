.mio-reset-password {
  .subtitleLightGrey {
    text-align: center;
    margin-top: 13px;
    opacity: 0.7;
  }
  .mio-button {
    margin-top: 100px;
  }
  .successIcon {
    color: #21bd4d;
    width: 131px;
  }
}

