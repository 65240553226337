@import '../theme/colors.scss';

.mio-text {
  color: $primary-white;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  padding: 0;

  &.text.bold {
    font-weight: 600;
  }

  &.display.bold {
    font-weight: 700;
  }

  &.display.small {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.25px;
  }

  &.display.medium {
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0.5px;
  }

  &.display.large {
    font-size: 42px;
    line-height: 54px;
    letter-spacing: 1px;
  }

  &.text.xsmall {
    font-size: 12px;
    line-height: 22px;
  }

  &.text.small {
    font-size: 14px;
    line-height: 24px;
  }

  &.text.medium {
    font-size: 16px;
    line-height: 28px;
  }

  &.text.large {
    font-size: 18px;
    line-height: 32px;
  }

  &.text.extra-large {
    font-size: 20px;
    line-height: 30px;
  }

  &.display.mobile.small {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.25px;
  }

  &.display.mobile.medium {
    font-size: 26px;
    line-height: 36px;
    letter-spacing: 0.2px;
  }

  &.display.mobile.large {
    font-size: 34px;
    line-height: 42px;
    letter-spacing: 0.5px;
  }

  &.text.mobile.small {
    font-size: 13px;
    line-height: 24px;
  }

  &.text.mobile.medium {
    font-size: 14px;
    line-height: 24px;
  }

  &.text.mobile.large {
    font-size: 16px;
    line-height: 26px;
  }

  &.text.mobile.extra-large {
    font-size: 18px;
    line-height: 32px;
  }
}
