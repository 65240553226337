@import '../../variables.scss';

$height: 6px;

.ProgressBar {
  display: inline-block;
  height: $height;
  width: 100%;
  background: white;
  border-radius: $height * 0.5;
  .completion {
    display: flex;
    height: 100%;
    background: $color-primary;
    border-radius: $height * 0.5;
  }
}
