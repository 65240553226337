@import '../../variables.scss';

.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 25px;
  cursor: pointer;
  border: none;
  color: $color-text-primary-light;
  border-radius: 8px;
  position: relative;
  box-sizing: border-box;
  box-shadow: -1px 4px 5px rgba(0, 0, 0, 0.25);

  &.narrow {
    padding: 10px 15px;
  }

  .label {
    margin-top: 0;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
  }

  .icon:first-child + .label {
    margin-left: $spacing;
  }

  .icon,
  .icon > * {
    height: 20px;
    width: 20px;
  }

  svg {
    fill: $color-text-primary-light;
  }

  &.primary {
    background: linear-gradient(353.16deg, #92124a 8.67%, #d91d6f 91.33%);
  }

  &.secondary {
    background: linear-gradient(180deg, #2e1c39 0%, #18131c 100%);
  }
  &.gold {
    background: rgb(234, 100, 25);
    background: linear-gradient(
      0deg,
      rgba(234, 100, 25, 1) 0%,
      rgba(235, 176, 25, 1) 50%
    );
  }
  &.link {
    background: none;
    border: none;
    box-shadow: none;
  }

  &.vertical,
  &.horizontalLg {
    .label {
      position: absolute;
      margin-top: $spacing;
      top: 100%;
      white-space: nowrap;
      font-size: 10px;
      margin-left: 0;
      background: transparent !important;
    }
    .icon {
      margin-right: 0;

      &:first-child + .label {
        margin-left: 0;
      }
    }
  }

  .effect {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: inherit;
    border: 1px solid white;
    transition: $animation-speed-fast ease-in;
  }

  &:hover {
    .effect {
      opacity: 0.8;
    }
  }

  &:active {
    .effect {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  &:focus {
    outline: 0;
  }

  &:disabled,
  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }

  @media (min-width: $breakpoint-sm) {
    // big phone and up
  }
  @media (min-width: $breakpoint-md) {
    .label {
      font-size: 16px;
    }
    &.horizontalLg {
      .label {
        margin-top: 2px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        margin-left: $spacing;
        position: initial;
      }
      .icon {
        margin-right: $spacing;
      }
    }
    &.large {
      padding: 14px 25px;
      .label {
        font-size: 18px;
        line-height: 27px;
      }
    }
    // tablet and up
  }
  @media (min-width: $breakpoint-lg) {
    // desktop and up
  }
  @media (min-width: $breakpoint-xl) {
    // wide desktop and up
  }
}
