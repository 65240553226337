@import '../../variables.scss';

.voucher-container {
  width: 100%;
  margin-bottom: 50px;
  max-width: 1200px;
  @media (max-width: $breakpoint-xl) {
    width: 90%;
  }
  #PricingCardNew {
    .mainCard {
      padding-top: 0;
      display: flex;
      align-items: center;
    }
    .background {
      background-position: center;
    }
    .card-content {
      text-align: center;
    }
    .subtitle {
      margin-bottom: 30px;
    }
    .card-header {
      display: none;
    }
  }
}
