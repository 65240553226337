@import '../../variables.scss';
.noFav {
  .title {
    margin-bottom: 15px;
    margin-left: 20px;
  }
  .noFav-bloc {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 30px;
    padding: 30px;
  }
  .noFav-text {
    margin-top: 10px;
  }
  @media (min-width: $breakpoint-lg) {
    .title {
      margin-left: 0px;
    }
  }
}
