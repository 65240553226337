@import '../../variables.scss';

#Footer {
  text-align: center;
  padding: 36px;
  padding-bottom: 29px;
  background-color: #faf4fd;
  .dark-mode & {
    background-color: transparent;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }
  .social-links {
    display: inline-flex;
    justify-content: space-around;
    max-width: 230px;
    width: 100%;
    margin-top: 16px;
  }
  .social-links .link-item {
    width: 36px;
    height: 36px;
    overflow: hidden;
    border-radius: 50%;
    display: block;
    box-shadow: -1px 4px 5px rgba(0, 0, 0, 0.25);
    transition: all 100ms ease;
  }

  .social-links .link-item:hover {
    transform: scale(1.2);
    /* box-shadow: -1px 6px 5px rgba(0, 0, 0, 0.25); */
  }

  .social-links .link-item:active {
    transform: scale(1.2);
    /* box-shadow: -1px 4px 5px rgba(0, 0, 0, 0.25); */
  }

  .social-links .link-item:focus {
    outline: none;
    box-shadow: 0 0 0 1px white;
  }

  .footer-menu {
    max-width: 1500px;
    width: 100%;
    margin: 68px auto;
  }

  .footer-menu a:hover {
    text-decoration: underline;
  }

  .footer-menu a,
  p {
    color: $color-text-primary-dark;
    .dark-mode & {
      color: $color-text-primary-light;
    }
    a {
      text-decoration: underline;
      color: $color-text-primary-dark;
      .dark-mode & {
        color: $color-text-primary-light;
      }
    }
  }

  .footer-menu > ul {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    line-height: 1.5em;
    text-align: left;
  }

  .footer-menu > ul > li > ul > li {
    line-height: 1.5em;
    margin-bottom: 10px;
  }

  .footer-menu .title {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 10px;
    opacity: 0.5;
    font-size: 1rem;
    line-height: 1.5em;
  }

  .download-content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 30px;
    margin-top: 0;
    @media (min-width: $breakpoint-md) {
      margin-top: 30px;
    }
    .AppStore-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media (min-width: $breakpoint-sm) {
        flex-direction: row;
      }
      .storeButtonLink {
        margin-right: 0;
        @media (min-width: $breakpoint-sm) {
          margin-right: 10px;
        }
      }
    }
  }

  .footer-version {
    p {
      font-weight: 600;
      font-size: 0.9375rem;
      line-height: 1.5em;
    }
    .version-icon {
      display: inline-block;
      height: 0.8em;
      width: auto;
    }
  }

  &.minimalFooter {
    background-image: none;
    padding-top: 60px;
    .footer-menu {
      margin: 30px auto;

      & > ul > li {
        width: 100%;
      }

      & > ul > li > ul {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        line-height: 1.5em;
        text-align: left;

        & > li {
          margin: 0 10px;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .footer-menu {
      font-size: 0.875rem;
      line-height: 1.5em;
      margin: 0;
      padding: 68px 0 0;
    }

    .footer-menu > ul {
      flex-direction: column;
    }

    .footer-menu > ul > li {
      margin-bottom: 70px;
    }

    &.minimalFooter {
      .mio-logo {
        width: 80px;
        height: 80px;
      }
      .footer-menu {
        padding: 0;

        & > ul > li > ul {
          flex-direction: column;
        }
      }
    }
  }
}
