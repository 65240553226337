@import '../../variables.scss';

#mio-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(13, 8, 24, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 30px 45px;
  color: white;
  width: 100%;
  .header {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    .title {
      padding: 0;
      margin: 0;
      img {
        display: block;
        height: 37px;
        width: 198px;
      }
    }
  }

  @media (max-width: $breakpoint-sm) {
    background: none;
    border: none;
    padding: 0 15px;
  }
}
