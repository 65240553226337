@import '../../variables.scss';

.ShareButton {
  position: relative;
  display: flex;
  .share-links {
    position: absolute;
    margin-top: 15px;
    right: 0;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    @media (min-width: $breakpoint-lg) {
      left: 0;
      margin-top: 70px;
      align-items: flex-start;
    }
  }
  .label-share {
    margin-left: 10px;
  }
  .share-link-icon {
    position: relative;
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
    margin-bottom: 12px;
    padding: 6px 12px 6px 12px !important;
    background: #3e3742 !important;
    box-shadow: 0px 0px 0px 0.5px #1a171c;
    border-radius: 20px;
    overflow: hidden;

    & svg {
      color: white;
      width: 32px;
      height: 32px;
    }
    > * {
      z-index: 2;
    }
  }
  .urlShare {
    width: 30px !important;
    height: 30px !important;
    border-radius: 20px;
    padding: 5px;
    background-color: grey;
  }
  .share-link-icon:hover {
    background: #564c5c !important;
  }

  .long-label {
    width: 80px;
  }
  .copied-link {
    fill: white;
    height: 40px;
    justify-content: center;
    #copied-img {
      width: 32px;
      height: 32px;
    }
  }
  .alert .content {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon-copied {
    height: 26px;
  }
  .animation {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    bottom: 0;
    width: 0;
    background: linear-gradient(to right, #151018 0, transparent 100%) left;
    transition: 0.3s ease-out;
    border-radius: 20px;
    &.copied {
      width: 100%;
    }
  }
  .copiedLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
