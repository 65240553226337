@import '../../variables.scss';

#BlogPage .BlogPage-container {
  color: white;

  .content-wrapper {
    max-width: 1281px + (80px * 2);
    width: 100%;
    margin: 0 auto;
    padding: 0 5.3%;
    overflow: hidden;
  }

  .BlogBanner {
    position: relative;
    img {
      width: 100%;
      border-radius: 8px;
      height: auto;
      margin: 0 auto;
    }
  }

  .BlogHero {
    margin-top: 45px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: $breakpoint-lg) {
    .BlogBanner {
      img {
        border-radius: 0;
      }
    }
  }

  .BlogFlex {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    flex-wrap: wrap;
    margin-bottom: 30px;

    .BlogFlex_item {
      margin-bottom: 20px;
      flex: 0 1 32%;
    }
  }

  .pagination {
    .hidden {
      visibility: hidden;
    }
    display: flex;
    justify-content: space-around;
  }

  @media (max-width: $breakpoint-lg) {
    .BlogFlex_item {
      margin-bottom: 20px;
      flex: 0 1 45% !important;
    }
  }

  @media (max-width: $breakpoint-sm) {
    .content-wrapper {
      padding: 40px 4% 0;
    }

    .BlogFlex {
      width: calc(100% + 16px * 2);
      margin: -16px;
      margin-bottom: 70px;

      .BlogFlex_item {
        margin-bottom: 0px;
        padding: 16px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);

        flex: 0 1 100% !important;
      }
    }
  }

  /* BlogPost */
  .BlogPost {
    cursor: pointer;
    padding: 20px 15px;

    color: $color-text-primary-dark;
    .dark-mode & {
      color: $color-text-primary-light;
    }

    &.large {
      padding: 0;
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      .dark-mode & {
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      }
    }

    .featured_image {
      position: relative;
      padding-top: 100%;

      background-size: cover;
      background-position: center center;
      border-radius: 19px;
      overflow: hidden;

      .featured_image_bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        transition: all 0.7s cubic-bezier(0.2, 1, 0.22, 1);
      }
    }
    &.large .featured_image {
      padding-top: 83%;
      border-radius: 4px;
    }
    &:hover .featured_image_bg {
      transform: scale(1.07);
    }

    .content {
      .title {
        font-size: 1rem;
        line-height: 1.5em;
        font-weight: 600;
        margin: 0;
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
      }

      .content_bottom {
        font-size: 0.875rem;
        line-height: 1.5em;
        color: $color-text-secondary-light;
        margin-top: 5px;
        p {
          margin: 0;
        }
      }
    }

    .type {
      color: $color-primary;
      text-transform: uppercase;
      font-size: 16px;
    }
  }

  /* BlogFilters */
  .BlogFilters {
    font-size: 1.125rem;
    line-height: 1.5em;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px 0;
    margin-top: 50px;
    margin-bottom: 30px;
    overflow: auto;

    .link {
      color: $color-text-secondary-light;

      &:not(:last-child) {
        margin-right: 5%;
      }

      &:hover {
        color: $color-text-primary-light;
      }

      &.active {
        color: $color-text-primary-light;
        font-weight: 600;
      }
    }
  }
}
