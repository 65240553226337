@import '../../variables.scss';

.ContinueReading {
  .mio-inlineCard {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }
  .noHistory {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    text-align: center;
    .noHistory_title {
      margin-bottom: 10px;
    }
  }
}
.menuSelector {
  display: flex;
  flex-direction: column;
  height: 100px;
  .tabsSelector {
    display: flex;
    border-radius: $radius;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    background-color: #342c39;
    margin-top: 15px;
    .tab {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 100%;
      border-radius: $radius;
      padding: 10px;
      color: $color-text-primary-light;
      .pill {
        background-color: $color-primary;
        margin-left: 5px;
      }
    }
    .selectedTab {
      background-color: #2d2332;
    }
  }
}
