@import '../../variables.scss';

.header-partner {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.3) 100%
    ),
    url('https://res-2.cloudinary.com/mangasio/image/fetch/f_auto/https://cdn.mangas.io/landing/partner-header.jpg');
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  padding: 100px 50px;
  text-align: center;
  height: 500px;
  .mio-button {
    margin-top: 50px;
  }
}
#PartnerPage {
  .partners {
    margin-bottom: 100px;
  }

  .about {
    padding: 50px;
    margin-bottom: 100px;
    background: $gradient-purple-red;
    border-radius: 20px;
    .subtitleText {
      color: white;
    }
    .wrapper-about {
      margin-top: 50px;
      margin-bottom: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      .left {
        text-align: left;
        flex: 1;
        margin-right: 50px;

        img {
          margin-bottom: 20px;
        }
      }
      .right {
        flex: 1;
      }
    }

    .wrapper-numbers {
      text-align: left;
      overflow: 'auto';
      margin-top: 50px;
      width: 100%;
      .carrousel-wrapper {
        padding: 0;
      }
    }
  }

  .wrapper-brand {
    margin-top: 40px;
    background: $gradient-pink;
    text-align: left;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 50px;
    img {
      margin-right: 50px;
    }
  }

  .wrapper-plan {
    width: 100%;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .plan-cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
    .animate {
      opacity: 0.5;
      &:hover {
        opacity: 1;
        background-color: #664ac8;
        transition: all 100ms ease;
      }
    }
    a {
      margin-top: 50px;
    }
  }
  .wrapper-digital {
    margin-bottom: 100px;
    padding: 50px;
    border-radius: 20px;
    background: $gradient-dark-blue;
    .subtitle {
      margin: 50px;
    }
    .wrapper-visibility {
      display: flex;
      align-items: center;
      .left {
        flex: 1;
        padding: 20px;
      }
      .right {
        flex: 1;
        padding: 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        .animate {
          opacity: 0.5;
          &:hover {
            opacity: 1;
            background-color: #ffffff;
            transition: all 100ms ease;
            .mio-text {
              color: #403a45;
            }
            .card-content-visitors {
              border: 1px solid #403a45;
            }
          }
        }
        .card-content {
          width: 100%;
        }
      }
    }
  }
  .wrapper-cta {
    background: $gradient-passculture;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    .mio-button {
      margin-top: 50px;
    }
  }
  .card-content {
    padding: 20px;
    width: 335px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(255, 255, 255, 0.15);
    border: 1px solid rgb(255, 255, 255);
    border-radius: 20px;
    margin: 20px;
    height: 150px;

    .card-content-visitors {
      border: 1px solid rgba(255, 255, 255, 0.3);
      width: fit-content;
      margin: 0 auto;
      padding-inline: 5px;
      border-radius: 10px;
    }
    img {
      object-fit: contain;
    }
    .card-content-text {
      text-align: center;
      margin: 0;
    }
  }

  @media (max-width: $breakpoint-lg) {
    padding-inline: 20px;
    .about {
      padding: 0;
      .titleHeader {
        padding-top: 50px;
        padding-inline: 50px;
      }
      .wrapper-about {
        padding-inline: 20px;
        .left {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 50px;
          margin-right: 0;
        }
      }
      .wrapper-numbers {
        .carrousel-wrapper {
          .card-content {
            margin-left: 20px;
            margin-right: 5px;
            margin-bottom: 20px;
            flex-direction: column;
            width: 150px;
            height: auto;
          }
        }
        .mio-text {
          padding-inline: 20px;
          text-align: center;
        }
      }
    }
    .wrapper-brand {
      flex-direction: column;
      padding: 20px;
      img {
        margin-right: 0;
        margin-bottom: 50px;
      }
      .subtitle {
        margin-top: 20px;
      }
    }
    .wrapper-digital {
      padding-inline: 0;
      padding-bottom: 0;

      .subtitle {
        margin: 20px;
      }
    }
    .wrapper-plan {
      .card-content {
        width: 100%;
      }
    }
    .wrapper-visibility {
      flex-direction: column;
      .right {
        .card-content {
          width: 100% !important;
        }
      }
    }
  }
}
