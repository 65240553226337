@import '../../../variables.scss';

$menuHeight: 50px;

#LargeMenu {
  height: $menuHeight;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: $breakpoint-lg) {
    .FloatingMenu {
      height: auto;
    }
  }

  .reader-menu-content {
    height: $menuHeight;
    display: flex;
    justify-content: space-evenly;
    .icon {
      width: 25px;
      height: 25px;
    }
    .backIcon {
      margin-right: $spacing * 2;
    }
    .reader-menu-block {
      // flex: 1;
      margin: 0 $spacing * 3;

      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
    }
    .zoom {
      width: 150px;
      justify-content: center;
      align-items: center;
      display: flex;

      .icon {
        width: 25px;
        height: 25px;
      }
      .--text {
        padding: 0 $spacing;
        margin-right: 1px;
        font-size: 14px;
        font-weight: 500;
      }
      .slider {
        margin: 0 10px;
      }
    }

    .chapter-select {
      flex: 1;
      .select {
        width: 90%;
      }
    }

    .select.page {
      max-width: 120px;
    }

    .settings-toggle {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 910;
    }
  }
}
