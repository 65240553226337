@import '../../variables.scss';

.Navbar {
  display: flex;
  padding: 0 $spacing * 3;
  height: $navbar-height;
  user-select: none;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 900;
  background: $background-nav;

  .logo {
    margin-top: $spacing;
    height: 12px;
    fill: $background-light;
    display: none;
  }

  .logoMobile {
    margin-top: $spacing;
    height: 27px;
    fill: $background-light;
    margin-left: $spacing;

    .io {
      fill: $background-dark;
    }
  }

  .side {
    flex-shrink: 0;
    position: relative;
    z-index: 910;

    &.right {
      margin-left: auto;
      > * {
        margin-left: $spacing;
        &.avatar {
          margin-left: $spacing * 3;
        }
        &.subscription-cta {
          margin-right: $spacing;
        }
      }
    }
  }

  .center {
    // margin-right: 15px;
    display: flex;
    flex: 1;
    justify-content: flex-start;
    max-width: 765px;
    align-items: center;
  }

  .menu-button {
    margin-left: $spacing * 3;
  }

  .mobile-menu-toggle {
    display: inline-flex;
    margin-left: $spacing;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;

    svg {
      transform: rotate(0deg);
      transition: all 0.1s ease;
      color: white;
      width: 24px;
      height: 24px;
    }

    &.active svg {
      transform: rotate(180deg);
    }
  }

  .nav-menu {
    display: flex;
    flex: 1 1;
    align-items: center;
    margin-right: 10%;

    .menu-item {
      color: $color-text-primary-light;
      white-space: nowrap;
      margin-left: 8%;
    }

    .AppStore-buttons {
      margin-bottom: $spacing * 4;
      padding: 0 $spacing * 4;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: $spacing;
    &.active,
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
    &.active {
      z-index: 1000;
    }
    svg {
      color: white;
      width: 24px;
      height: 24px;
    }
    &.custom-margin {
      svg {
        margin-left: -2px;
      }
    }
  }
  .icon-search {
    cursor: pointer;
    z-index: 4;
    position: relative;
    svg {
      position: absolute;
    }
  }

  .avatar {
    width: 34px;
    height: 34px;
    padding: 2px;

    .avatar-image {
      background-color: #463b4d;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    &.subscriptionPremium,
    &.subscriptionPremium:hover {
      background: linear-gradient(
        90deg,
        #bf953f 0%,
        #e0b416 0.01%,
        #f3d97c 46.88%,
        #bf953f 100%
      );
    }

    &.subscriptionFamily,
    &.subscriptionFamily:hover {
      background: linear-gradient(
        90deg,
        #fdc3df 0%,
        #dd205f 0.01%,
        #fdc3df 46.88%,
        #dd205f 100%
      );
    }

    &.subscriptionBasic,
    &.subscriptionBasic:hover {
      background: linear-gradient(
        90deg,
        #c4c4c4 0%,
        #afafaf 0.01%,
        #afafaf 46.88%,
        #c4c4c4 100%
      );
    }

    &.notification {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 2px;
        right: 2px;
        width: 10px;
        height: 10px;
        box-shadow: 0 0 0 2px $background-dark;
        border-radius: 50%;
        background-color: $color-primary;
      }
    }
  }

  .side {
    display: flex;
    align-items: center;
  }

  .item-gift-link {
    padding: 9px 8px;
    .gift-icon {
      vertical-align: middle;
      margin-top: -2px;
    }
    .gift-label {
      margin-left: 8px;
      vertical-align: middle;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.6em;
      text-transform: uppercase;
      background: linear-gradient(
        133.23deg,
        #ff78fa 17.96%,
        #fff06c 46.18%,
        #6cfff6 71.09%,
        #5fff78 97.66%
      );
      background-clip: text;
      -webkit-text-fill-color: transparent;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
    }
  }

  @media (max-width: 500px) {
    &.PublicNavbar .item-gift-link .gift-label {
      display: none;
    }
  }

  @media (max-width: $breakpoint-xl) {
    .item-gift-link {
      .gift-label {
        display: none;
      }
    }
  }

  .is-hidden-mobile {
    display: none;
  }

  @media (max-width: $breakpoint-md) {
    .menu-button .mio-button {
      font-size: 0.8rem;
      line-height: 1.5em;
      padding: $spacing $spacing * 3;
      margin: 0;
    }
  }

  @media (max-width: $breakpoint-lg) {
    // big phone and down

    .nav-menu {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      text-align: left;
      background: $background-nav;
      height: 0;
      overflow: hidden;
      transition: height 0.2s ease;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      margin: 0;
      &.active {
        top: 60px;
        height: auto;
        padding: 10px 0;
      }

      .menu-item {
        width: 100%;
        font-size: 1.125rem;
        line-height: 1.5em;
        padding: $spacing * 3 $spacing * 4;
        margin-left: 0;
        &:hover,
        &.active {
          background: rgba(255, 255, 255, 0.05);
        }
      }
    }
  }

  @media (min-width: $breakpoint-sm) {
    // big phone and up
    .logo {
      height: 16px;
    }
    .icon {
      margin-left: $spacing * 4;
      width: 42px;
      height: 42px;
      svg {
        width: 30px;
        height: 30px;
      }
    }

    .logo {
      display: unset;
    }

    .logoMobile {
      display: none;
    }
  }
  @media (min-width: $breakpoint-md) {
    // tablet and up
    padding: $spacing * 3 $spacing * 12;
    .logo {
      height: 18px;
    }
    .link {
      display: block;
    }
    .icon {
      margin-left: $spacing * 4;
      width: 42px;
      height: 42px;
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
  @media (min-width: $breakpoint-lg) {
    // desktop and up
    .is-mobile {
      display: none;
    }
    .is-hidden-mobile {
      display: unset;
    }
  }
  @media (min-width: $breakpoint-xl) {
    // wide desktop and up
  }
}
