@import '../theme/colors.scss';

.mio-tag {
  background: rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.7);
  border-radius: 2px;
  font-weight: 500;
  padding: 2px 6px;

  &.small {
    font-size: 11px;
  }
  &.medium {
    font-size: 14px;
  }
}
