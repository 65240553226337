@import '../../variables.scss';

#Faq {
  text-align: center;
  color: white;
  .pageTitle {
    margin-top: 50px;
    margin-bottom: 100px;
  }
  .faq-component {
    margin-top: 50px;
  }
  .footer {
    margin-bottom: 40px;
    a {
      color: white;
      text-decoration: underline;
    }
  }
  .faq-footer {
    display: none;
  }
}
