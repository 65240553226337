@import '../../../variables.scss';

.PageTurn {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  display: flex;
  & > div {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &.hidden {
      svg {
        color: transparent;
        background-color: transparent !important;
        box-shadow: none !important;
      }
    }
  }
  svg {
    display: none;
    height: 40px !important;
    width: 40px !important;
    color: black;
    opacity: 0;
    transform: scale(0.8);
    margin: 20px;
    transition: opacity $animation-speed-fast ease-in,
      transform $animation-speed-fast ease-in;
  }
  .visible svg {
    opacity: 1;
    transform: scale(1);
  }
  .left {
    justify-content: flex-start;
  }
  .right {
    justify-content: flex-end;
  }

  @media (min-width: $breakpoint-md) {
    svg {
      display: inherit;
      padding: 6px;
      background-color: rgba(255, 255, 255, 0.8) !important;
      border-radius: 90px !important;
    }
  }
}
