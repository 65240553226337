@import '../../variables.scss';
.headerButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  .passCulture-button {
    background: $gradient-passculture;
    margin: 0 10px;
    border-radius: 8px;
    height: 54px;
    img {
      height: 100%;
    }
  }
  #cta_landing_signup {
    .label {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
#LandingHero {
  width: 100%;
  padding-top: 0;
  margin: 0 auto;
  .hero-bg {
    background-image: none;
  }
}
#flash-message-landing {
  width: 100%;
  max-width: 1200px;
}

#LandingPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  #LandingAdvantage {
    margin-bottom: 150px;
  }
  #LandingFeedback {
    .section-container {
      margin-right: 15px;
      margin-left: 15px;
    }
  }

  .medias-line {
    background-image: url('https://res-2.cloudinary.com/mangasio/image/fetch/c_limit,w_800/f_auto/https://cdn.mangas.io/landing/bg_presse_landing.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    border-radius: 30px;
    margin: 100px 50px;
    width: 90%;
    max-width: 1200px;
    padding: 60px 0px;
    z-index: 3;
    .carrousel-wrapper {
      margin-top: 40px;
      padding: 0;
    }
  }

  .pass-culture-container {
    @media (max-width: $breakpoint-xl) {
      width: 90%;
    }
  }

  .popularConnected-line {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 50px;
    margin-top: 50px;
    z-index: 3;
    .buttonLink {
      justify-content: center;
      display: flex;
    }
    .titleBloc {
      display: none;
    }
    #button {
      margin-top: 20px;
      margin-bottom: 100px;
    }
    .elements {
      padding-top: 20px;
    }
  }
  .content-wrapper {
    text-align: center;
  }
  #LandingFaq {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 50px;
  }

  .titleHeader.giftCard-title .subtitleText {
    color: white;
  }
}

//mobile
@media (max-width: $breakpoint-lg) {
  #LandingHero {
    margin-top: -60px;
    .hero-bg {
      background-image: none;
    }
  }
  #flash-message-landing {
    width: 100%;
    padding: 0;
  }
  .headerButtons {
    flex-direction: column;
    margin-bottom: 0px;
    margin-top: 20px;
    #cta_landing_signup {
      margin-top: 10px;
    }
  }
  #LandingPage {
    #LandingAdvantage {
      margin-bottom: 50px;
    }
    .partners-list {
      padding: 0;
    }
    .pricing-disclaimer {
      margin: 10px;
    }
    .medias-line {
      height: 400px;
    }
    #LandingFeedback {
      .user-feedback {
        margin: 50px 15px;
      }
    }
  }
}
