@import '../../../variables.scss';

#LandingFeedback {
  position: relative;
  z-index: 0;
  width: 100%;
  text-align: center;
  color: black;
  padding-top: 100px;
  padding-bottom: 100px;

  .feedback-bg-1 {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://cdn.mangas.io/landing/feedback-bg-1-min.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    z-index: -1;
  }
  .feedback-bg-2 {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://cdn.mangas.io/landing/feedback-bg-2-min.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    z-index: -2;
  }

  .title {
    color: white;
    margin-bottom: 0px;
  }

  .user-feedback {
    position: relative;
    width: 400px;
    max-width: calc(100vw - 30px);
    min-height: 280px;
    text-align: left;
    border-radius: 32px;
    border-bottom-left-radius: 0;
    background-color: #ad1055;
    border: 1px solid rgba(255, 255, 255, 0.15);
    margin: 100px 15px;
    padding: 20px 30px;
    box-shadow: 20px 20px 100px -40px currentColor;

    p {
      font-size: 1rem;
      line-height: 1.5em;
      color: white;
    }

    .username {
      font-size: 1.125rem;
      line-height: 1.5em;
      font-weight: 600;
    }

    .info {
      font-size: 0.875rem;
      line-height: 1.5em;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.5);
    }

    .separator {
      background: none;
      border: none;
      border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    }

    .user-avatar {
      position: absolute;
      display: block;
      bottom: -39px;
      left: -1px;
      border-radius: 50%;
      border: 7px solid currentColor;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 78px;
      height: 78px;
      overflow: hidden;

      img {
        object-fit: cover;
        max-width: 110%;
        width: 105%;
      }
    }

    .user-feedback-icon {
      position: absolute;
      display: block;
      bottom: -25px;
      left: 70px;
      border-radius: 50%;
      border: 7px solid currentColor;
      background-color: currentColor;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      overflow: hidden;

      img {
        object-fit: cover;
        max-width: 110%;
        width: 102%;
      }
    }
  }

  @media (max-width: 900px) {
    // padding-top: 40px;
    // padding-bottom: 40px;
  }
}
