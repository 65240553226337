$radius: 8px;

$spacing: 5px;
$spacing-2: $spacing * 2;
$spacing-3: $spacing * 3;
$spacing-4: $spacing * 4;
$spacing-5: $spacing * 5;
$spacing-6: $spacing * 6;

.absolute-fill {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
