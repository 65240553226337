@import '../../variables.scss';

.promo-modal {
  position: fixed;
  max-width: 530px;
  top: $spacing * 2;
  left: $spacing * 2;
  right: $spacing * 2;
  animation: fade1 0.2s ease-in;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);

  .mio-bloc {
    background: linear-gradient(
      175.8deg,
      #aa1a88 0%,
      #e75a03 55.78%,
      #e0a306 100%
    );
    & > * {
      padding: 15px;
    }
  }
  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    #modal-img {
      margin-bottom: 30px;
      width: 100%;
    }
  }
  svg {
    border-radius: 90px;
    background-color: white;
    color: black;
    cursor: pointer;
    width: 20px;
  }
  a {
    width: 100%;
  }
  #modal-btn {
    width: 100%;
  }
  .promo-text {
    display: flex;
    margin-bottom: 25px;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .bold {
      font-weight: bold;
    }
    .marginTop {
      margin-top: 10px;
    }
    .small {
      font-size: 13px;
      font-style: italic;
    }
  }
}
