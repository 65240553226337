@import '../../variables.scss';

.mio-form-success {
  img.coffee-success {
    width: 50%;
  }

  .mio-text {
    text-align: center;
    margin-top: $spacing * 3;
  }

  .mio-button {
    margin-top: $spacing * 3;
  }
}
