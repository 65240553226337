@import '../../variables.scss';

.header-media {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.3) 100%
    ),
    url('https://res-2.cloudinary.com/mangasio/image/fetch/c_limit,w_1800/f_auto/https://cdn.mangas.io/landing/bg_landing_qvema_mob.png');
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  padding: 100px 50px;
  text-align: center;
  height: 500px;
  .mio-button {
    margin-top: 50px;
  }
  .titleHeader {
    max-width: 700px;
  }
  @media (max-width: $breakpoint-lg) {
    padding: 0;
    padding-top: 250px;
    background-position: right 11% bottom;
  }
}

#MediaPage {
  .partners {
    margin-bottom: 100px;
  }

  .wrapper-press {
    display: flex;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-inline: 100px;
    align-items: center;
    justify-content: center;
    .press-text {
      background-color: #262128;
      padding: 50px;
      height: 300px;
      margin-right: 20px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    .press-img {
      height: 300px;
      border-radius: 20px;
      width: 100%;
      background-image: url('https://res-2.cloudinary.com/mangasio/image/fetch/c_limit,w_1400/f_auto/https://cdn.mangas.io/landing/mio-press.jpeg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .wrapper-video {
    margin-bottom: 100px;
    padding: 50px;
    background: $gradient-blue-purple;
    border-radius: 20px;
    .titleHeader {
      margin-bottom: 50px;
      .subtitleText {
        margin: 0 auto;
        color: white;
        max-width: 500px;
      }
    }
    .video {
      padding: 50px;
    }
  }
  .press-cta {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 100px;

    .mio-button {
      width: 400px;
    }
  }

  .wrapper-cta {
    background: $gradient-passculture;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    .mio-button {
      margin-top: 50px;
      width: 400px;
    }
  }

  @media (max-width: $breakpoint-lg) {
    padding-inline: 20px;
    .wrapper-video {
      display: flex;
      flex-direction: column-reverse;
      padding: 20px;
      .video {
        padding: 0;
        margin-bottom: 50px;
      }
      .titleHeader {
        padding: 0;
      }
    }
    .wrapper-press {
      flex-direction: column;
      padding: 0;
      .press-text {
        margin-bottom: 20px;
        margin-top: 20px;
        margin-right: 0;
        padding: 20px;
        height: 150px;
      }
    }
    .press-cta {
      .mio-button {
        margin-top: 50px;
        width: 100%;
      }
    }
    .wrapper-cta {
      padding: 20px;
      .mio-button {
        width: 100%;
      }
    }
  }
}
