@import '../../variables.scss';

.ReferralPage {
  [data-aos] {
    opacity: 1;
    transform: initial;
  }

  button:disabled {
    opacity: 0.5;
  }
  .code-validation-input {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .mio-button {
      margin-left: $spacing * 3;
      margin-top: $spacing;
    }
    .mio-input {
      max-width: 250px;
      margin: 2px;
    }

    @media (min-width: $breakpoint-lg) {
      justify-content: flex-start;
    }
  }
  .codeValidation {
    display: flex;
    align-items: center;
    padding: $spacing * 4 0;
    @media (max-width: 900px) {
      justify-content: center;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: $spacing;
    }
    &.valid {
      svg {
        fill: rgb(23, 117, 23);
      }
    }
    &.invalid {
      svg {
        fill: rgb(153, 28, 28);
      }
    }
    &.loading {
      svg {
        fill: rgb(197, 197, 197);
      }
    }
  }
}
