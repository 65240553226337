@import '../../../variables.scss';

#GoldenTicket {
  text-align: center;
  padding: 100px 0;

  .goldenTicket-container {
    position: relative;
    overflow: hidden;
  }

  .goldenTicket-wrapper {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 0 25px;
  }

  .text-gold {
    background: linear-gradient(
      90deg,
      #bf953f 0%,
      #f3d97c 46.88%,
      #bf953f 100%
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
  }

  .goldenTicketTitle {
    font-weight: 700;
    font-size: 2.625rem;
    line-height: 1.5em;
    margin: 0;
    color: white;
  }

  .goldenTicketSubtitle {
    font-size: 1rem;
    line-height: 1.8em;
    color: white;
    max-width: 580px;
    margin: 0 auto;
    font-weight: normal;
  }

  .goldenTicketBgWave {
    background-image: url('../../../assets/goldenTicketBgWave.svg');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;

    margin-bottom: 150px;
  }

  .content-wrapper {
    padding: 0 25px;
  }

  .gTicket {
    margin: 0 auto;
    position: relative;
    max-width: 824px;

    .gTicket-card {
      position: relative;
      background: linear-gradient(88.5deg, #0862b5 0%, #54a0f9 100%);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 32px;
      filter: drop-shadow(0px 4px 15px rgba(97, 81, 24, 0.4));
      padding: 60px;
      margin-top: 70px;
      padding-bottom: 40px;
      overflow: hidden;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background-image: url('https://cdn.mangas.io/images/goldenTicketBgBlood-min.png');
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: 328px 491px;
      }

      .mio-logo {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 65px;
        height: 65px;
      }

      .gTicket-content {
        //padding-right: 85px;
        text-align: left;
        max-width: 574px;
        color: white;
      }

      .header {
        color: #ffe818;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.5em;
        text-transform: uppercase;
        margin: 0;
      }

      .title {
        font-weight: 600;
        font-size: 2.25rem;
        line-height: 1.5em;
        margin: 0;
        color: white;
      }

      .price {
        font-size: 2.3125rem;
        line-height: 1.5em;
        margin: 0;
        color: white;

        .discount {
          font-size: 1.375rem;
          text-decoration: line-through;
        }
      }

      .text {
        font-size: 1.25rem;
        line-height: 1.8em;
        margin-top: 15px;
        color: white;
      }

      .footer {
        text-align: left;
        margin-top: 45px;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        .left {
          flex: 1;
        }
        .center {
          flex-shrink: 0;
        }
        .right {
          flex: 1;
        }
      }

      .stripe {
        font-size: 0.875rem;
        line-height: 1.5em;
        opacity: 0.8;
        color: white;
        font-weight: 600;
      }

      .footer-button {
        display: inline-block;
      }

      .footer-button {
        padding-left: 55px;
        padding-right: 55px;
        margin: 0;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        90deg,
        #bf953f 0%,
        #e0b416 0.01%,
        #f3d97c 46.88%,
        #bf953f 100%
      );
      box-shadow: -3px 3px 9px rgba(0, 0, 0, 0.38);
      border-radius: 20px;
      transform: rotate(-3.43deg);
    }
  }

  @media (max-width: 700px) {
    padding: 50px 0;

    .goldenTicketBgWave {
      margin-bottom: 50px;
    }

    .goldenTicketTitle {
      font-size: 1.5rem;
      line-height: 1.5em;
    }
    .goldenTicketSubtitle {
      font-size: 0.8rem;
    }

    .gTicket .gTicket-card {
      padding: 30px;

      &::before {
        background-size: 200px auto;
      }

      .mio-logo {
        display: none;
      }

      .header {
        font-size: 1.2rem;
      }

      .title {
        font-size: 1.8rem;
      }

      .price {
        font-size: 2rem;
      }

      .text {
        font-size: 1rem;
      }

      .footer {
        // text-align: center;
        //margin-top: 30px;
        align-items: flex-start;
        flex-direction: column-reverse;
      }
    }
  }
  @media (max-width: 1200px) {
  }
}
