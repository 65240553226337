#PreFooterTrial {
  text-align: center;
  background-color: #2c2731;
  padding: 65px 0;

  .section-container {
    position: relative;
    overflow: hidden;
  }

  .content-wrapper {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 0 25px;
  }

  .register-form {
    margin-top: 0 !important;
    .input {
      border: none;
      background: #ffffff;
      box-shadow: 0px 0px 0.25em rgba(21, 16, 24, 0.2);
      border-radius: 0.25em;
      font-style: normal;
      font-weight: 300;
      font-size: 1em;
      line-height: 1.5em;
      height: 3.5em;
      padding: 0.625em 0.875em;
      width: 100%;
      max-width: 340px;
      margin: 10px 10px;
      outline: none;

      &.is-error {
        border-color: #ff4a3f;
      }
    }

    .button {
      background: linear-gradient(358.13deg, #92124a 8.67%, #d91d6f 91.33%);
      border: none;
      border-radius: 8px;
      font-size: 1em;
      color: #ffffff;
      padding: 0 1.5em;
      cursor: pointer;
      height: 3.5em;
      text-align: center;
      outline: none;
      margin: 0;
      width: 100%;
      max-width: 340px;
    }

    .button:hover {
      background: #92124a;
    }

    .group-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .help {
      font-size: 1.125rem;
      line-height: 1.5em;
      margin: 5px 0;
      display: inline-block;
      max-width: 678px;
    }

    .text-white {
      color: white;
    }
  }

  @media (max-width: 900px) {
    .register-form {
      .button {
        font-size: 0.875rem;
        height: 2.9em;
      }

      .group-row {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .help {
        font-size: 0.875rem;
        margin: 15px 0;
      }
    }
  }
}
