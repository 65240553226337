@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500');
@import '../../variables.scss';

.Settings {
  height: 600px;
  background: $background-nav;
  border-radius: $radius;

  .floatingmenu-header.clickable {
    cursor: pointer;
  }

  .slide {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    transition: transform $animation-speed ease-out;
    transform: translateX(100%);
    display: flex;
    flex-direction: column;
    &.displayed {
      transform: translateX(0);
    }

    &.shifted {
      transform: translateX(-100%);
    }

    .settings-icon {
      width: 24px;
      height: 26px;
      margin: 0 $spacing * 2;

      svg {
        color: $color-text-primary-light;
      }
    }

    .back-icon {
      width: 20px;
      height: 20px;
      margin-right: $spacing;
    }

    .row {
      display: flex;
      height: 55px;
      padding: 0 $spacing;
      align-items: center;

      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }

      .mio-text {
        flex: 1;
        padding-left: $spacing;
      }
    }
  }
}
