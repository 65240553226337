#PricingCard {
  border-radius: 8px;
  overflow: hidden;
  .button {
    display: inline-block;
    cursor: pointer;
    user-select: none;
    font-size: 1rem;
    line-height: 1.5em;
    color: white;
    font-weight: 600;
    box-shadow: -1px 4px 5px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    border: none;
    height: 2.75em;
    width: 100%;
    padding: 0 2em;
    align-items: center;
    display: flex;
    justify-content: center;
    transition: all 100ms ease;
  }

  .button:hover {
    text-decoration: none;
  }

  .button:not(.disabled):active {
    transform: translateY(2px);
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.25);
  }

  .button.disabled {
    cursor: default;
    opacity: 0.5;
  }

  .card-tag {
    position: absolute;
    top: -0.75em;
    left: 0;
    z-index: 1;
    font-size: 1rem;
    line-height: 1.5em;
    font-weight: 500;
    height: 1.5em;
  }

  .card-header {
    height: 55px;
    background: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    span {
      font-weight: 600;
      font-size: 1em;
      text-transform: uppercase;
    }
  }

  .card-content {
    padding: 2.5rem;
    height: 380px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .background {
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .chip {
    font-size: 0.8rem;
    border: solid 1px white;
    border-radius: 12px;
    padding: 2px 10px;
    text-transform: uppercase;
  }

  .price {
    font-size: 2.75rem;
    font-weight: 600;
    line-height: 1.5em;
    color: white;
    margin: 0;
    margin-top: 10px;
  }

  .price.is-2 {
    font-size: 1.2rem;
    line-height: 1.5em;
  }

  .price sup {
    vertical-align: baseline;
    font-size: 0.4em;
    font-weight: normal;
    line-height: 1.5em;
  }

  .card-content .subtitle {
    font-size: 0.875rem;
    line-height: 1.5em;
    margin-block-start: 0;
    margin-block-end: 0;
    color: white;
    font-weight: normal;
  }

  &.monthly {
    background: linear-gradient(180deg, #37303c 0%, #231e25 100%);
    .chip {
      background: linear-gradient(
        90deg,
        rgba(173, 172, 173, 0.6) 0%,
        rgba(213, 213, 213, 0.6) 57.81%,
        rgba(147, 145, 150, 0.6) 100%
      );
    }
    .background {
      background-image: url(https://cdn.mangas.io/illustrations/shapes.svg);
    }
    .button {
      background: linear-gradient(180deg, #3e3442 0%, #322935 100%);
    }
  }

  &.yearly {
    background: linear-gradient(
      180deg,
      #c93709 0%,
      #da8522 51.56%,
      #f4b368 95.31%,
      #e3582d 100%
    );
    .chip {
      background: linear-gradient(
        90deg,
        rgba(209, 111, 21, 0.86) 0%,
        rgba(255, 160, 72, 0.86) 50%,
        rgba(172, 92, 19, 0.86) 100%
      );
    }
    .background {
      background-image: url(https://cdn.mangas.io/illustrations/sun.svg);
    }
    .button {
      background: linear-gradient(358.24deg, #92124a 8.67%, #d91d6f 91.33%);
    }
  }

  @media (max-width: 1200px) {
    .card-content {
      padding: 1.5rem;
    }

    .price {
      font-size: 2.375rem;
      line-height: 1.5em;
    }

    .price.is-2 {
      font-size: 0.94rem;
      line-height: 1.5em;
    }
  }
}
