@import '../../variables.scss';

.Collection {
  // main css here
  height: 100%;
  padding: $spacing * 2;
  border-radius: $radius * 0.5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .--subtitle {
    margin: 0;
    margin-top: $spacing;
    color: white;
  }
  .--text {
    margin: $spacing * 2 0;
    color: #bfbfbf;
    height: 100px;
  }
  .thumbnails {
    display: flex;

    justify-content: space-between;
    .thumbnail {
      width: 32%;
      background-size: cover;
      background-position: center;
    }
  }

  .dark-mode & {
    // dark mode css here
  }

  @media (min-width: $breakpoint-sm) {
    // big phone and up
  }
  @media (min-width: $breakpoint-md) {
  }
  @media (min-width: $breakpoint-lg) {
    // desktop and up
  }
  @media (min-width: $breakpoint-xl) {
    // wide desktop and up
  }
}
