@import '../../variables.scss';

.pricing-table {
  .flash-message {
    padding: 0 15px;
  }
}
.pricing-table-plans {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  .offer-card {
    display: flex;
    flex-direction: column;
    width: 380px;
    align-items: center;
    margin: 10px;
    margin-top: 30px;
    .card-bg {
      width: 100%;
      border-radius: 16px;
      padding: 40px 30px;
      height: 100%;
      background: linear-gradient(
        180deg,
        transparent 0%,
        #15101894 20%,
        #151018 100%
      );
    }
    img {
      object-fit: cover;
    }
    .avatar-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      img {
        width: 60px;
        position: absolute;
        margin-top: -20px;
        margin-right: -10px;
      }
    }
    .card-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 30px;
      margin-top: 15px;
      .offer-card-logo {
        max-width: 167px;
        height: 40px;
        margin-top: 10px;
        object-fit: contain;
      }
    }
    .price-container {
      display: flex;
      align-items: baseline;
    }
    .offer-description {
      margin-bottom: 30px;
      height: 46px;
    }
    .card-description {
      margin-top: 20px;
      margin-bottom: 20px;
      height: 46px;
    }
    .offer-feature-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .offer-feature-item {
        width: 100%;
        display: flex;
        margin: 5px 0 5px 0;
        text-align: left;
        align-items: center;
        justify-content: flex-start;
        img {
          margin-right: 10px;
        }
      }
    }
  }
}

.offer-btn {
  width: 100%;
}
.btnOffer {
  margin-bottom: 20px;
}

.special-offer {
  text-align: center;
}
.pricing-disclaimer {
  max-width: 840px;
  align-self: center;
  margin: 0 auto;
}
.pricing-table-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 10px;
  .mio-toggle {
    margin-left: 15px;
  }
}
p.legal {
  color: #ffffffbb;
  font-size: 12px;
  @media (max-width: $breakpoint-md) {
    text-align: center;
  }
}

@media (max-width: $breakpoint-md) {
  .pricing-table {
    margin: 0 auto;
    .pricing-table-plans {
      flex-direction: column;
      align-items: center;
    }
    .pricing-table-plans .offer-card {
      width: 350px;
    }
  }
}
