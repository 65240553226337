@import '../../variables.scss';

.ReaderProgress {
  &.reversed {
    transform: rotate(180deg);
  }
  width: 100%;
  height: 1px * $readerProgressBarHeight;
  position: absolute;
  z-index: 2;
  background: $background-light;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  .current-progress,
  .loading-progress {
    transition: width 0.3s ease;
    position: absolute;
    top: 0;
    height: 100%;
  }
  .current-progress {
    background-color: $color-primary;
  }
  .loading-progress {
    background-color: $color-primary;
    opacity: 0.5;
  }

  .dark-mode & {
    background: $background-dark;
  }

  @media (min-width: $breakpoint-lg) {
    // tablet and up
    top: $reader-menu-height;
    display: block;
  }
}
