@import '../../variables.scss';

#SubCategories {
  .page-header {
    width: 100%;
    margin-bottom: 35px;
    @media (max-width: $breakpoint-lg) {
      padding-left: 80px;
      padding-top: 15px;
    }
    @media (max-width: $breakpoint-sm) {
      padding: 0;
    }
  }
  .SubCategories-container {
    .SubCategories-header {
      white-space: pre-wrap;
      @media (max-width: $breakpoint-lg) {
        padding-left: 80px;
        padding-right: 80px;
      }
      @media (max-width: $breakpoint-sm) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  .categoryTitle,
  .categorySubtitle {
    margin-bottom: 15px;
  }
  @media (min-width: $breakpoint-sm) {
    .SubCategories-content {
      padding: $spacing * 8 0;
    }
    .SubCategories-list ul {
      justify-content: flex-start;
      @media (max-width: $breakpoint-lg) {
        padding-left: 80px;
      }
      @media (max-width: $breakpoint-sm) {
        padding-left: 20px;
      }
      li {
        margin: 10px;
      }
    }
  }
}
